<h5 class="zint-card-title">{{ label }}</h5>
@if(!value) {
<div class="aira-nodata-icon-backdrop">
    <i class="fa-duotone fa-thin fa-gauge-simple-high"></i>
    <p>No insights have been derived.</p>
</div>
}
@else {    
    <gauge [gaugeData]="{value}"></gauge>
}
