<div class="zint-base-card zint-card-gradient-blue mb-2">
    <div>
        <p>Teams:</p>
        <table class="table table-sm table-within-table" [ngClass]="{'zero-margin-bottom': !inputParams.objectAddingOrRemovingTeam[inputParams.attributeNameForTeams] || inputParams.objectAddingOrRemovingTeam[inputParams.attributeNameForTeams].length == 0}">
            <tr *ngFor="let team of inputParams.objectAddingOrRemovingTeam[inputParams.attributeNameForTeams]">
                <td class="text-grey-300">{{ team.name }}</td>
                <td>
                    <button class="rounded-btn-base rounded-btn-light-outline btn-p-sm" style="font-size: 10px" (click)="ChildFunctionOnClickToDeleteTeam(team, inputParams.objectAddingOrRemovingTeam)" data-toggle="modal" [attr.data-target]="'#' + inputParams.confirmDeleteModalId"><i class="fal fa-trash"></i></button>
                </td>
            </tr>
        </table>
        <ng-container *ngIf="inputParams.organisationTeams && inputParams.objectAddingOrRemovingTeam[inputParams.attributeNameForTeams] && inputParams.organisationTeams.length != inputParams.objectAddingOrRemovingTeam[inputParams.attributeNameForTeams].length">
            <select class="custom-select" (change)="ChildFunctionOnAddNewTeam($event.target.value, inputParams.objectAddingOrRemovingTeam)" *ngIf="isAddingTeam">
                <ng-container *ngIf="inputParams.objectAddingOrRemovingTeam[inputParams.attributeNameForTeams]">
                    <option selected></option>
                    <ng-container *ngFor="let organisationTeam of inputParams.organisationTeams;">
                        <option [attr.value]="organisationTeam.id" *ngIf="organisationTeam | filterLoopPipe:inputParams.objectAddingOrRemovingTeam[inputParams.attributeNameForTeams]:'id'">{{organisationTeam.name}}</option>
                    </ng-container>
                </ng-container>
            </select>
            <ng-container *ngIf="!isAddingTeam">
                <button class="rounded-btn-base rounded-btn-light-outline full-width" (click)="isAddingTeam = true">
                    <i class="fal fa-plus"></i> Add Team
                </button>
            </ng-container>
        </ng-container>
    </div>
</div>
