import { Component, Input } from '@angular/core';

@Component({
  selector: 'aira-pills',
  templateUrl: './aira-pills.component.html',
  styleUrl: './aira-pills.component.css',
})
export class AiraPillsComponent {
  @Input() label;
  @Input() data;
  pillsToRender: any[] = [];
  leftoverCount: number = 0;

  ngOnInit() {
    this.handlePillsData();
  }

  handlePillsData() {
    if (!this.data?.length) return;

    if (this.data.length <= 5) {
      this.pillsToRender = this.data;
      return;
    }

    if (this.data.length > 5) {
      this.leftoverCount = this.data.length - 5;
      this.pillsToRender = this.data.slice(0, 5);
    }
  }

  showAllPills() {
    this.pillsToRender = this.data;
    this.leftoverCount = null;
  }
}
