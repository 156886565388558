import { Component, Input } from '@angular/core';
import { publicMethods } from '../../globals';

@Component({
  selector: 'aira-responses',
  templateUrl: './aira-responses.component.html',
  styleUrl: './aira-responses.component.css',
})
export class AiraResponsesComponent {
  @Input() airaResponses: any[] = [];
  @Input() moduleSectionId: string = '';

  constructor(public pubMethods: publicMethods) {}

  shouldSpanTwoColumns(displayType: string, answer: any): string {
    const twoColDisplayTypes = ['card_list', 'labelled_pills'];
    const hasThreeOrMoreItems = answer?.length >= 3;

    const hasData = this.pubMethods.hasData(answer);

    const longContents =
      hasData &&
      twoColDisplayTypes.includes(displayType) &&
      hasThreeOrMoreItems;

    let hasTwoColLongPills = false;
    if (displayType === 'pills') {
      //char length 25 or more should be considered long
      hasTwoColLongPills = hasData && answer?.some(str => str.length >= 25);
    }

    if (longContents || hasTwoColLongPills) {
      return 'grid-col-2';
    }
  }
}
