<div class="d-flex flex-column align-items-center">
<apx-chart [series]="chartSeries" [chart]="chartOptions" [plotOptions]="plotOptions" [fill]="fill"
  [stroke]="stroke" [labels]="chartOptions.labels"></apx-chart>

  @if (gaugeData.extraInfo) {
    <div style="margin-top: -4rem;">
      @for (item of gaugeData.extraInfo; track $index) {
      <span>&blacktriangleright; {{item}}</span>
      }
    
    </div>
  }
</div>