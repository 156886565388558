<div class="container" *ngIf="airaOutput && airaOutput.retrieval_model">
    @if (airaOutput.previous_company_number){
    <a [attr.href]="'/aira?airaRunId='+airaRunId+'&companyNumber='+airaOutput.previous_company_number"
        class="arrow-link left-arrow">
        <i class="fal fa-arrow-alt-circle-left cursor-pointer"></i>
    </a>
    }
    <div class="left-side">
        <mat-card>
            <mat-card-header>
                <mat-card-title>General Information</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-list>
                    <mat-list-item>
                        <span class="label">Retrieval Model:</span>
                        <span class="value">
                            <img class="logo" src="https://www.google.com/s2/favicons?domain=google.com"
                                alt="Google Logo" *ngIf="airaOutput.retrieval_model.startsWith('gemini')">
                            <img class="logo" src="https://www.google.com/s2/favicons?domain=openai.com"
                                alt="OpenAI Logo" *ngIf="!airaOutput.retrieval_model.startsWith('gemini')">
                            {{ airaOutput.retrieval_model }}
                        </span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="label">Reasoning Model:</span>
                        <span class="value">
                            <img class="logo" src="https://www.google.com/s2/favicons?domain=google.com"
                                alt="Google Logo" *ngIf="airaOutput.reasoning_model.startsWith('gemini')">
                            <img class="logo" src="https://www.google.com/s2/favicons?domain=openai.com"
                                alt="OpenAI Logo" *ngIf="!airaOutput.reasoning_model.startsWith('gemini')">
                            {{ airaOutput.reasoning_model }}
                        </span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="label">Retrieval Input Tokens:</span>
                        <span class="value">{{ airaOutput.retrieval_input_tokens }}</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="label">Retrieval Output Tokens:</span>
                        <span class="value">{{ airaOutput.retrieval_output_tokens }}</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="label">Retrieval Cached Tokens:</span>
                        <span class="value">{{ airaOutput.retrieval_cached_tokens }}</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="label">Reasoning Input Tokens:</span>
                        <span class="value">{{ airaOutput.reasoning_input_tokens }}</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="label">Reasoning Output Tokens:</span>
                        <span class="value">{{ airaOutput.reasoning_output_tokens }}</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="label">UK Companies Count:</span>
                        <span class="value">{{ airaOutput.uk_companies_count }}</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="label">Questions Answered:</span>
                        <span class="value">{{ airaOutput.questions_answered }}</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="label">Latest Update:</span>
                        <span class="value">{{ airaOutput.latest_update }}</span>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="right-side">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Financial Information</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-list>
                    <mat-list-item class="financial-data">
                        <span class="label">Total Retrieval Cost:</span>
                        <span class="value">{{ (airaOutput.total_retrieval_cost).toPrecision(3) }} USD</span>
                    </mat-list-item>
                    <mat-list-item class="financial-data">
                        <span class="label">Total Reasoning Cost:</span>
                        <span class="value">{{ (airaOutput.total_reasoning_cost).toPrecision(3) }} USD</span>
                    </mat-list-item>
                    <mat-list-item class="financial-data">
                        <span class="label">Avg. Retrieval Cost/Company:</span>
                        <span class="value">{{ (airaOutput.average_retrieval_cost_per_company).toPrecision(3) }}
                            USD</span>
                    </mat-list-item>
                    <mat-list-item class="financial-data">
                        <span class="label">Avg. Retrieval Cost/Company/Q:</span>
                        <span class="value">{{ (airaOutput.average_retrieval_cost_per_company_question).toPrecision(3)
                            }} USD</span>
                    </mat-list-item>
                    <mat-list-item class="financial-data">
                        <span class="label">Avg. Reasoning Cost/Company:</span>
                        <span class="value">{{ (airaOutput.average_reasoning_cost_per_company).toPrecision(3) }}
                            USD</span>
                    </mat-list-item>
                    <mat-list-item class="financial-data">
                        <span class="label">Avg. Reasoning Cost/Company/Q:</span>
                        <span class="value">{{ (airaOutput.average_reasoning_cost_per_company_question).toPrecision(3)
                            }} USD</span>
                    </mat-list-item>
                    <mat-list-item class="financial-data">
                        <span class="label">Avg. Total Cost/Company/Q:</span>
                        <span class="value">{{ (airaOutput.average_total_cost_per_company_question).toPrecision(3) }}
                            USD</span>
                    </mat-list-item>
                    <mat-list-item class="financial-data">
                        <span class="label">Avg. Total Cost/Company:</span>
                        <span class="value">{{ (airaOutput.average_total_cost_per_company).toPrecision(3) }} USD</span>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div>
    @if (airaOutput.next_company_number){
    <a [attr.href]="'/aira?airaRunId='+airaRunId+'&companyNumber='+airaOutput.next_company_number"
        class="arrow-link right-arrow">
        <i class="fal fa-arrow-alt-circle-right cursor-pointer"></i>
    </a>
    }
</div>


<div class="aira-answers-container" *ngIf="airaOutput && airaOutput.retrieval_model">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let answer of airaOutput.responses; let i = index" style="min-height: 500px;">
            <mat-expansion-panel-header style="min-height: 500px;">
                <mat-panel-title style="min-width: 40%">
                    {{ answer.question }}
                </mat-panel-title>

                <mat-panel-description>
                    <mat-card class="answer-card">
<mat-card-content style="max-height: 400px; overflow-y: auto;">
    <ng-container *ngIf="isString(answer.answer)">
        <p class="answer" [matTooltip]="answer.answer">{{ answer.answer }}</p>
    </ng-container>

    <ng-container *ngIf="isStringArray(answer.answer)">
        <div class="pill-container">
            <span *ngFor="let str of answer.answer" class="string-pill">{{ str }}</span>
        </div>
    </ng-container>

    <ng-container *ngIf="isArrayOfObjects(answer.answer)">
        <div *ngFor="let item of answer.answer">
            <div *ngFor="let key of objectKeys(item)">
                <label class="top-level-label">{{ key }}: </label>
                <ng-container *ngIf="!isArrayOfNumbers(item[key]) && !isStringArray(item[key]) && !isNumber(item[key]); else otherTypes">
                  {{ item[key] }}
                </ng-container>
                <ng-template #otherTypes>
                    <ng-container *ngIf="isStringArray(item[key])">
                        <div class="pill-container">
                            <span *ngFor="let str of item[key]" class="string-pill">{{ str }}</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isArrayOfNumbers(item[key])">
                        <div class="histogram-container">
                            <aira-histogram-chart [frequencies]="item[key]"></aira-histogram-chart>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isNumber(item[key])">
                        {{item[key]}}
                    </ng-container>
                </ng-template>
            </div>
            <hr *ngIf="!lastItem(answer.answer, item)" />
        </div>
    </ng-container>

    <ng-container *ngIf="isArrayOfNumbers(answer.answer)">
        <div class="histogram-container">
            <aira-histogram-chart [frequencies]="answer.answer"></aira-histogram-chart>
        </div>
    </ng-container>

    <ng-container *ngIf="isObject(answer.answer) && !isArrayOfObjects(answer.answer)">
        <div *ngFor="let key of objectKeys(answer.answer)">
            <label class="top-level-label">{{key}}:</label>
            <ng-container *ngIf="isString(answer.answer[key]); else notAnswerKeyString">
                {{ answer.answer[key] }}
            </ng-container>
            <ng-template #notAnswerKeyString>
                <ng-container *ngIf="isStringArray(answer.answer[key]); else notAnswerKeyStringArray">
                        <div class="pill-container">
                        <span *ngFor="let str of answer.answer[key]" class="string-pill">{{ str }}</span>
                    </div>
                </ng-container>
            </ng-template>
                <ng-template #notAnswerKeyStringArray>
                <ng-container *ngIf="isArrayOfNumbers(answer.answer[key]); else notAnswerKeyNumberArray">
                    <div class="histogram-container">
                            <aira-histogram-chart [frequencies]="answer.answer[key]"></aira-histogram-chart>
                        </div>
                </ng-container>
                </ng-template>
                <ng-template #notAnswerKeyNumberArray>
                    <ng-container *ngIf="isArrayOfObjects(answer.answer[key]); else notAnswerKeyObjectArray">
                        <div *ngFor="let item of answer.answer[key]">
                            <div *ngFor="let subKey of objectKeys(item)">
                                <label class="sub">{{ subKey }}: </label>
                                <ng-container *ngIf="isString(item[subKey]); else notSubKeyString">
                                        {{item[subKey]}}
                                </ng-container>
                                    <ng-template #notSubKeyString>
                                        <ng-container *ngIf="isStringArray(item[subKey]); else notSubKeyStringArray">
                                            <div class="pill-container">
                                                <span *ngFor="let str of item[subKey]" class="string-pill">{{str}}</span>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                        <ng-template #notSubKeyStringArray>
                                            <ng-container *ngIf="isArrayOfNumbers(item[subKey]);else notSubKeyNumberArray">
                                                <div class="histogram-container">
                                                        <aira-histogram-chart [frequencies]="item[subKey]"></aira-histogram-chart>
                                                    </div>
                                            </ng-container>
                                    </ng-template>
                                    <ng-template #notSubKeyNumberArray>
                                            <ng-container *ngIf="isNumber(item[subKey]); else notSubKeyNumber">
                                                    {{item[subKey]}}
                                            </ng-container>
                                           <ng-template #notSubKeyNumber>
                                            {{item[subKey]}}
                                           </ng-template>
                                    </ng-template>
                            </div>
                            <hr/>
                        </div>
                    </ng-container>
                </ng-template>
                <ng-template #notAnswerKeyObjectArray>

                    <ng-container *ngIf="isNumber(answer.answer[key])">
                            {{answer.answer[key]}}
                    </ng-container>
                     <ng-container *ngIf="!isNumber(answer.answer[key]) && !isString(answer.answer[key]) && !isArrayOfNumbers(answer.answer[key]) && !isArrayOfObjects(answer.answer[key]) && !isStringArray(answer.answer[key])">
                           Unknown: {{answer.answer[key] | json}}
                    </ng-container>

                </ng-template>
        </div>
    </ng-container>
</mat-card-content>

                    </mat-card>
                    <div class="confidence-pill" [style.background-color]="getConfidenceColor(answer.confidence)">
                        {{ answer.confidence }}%
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="answer-content" style="padding-top: 50px; padding-bottom: 50px">

                <ng-container *ngIf="isArrayOfNumbers(answer.answer)">
                    <aira-histogram-chart [frequencies]="answer.answer"></aira-histogram-chart>
                </ng-container>


                <div *ngIf="answer.commentary">
                    <h4>Commentary:</h4>
                    <p>{{ answer.commentary }}</p>
                </div>

                <div *ngIf="answer.references.length > 0">
                    <h4>References:</h4>
                    <ul>
                        <li *ngFor="let ref of answer.references">
                            <p>
                                <b>Key Info:</b> {{ ref.key_info }}
                            </p>
                            <mat-card *ngIf="ref.quote" class="quote-card">
                                <mat-card-content>
                                    <p class="quote">{{ ref.quote }}</p>
                                </mat-card-content>
                            </mat-card>
                            <p>
                                <b>Source:</b> {{ ref.source }}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
