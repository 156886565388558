<h5 class="zint-card-title">{{ label }}</h5>
@if(!data?.length) {
<div class="aira-nodata-icon-backdrop">
    <i class="fa-duotone fa-thin fa-grid-horizontal"></i>
    <p>No insights have been derived.</p>
</div>
}
@else {
    <div class="pill-container">
        @for (item of pillsToRender; track $index;) {
        <span class="badge-vivid-blue" style="font-size: 0.9rem;">            
            <truncate-text [inputText]="item" [limit]="13" [textColor]="'var(--zint-vivid-blue)'"></truncate-text>
        </span>
        }
        @if (leftoverCount) {
            <span role="button" class="badge-vivid-blue" (click)="showAllPills()">{{leftoverCount}}+</span>
        }
    </div>
}