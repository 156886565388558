import { Component, Input } from '@angular/core';
import { publicMethods } from '../../../globals';

@Component({
  selector: 'aira-pie-chart',
  templateUrl: './aira-pie-chart.component.html',
  styleUrl: './aira-pie-chart.component.css',
})
export class AiraPieChartComponent {
  @Input() label = '';
  @Input() data;
  @Input() labelName;
  @Input() valueName;
  @Input() append = '';
  @Input() total: number;
  @Input() baseLabel: string;
  @Input() remainderLabel: string;

  chartOptions: any;
  labels = [];
  series = [];
  baseColour = '#4A2673';

  labelNameToTotalLabelMap = {
    country_code: 'regions',
    country_name: 'regions',
    currency: 'currencies',
    B2C: 'markets',
  };

  constructor(public pubMethods: publicMethods) {}

  ngOnInit(): void {
    this.handleDifferentDataType();
  }

  handleDifferentDataType(): void {
    if (Array.isArray(this.data)) {
      this.convertKeyValuesIntoSeriesAndLabels();
    } else if (typeof this.data === 'number') {
      const total = this.total || 100;
      const baseNum = this.data;
      const remainder = total - this.data;
      this.series.push(baseNum, remainder);
      this.labels.push(this.baseLabel, this.remainderLabel);
    }
  }

  convertKeyValuesIntoSeriesAndLabels(): void {
    for (const item of this.data) {
      this.series.push(parseFloat(item[this.valueName]));
      this.labels.push(item[this.labelName]);
    }
  }

  getTotalLabel(): string {
    if (this.labelName) {
      return this.labelNameToTotalLabelMap[this.labelName];
    } else if (this.baseLabel) {
      return this.labelNameToTotalLabelMap[this.baseLabel];
    }
  }
}
