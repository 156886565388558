<div *ngFor="let searchTag of inputParams.searchTags;" style="display: inline">
    @if (searchTag.value && searchTag.value !== 'false' && searchTag.value !== 'null') {
    <span class="search-param-pill-base xsmall-margin-right position-relative" [ngClass]="{'highlighted-badge': searchTag.param === 'selectedTerritory', 'savedSearch-badge': searchTag.param === 'savedSearchName'}" [matTooltip]="truncParamsTooltips[searchTag.param]" matTooltipClass="search-pill-tt">
        <span *ngIf="searchTag.value == 'true'"><i class="fal fa-check-circle"></i> </span>
        <span style="font-weight: 500"> {{ formatParamName(searchTag.param) }}</span>
        <span *ngIf="searchTag.value !== 'true' && searchTag.param !== 'selectedCustomColumns'" [ngClass]="{'text-grey-0': searchTag.param !== 'savedSearchName'}">:
            @if (searchTag.param === 'selectedTerritory') {
                {{ searchTag.value | nameOnlyDisplay }}
            } 
            @else {
                {{ formatParamValue({paramObj: searchTag, truncate: true}) }}
            }
        </span>
        <span *ngIf="searchTag.param == 'selectedCustomColumns'" class="text-grey-00">: 
            {{formatParamValue({paramObj: searchTag})}}       
        </span>
        <span *ngIf="searchTag.param == 'selectedCustomColumns' && customColsCount > 1" class="custom-col-counter">{{customColsCount}}</span>
        <span *ngIf="inputParams.enableClearing && excludeClearing(searchTag.param)" (click)="clearParameterButtonWasClicked(searchTag)" class="cursor-pointer badge-close-button"><i class="fas fa-times"></i></span>
    </span>
}
</div>
