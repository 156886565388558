import { Component, Input } from '@angular/core';

@Component({
  selector: 'aira-gauge',
  templateUrl: './aira-gauge.component.html',
  styleUrl: './aira-gauge.component.css',
})
export class AiraGaugeComponent {
  @Input() label = '';
  @Input() value: number;
}
