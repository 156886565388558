import { Component } from '@angular/core';
import { CompanyService } from '../../services/company.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'aira',
  templateUrl: './aira.component.html',
  styleUrl: './aira.component.css',
})
export class AiraComponent {
  airaOutput: Record<string, any> = {};
  airaRunId: any;
  constructor(
    private companyService: CompanyService,
    private route: ActivatedRoute
  ) {}
  ngAfterViewInit(): void {
    const params = this.route.snapshot.queryParams;
    this.airaRunId = params.airaRunId;
    this.companyService
      .getAIRABetaResponse(params.airaRunId, params.companyNumber)
      .subscribe(data => {
        this.airaOutput = data;
      });
  }

  isArrayOfObjects(value: any): boolean {
    return (
      Array.isArray(value) &&
      value.length > 0 &&
      typeof value[0] === 'object' &&
      !Array.isArray(value[0])
    );
  }

  isArrayOfNumbers(value: any): boolean {
    if (!Array.isArray(value)) {
      return false;
    }
    if (value.length === 0) {
      return false; // Empty array is not considered an array of numbers
    }
    // Recursively check if elements are numbers or arrays of numbers
    return value.every(
      item => typeof item === 'number' || this.isArrayOfNumbers(item)
    );
  }

  isString(value: any): boolean {
    return typeof value === 'string';
  }

  isStringArray(value: any): boolean {
    return (
      Array.isArray(value) && value.every(item => typeof item === 'string')
    );
  }

  isObject(value: any): value is object {
    // Type guard
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  lastItem(array: any[], item: any): boolean {
    return array.indexOf(item) === array.length - 1;
  }

  getConfidenceColor(confidence: number): string {
    if (confidence < 50) {
      return 'red'; // Pure red for < 50
    } else {
      const greenPortion = (confidence - 50) / 50; // Scale 50-100 to 0-1
      const redPortion = 1 - greenPortion;

      const red = Math.round(255 * redPortion);
      const green = Math.round(255 * greenPortion);
      return `rgb(${red}, ${green}, 0)`;
    }
  }

  isNumber(value: any): boolean {
    return typeof value === 'number' && !isNaN(value);
  }

  // Helper function to get object keys.  Object.keys() is fine, but
  // this allows us to use it in the template with proper typing.
  objectKeys(obj: any) {
    if (!obj) {
      return [];
    }
    return Object.keys(obj);
  }

  protected readonly Object = Object;
}
