<div class="zint-base-card zint-card-white aira-card">
    <span role="button" class="reference-trigger" (click)="openRefModal()" [ngClass]="{'button-disabled': noInsights}">
        <i  class="fal fa-question-circle"></i>
    </span>
@switch (displaySchema.display_type) {
    @case ('string') {
        <aira-string [label]="displaySchema.label" [value]="displayData"></aira-string>
    }
    @case ('card_list') {
        <aira-card-list [schema]="displaySchema" [data]="displayData"
            [headerKeyName]="displaySchema.header_key_name" [contentKeyName]="displaySchema.content_key_name"></aira-card-list>
    }
    @case ('string_list') {
        <aira-string-list [label]="displaySchema.label" [data]="displayData"
            [headerKeyName]="displaySchema.header_key_name" [contentKeyName]="displaySchema.content_key_name"></aira-string-list>
    }
    @case ('vertical_bar_chart') {
        <aira-bar-chart [label]="displaySchema.label" [schema]="displaySchema" [data]="displayData"></aira-bar-chart>
    }
    @case ('number') {        
        <aira-number [label]="displaySchema.label" [value]="displayData"
            [prepend]="displaySchema.prepend" [append]="displaySchema.append"
            [displayFormat]="displaySchema.display_format"></aira-number>
       
    }
    @case ('pie_chart') {
        <aira-pie-chart [label]="displaySchema.label" [data]="displayData"
            [labelName]="displaySchema.label_name" [valueName]="displaySchema.value_name"
            [append]="displaySchema.append" [total]="displaySchema.total" [baseLabel]="displaySchema.base_number_label" [remainderLabel]="displaySchema.remainder_number_label"></aira-pie-chart>
    }
    @case ('labelled_pills') {
        <aira-labelled-pills [label]="displaySchema.label" [data]="displayData"
            [textPropertyName]="displaySchema.text_property_name"
            [onHoverPropertyName]="displaySchema.on_hover_property_name"></aira-labelled-pills>
    }
    @case ('pills') {
        <aira-pills [label]="displaySchema.label" [data]="displayData"></aira-pills>
    }
    @case ('gauge') {
        <aira-gauge [label]="displaySchema.label" [value]="displayData"
            [maximumValue]="displaySchema.maximum_value"></aira-gauge>
    }
}
</div>

<div *ngIf="showModal" class="modal" id="aira-ref-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header align-items-baseline">
                <h4 class="modal-title">Insights Reference</h4>
                <div class="d-flex align-items-baseline">
                    <h5 class="mb-0">
                        Confidence Rating 
                        <span class="zint-card-details d-inline-block rounded p-1 ml-2"
                            [style.background-color]="getConfidenceColor(completeAnswer.confidence)">{{completeAnswer.confidence}}%</span>
                    </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span>&times;</span>
                </button>
                </div>
            </div>
            <div class="modal-body max-height-75vh">
                <div class="mb-3 pb-3 border-bottom">
                    <h5>AI Commentary</h5>
                    <truncate-text [inputText]="completeAnswer.commentary" [limit]="40"></truncate-text>
                </div> 
                <div *ngIf="completeAnswer.references.length">
                    <h5>References:</h5>
                    <ul>
                        @for (ref of completeAnswer.references; track $index; let last=$last) {
                            <li class="mb-3 text-grey-600" [ngClass]="{'border-bottom': !last}">
                                <p>
                                    <b>Key Info:</b> {{ ref.key_info }}
                                </p>
                                <div *ngIf="ref.quote" class="reference-quote">
                                    {{ ref.quote }}
                                </div>
                                <p>
                                    <b>Source:</b>&nbsp; 
                                    @if (ref.url) {
                                    <a [href]="ref.url" target="_blank">{{ ref.source | underscoreToUpper}}</a>
                                    }@else {
                                        <span>{{ref.source | underscoreToUpper}}</span>
                                    }
                                </p>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
