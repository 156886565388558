<h5 class="zint-card-title">{{ label }}</h5>
@if(!value) {
  <div class="aira-nodata-icon-backdrop">
  <i class="fa-duotone fa-thin fa-message-xmark"></i>
    <p>No insights have been derived.</p>
  </div>
}
@else {
  <div class="pr-5">
    <truncate-text [inputText]="value" [limit]="31"></truncate-text>
  </div>
}


