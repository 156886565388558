import { Component, OnInit } from '@angular/core';
import { OrganisationManagementService } from '../../services/organisation-management.service';
import { publicMethods } from '../../globals';
import { CommunicationService } from '../../services/communication.service';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'organisation-management',
  templateUrl: './organisation-management.component.html',
  styleUrls: ['./organisation-management.component.css'],
})
export class OrganisationManagementComponent implements OnInit {
  paramsOrgId: number;

  organisationName: string;
  maxNumberOfAccounts: number;
  downloadsUsed: number;
  totalDownloadsBetweenAllAccounts: number;
  teamMembers: Array<any>;
  tagViewingStatusOptions: Array<any>;
  tagViewingStatus: string;
  teamOrOrgSharedDownloadColumn: any;
  isEditingTeamOrOrgSharedDownloadColumn = false;
  optionsForSharedDownloadColumn = null;
  selectedSharedDownloadColumn: any;

  teamMemberIdEditingEmail: number;
  teamMemberIdEditingDownloads: number;
  showAddTeamMember = false;
  newTeamMemberEmail = '';
  teamMemberToDelete: any;

  hasSalesforceV2Access: boolean;

  isParentOrgSuperUser: boolean;

  constructor(
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private organisationManagementService: OrganisationManagementService,
    private pubMethods: publicMethods
  ) {}

  isImmediateSuperusersOrg = false;
  isLoading = this.organisationManagementService.isLoading;

  ngOnInit() {
    document.title = 'Manage Org';
    this.communicationService
      .getIsParentOrgSuperUserMessage()
      .subscribe(isParentOrgSuperUser => {
        this.isParentOrgSuperUser = isParentOrgSuperUser;
      });
    this.getOrgIDFromParams();
    this.getOrganisationDetails();
  }

  getOrgIDFromParams(): void {
    const params = this.route.snapshot.queryParams;
    if (params.org_id) {
      this.paramsOrgId = params.org_id;
    }
    else{
      // This get param exists if this is the top level superuser managing team.
      this.isImmediateSuperusersOrg = true;
    }
  }

  updateOrganisationDetails(data): void {
    this.organisationName = data.organisation_name;
    this.maxNumberOfAccounts = data.max_number_of_team_members;
    this.downloadsUsed = data.downloads_used;
    this.totalDownloadsBetweenAllAccounts =
      data.total_downloads_between_all_accounts;
    this.tagViewingStatus = data.team_member_tag_viewing_status;
    this.tagViewingStatusOptions = data.team_member_tag_viewing_status_options;
    this.teamMembers = data.team_members;
    this.teamOrOrgSharedDownloadColumn = data.shared_download_column;
  }

  getOrganisationDetails(): void {
    this.organisationManagementService
      .getOrganisationManagementDetails(this.paramsOrgId)
      .subscribe(data => this.updateOrganisationDetails(data));
  }

  afterUserUpdate(data): void {
    if (data.success) {
      this.pubMethods.showInfoMessage(
        'The details for this user have been updated.'
      );
      this.teamMemberIdEditingDownloads = null;
      this.teamMemberIdEditingEmail = null;
    }
  }

  updateUserEmailAddress(userId, emailAddress): void {
    this.organisationManagementService
      .superUserSaveUserEmailAddress(userId, emailAddress, this.paramsOrgId)
      .subscribe(data => this.afterUserUpdate(data));
  }

  updateUserDownloadLimit(userId, downloadLimit): void {
    this.organisationManagementService
      .superUserSaveUserDownloadQuota(userId, downloadLimit, this.paramsOrgId)
      .subscribe(data => this.afterUserUpdate(data));
  }

  afterUserPasswordResetEmailSent(data): void {
    if (data.success) {
      this.pubMethods.showInfoMessage(
        'An email with password reset instructions was sent to this user.'
      );
    }
  }

  sendPasswordResetEmail(userId): void {
    this.organisationManagementService
      .superUserRequestNewPassword(userId, this.paramsOrgId)
      .subscribe(data => this.afterUserPasswordResetEmailSent(data));
  }

  afterTeamMemberAdded(data): void {
    if (data.success) {
      this.showAddTeamMember = false;
      this.getOrganisationDetails();
      this.pubMethods.showInfoMessage('New Team Member Successfully Added.');
    }
  }

  addNewTeamMember(emailAddress): void {
    this.organisationManagementService
      .addTeamMember(emailAddress, this.paramsOrgId)
      .subscribe(data => this.afterTeamMemberAdded(data));
  }

  afterTeamMemberDeleted(data): void {
    if (data.success) {
      this.getOrganisationDetails();
      $('#confirmDeleteUserAccountModal').modal('hide');
      this.pubMethods.showInfoMessage('Account deleted.');
    }
  }

  deleteUserAccount(): void {
    this.organisationManagementService
      .callDeleteUserAccount(this.teamMemberToDelete.userId, this.paramsOrgId)
      .subscribe(data => this.afterTeamMemberDeleted(data));
  }

  afterTagViewUpdate(data): void {
    if (data.success) {
      this.pubMethods.showInfoMessage('Tag Viewing Status Updated.');
    }
  }

  updateTagViewLevel(): void {
    this.organisationManagementService
      .updateTagView(this.tagViewingStatus, this.paramsOrgId)
      .subscribe(data => this.afterTagViewUpdate(data));
  }

  getSharedDownloadColumnOptions(): void {
    this.organisationManagementService
      .getSharedDownloadColumnOptions()
      .subscribe(data => this.optionsForSharedDownloadColumn = data);
  }

  setSharedDownloadColumn(): void {
    this.organisationManagementService
      .setSharedDownloadColumn(this.selectedSharedDownloadColumn.id)
      .subscribe(data => {
        if (data){
          this.teamOrOrgSharedDownloadColumn = data;
          this.pubMethods.showInfoMessage('Shared Last Downloaded Column Updated for your team/organisation.');
          this.isEditingTeamOrOrgSharedDownloadColumn = false;
        }
      });
  }


}
