import { Component, Input } from '@angular/core';

@Component({
  selector: 'aira-labelled-pills',
  templateUrl: './aira-labelled-pills.component.html',
  styleUrl: './aira-labelled-pills.component.css',
})
export class AiraLabelledPillsComponent {
  @Input() label = '';
  @Input() data;
  @Input() textPropertyName;
  @Input() onHoverPropertyName;
  pillsToRender: any[] = [];
  leftoverCount: number = 0;

  ngOnInit() {
    this.handlePillsData();
  }

  handlePillsData() {
    if (!this.data?.length) return;

    if (this.data.length <= 4) {
      this.pillsToRender = this.data;
      return;
    }

    if (this.data.length > 4) {
      this.leftoverCount = this.data.length - 4;
      this.pillsToRender = this.data.slice(0, 4);
    }
  }

  showAllPills() {
    this.pillsToRender = this.data;
    this.leftoverCount = null;
  }
}
