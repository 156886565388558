import { Component, Input } from '@angular/core';

@Component({
  selector: 'aira-string-list',
  templateUrl: './aira-string-list.component.html',
  styleUrl: './aira-string-list.component.css',
})
export class AiraStringListComponent {
  @Input() label = '';
  @Input() data: any;
  @Input() headerKeyName = '';
  @Input() contentKeyName = '';
}
