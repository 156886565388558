import { Component, Input } from '@angular/core';
import { publicMethods } from '../../../globals';

@Component({
  selector: 'aira-bar-chart',
  templateUrl: './aira-bar-chart.component.html',
  styleUrl: './aira-bar-chart.component.css',
})
export class AiraBarChartComponent {
  @Input() label;
  @Input() data;
  @Input() schema: Record<string, any>;
  @Input() isVertical = true;
  @Input() extraInfo: string[];

  constructor(public pubMethods: publicMethods) {}

  blues = [
    '#02083b',
    '#0c1d51',
    '#203266',
    '#35497c',
    '#4a5e91',
    '#6174a7',
    '#7f90b9',
  ];
}
