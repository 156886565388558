import { Component, Input } from '@angular/core';

@Component({
  selector: 'aira-string',
  templateUrl: './aira-string.component.html',
  styleUrl: './aira-string.component.css'
})
export class AiraStringComponent {
  @Input() label = '';
  @Input() value = '';
}
