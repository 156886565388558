<div id="chart" *ngIf="chartOptions">
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [fill]="chartOptions.fill"
    [yaxis]="chartOptions.yaxis"
    [tooltip]="chartOptions.tooltip"
    [grid]="chartOptions.grid"
    [title]="chartOptions.title"
  ></apx-chart>
</div>

<div *ngIf="!frequencies || frequencies.length === 0">
  No data to display.
</div>
