export const filterIdToKeywordsMappings = {
  /**********************===========================*************************/
  /**********************======== FIRMOGRAPHICS ======*************************/
  /**********************===========================*************************/
  'company-name-filter': [
    'company name',
    'name',
    'business name',
    'organisation name',
    'name contains',
  ],
  'company-status-filter': [
    'active',
    'distress',
    'dissolved',
    'company status',
    'company status options',
    'status options',
    'status',
    'statuses',
  ],
  'company-category-filter': [
    'company category',
    'company category options',
    'category options',
    'category',
    'categories',
    'private',
    'public',
    'partnerships',
    'investment',
    'charitable and community interest',
  ],
  'company-age-range-filter': ['age', 'company age', 'young', 'old'],
  'sics-overflowing-table-filter': [
    'company sic codes',
    'sic codes',
    'search sic codes',
    'filter sic codes',
    'include sic codes',
    'exclude sic codes',
    'sic code search',
    'sics',
    'sic',
    'industries',
    'industry',
    'industrial classification',
    'classification',
  ],
  'homepage-text-filter': [
    'homepage website text search',
    'homepage text search',
    'website text search',
    'homepage',
    'homepage search',
    'website',
    'website search',
    'text search',
    'boolean',
  ],
  'deep-crawl-search-filter': [
    'zint deep crawl website text search',
    'zint deep crawl',
    'website text search',
    'deep crawl',
    'crawl',
    'website search',
    'text search',
    'boolean',
  ],
  'keyword-website-search-filter': [
    'website keywords',
    'keyword search',
    'website search',
    'keyword filter',
    'search website keywords',
    'website keyword search',
    'keywords',
    'keyword',
  ],
  'address-type-dropdown-filter': [
    'address type',
    'trading address',
    'registered address',
    'trading',
    'registered',
    'addresses',
    'location',
  ],
  'postcode-begins-with-text-filter': [
    'postcode begins with',
    'postcode starts with',
    'postcode start',
    'begins with postcode',
    'starts with postcode',
    'postcode',
  ],
  'postcode-district-text-filter': [
    'postcode district',
    'district',
    'postcode district search',
    'search postcode district',
    'district search',
    'postcode',
  ],
  'uk-region-dropdown-filter': [
    'uk region',
    'uk region selection',
    'uk region dropdown',
    'select uk region',
    'region',
    'regions',
  ],
  'postcode-within-radius-filter': [
    'within radius',
    'within',
    'radius',
    'distance',
    'postcode within',
    'postcode radius',
    'postcode',
    'town',
    'uk town',
  ],
  'town-within-radius-filter': [
    'within radius',
    'within',
    'radius',
    'distance',
    'postcode within',
    'postcode',
    'town',
    'uk town',
    'town radius',
  ],
  'postcode-radius-range-filter': [
    'mile radius of postcode',
    'within radius',
    'within',
    'radius',
    'distance',
    'postcode within',
    'postcode radius',
    'postcode',
    'town',
    'uk town',
    'town radius',
  ],
  'has-overseas-parent-checkbox-filter': [
    'has overseas parent',
    'overseas parent',
    'overseas',
    'parent overseas',
    'has foreign parent',
    'foreign parent',
  ],
  'has-overseas-offices-checkbox-filter': [
    'has office overseas',
    'has offices overseas',
    'office overseas',
    'offices overseas',
    'overseas office',
    'overseas offices',
    'overseas',
    'office abroad',
    'offices abroad',
  ],
  'has-offices-in-countries-filter': [
    'has offices in',
    'offices in',
    'office in',
    'offices in countries',
    'overseas offices',
    'overseas office',
    'overseas',
    'has offices',
    'offices',
    'countries',
    'abroad',
    'international',
    'headquarter',
  ],
  'country-of-origin-filter': [
    'country of origin',
    'country origin',
    'origin',
    'country',
    'has offices in',
    'offices in',
    'office in',
    'offices in countries',
    'overseas',
    'overseas offices',
    'overseas office',
    'has offices',
    'offices',
    'countries',
    'abroad',
    'international',
    'headquarter',
  ],
  'known-office-locations-filter': [
    'known office locations',
    'known office location',
    'known offices',
    'number of offices',
    'total number of offices',
    'number of known offices',
    'total number of known offices',
    'offices',
    'total',
  ],
  'overseas-revenue-filter': [
    'overseas',
    'revenue',
    'overseas revenue',
    'geographical revenue',
    'international revenue',
    'international',
    'global',
  ],
  'geo-revenue-regions-countries-filter': [
    'overseas',
    'revenue',
    'overseas revenue',
    'geographical revenue',
    'international revenue',
    'countries',
    'territories',
    'regions',
    'world',
    'international',
    'global revenue',
    'global',
  ],
  'is-top-level-parent-checkbox-filter': [
    'is top-level parent',
    'top-level parent',
    'is top level parent',
    'top level parent',
    'parent',
    'top-level',
    'top level',
    'structure',
    'corporate structure',
  ],
  'has-parent-checkbox-filter': [
    'has parent',
    'has a parent',
    'has parent company',
    'has parent',
    'has parents',
    'parent',
    'company parent',
    'structure',
    'corporate structure',
  ],
  'does-not-have-parent-checkbox-filter': [
    'does not have parent',
    'does not have a parent',
    'does not have parent company',
    'no parent',
    'no parents',
    'no parent company',
    'does not have parent',
    'structure',
    'corporate structure',
  ],
  'has-child-checkbox-filter': [
    'has child',
    'has a child',
    'has child company',
    'has child',
    'has children',
    'child',
    'company child',
    'structure',
    'corporate structure',
  ],
  'does-not-have-child-checkbox-filter': [
    'does not have child',
    'does not have a child',
    'does not have child company',
    'no child',
    'no child company',
    'does not have child',
    'structure',
    'corporate structure',
  ],
  'number-ultimate-beneficial-owners-filter': [
    'number of ultimate beneficial owners',
    'ultimate beneficial owners',
    'ultimate',
    'beneficial owners',
    'number of owners',
    'ubo',
    'ubos',
    'structure',
    'corporate structure',
  ],
  'ubo-countries-filter': [
    'ubo',
    'ubos',
    'structure',
    'corporate structure',
    'ultimate',
    'countries of owner',
    'owner country',
    'owner',
    'owners country',
    'non uk',
    'uk',
    'british',
    'non british',
    'foreign',
    'foreign owner',
  ],
  'list-companies-in-group-filter': [
    'list of all companies',
    'list',
    'corporate structure',
    'structure',
    'related companies',
    'related',
    'list of related companies',
    'company number',
    'group of companies',
    'group',
    'groups',
    'companies in group',
    'companies',
  ],
  'has-website-checkbox-filter': [
    'has website',
    'has a website',
    'website',
    'company website',
    'website existence',
    'web presence',
    'contact',
    'contact details',
  ],
  'has-phone-checkbox-filter': [
    'has phone number',
    'has a phone number',
    'phone number',
    'phone',
    'telephone',
    'mobile',
    'company phone',
    'contact phone',
    'contact',
    'contact details',
  ],
  'has-email-checkbox-filter': [
    'has email address',
    'has an email address',
    'email address',
    'email',
    'company email',
    'contact email',
    'contact',
    'contact details',
  ],
  'web-technologies-list-filter': [
    'web technologies',
    'web technology',
    'software technologies',
    'software technology',
    'web technologies list',
    'technology options',
    'web technology options',
    'software',
    'program',
    'testing',
    'plugin',
    'website',
    'programming',
    'webflow',
    'wordpress',
    'web',
  ],
  'website-visitor-filter': [
    'website',
    'website visitor',
    'website visitors',
    'monthly visitors',
    'visitors',
    'visitor',
    'average monthly website visitors',
    'average number of visitors',
    'traffic',
  ],
  'website-homepage-text-filter': [
    'homepage website text search',
    'homepage text search',
    'website text search',
    'homepage',
    'homepage search',
    'website',
    'website search',
    'text search',
    'boolean',
  ],
  'website-deep-crawl-search-filter': [
    'zint deep crawl website text search',
    'zint deep crawl',
    'website text search',
    'deep crawl',
    'crawl',
    'website search',
    'text search',
    'boolean',
  ],
  'website-keyword-search-filter': [
    'website keywords',
    'keyword search',
    'website search',
    'keyword filter',
    'search website keywords',
    'website keyword search',
    'keywords',
    'keyword',
  ],
  /**********************===========================*************************/
  /**********************======== FINANCIALS ======*************************/
  /**********************===========================*************************/
  'gross-profit-filter': ['profit', 'gross profit', 'gross'],
  'operating-profit-filter': ['operating', 'profit'],
  'profit-before-tax-filter': ['profit', 'before', 'tax'],
  'profit-after-tax-filter': ['profit', 'after', 'tax'],
  'gross-profit-growth-rate-filter': [
    'gross',
    'profit',
    'gross profit',
    'growth',
    'rate',
  ],
  'gross-profit-absolute-growth-filter': [
    'gross',
    'profit',
    'gross profit',
    'growth',
  ],
  'profit-before-tax-growth-rate-filter': ['profit', 'growth', 'tax', 'rate'],
  'profit-before-tax-absolute-growth-filter': ['profit', 'growth', 'tax'],
  'profit-after-tax-growth-rate-filter': ['profit', 'growth', 'tax', 'rate'],
  'profit-after-tax-absolute-growth-filter': [
    'profit',
    'absolute growth',
    'tax',
  ],
  'operating-profit-growth-rate-filter': [
    'operating',
    'profit',
    'growth',
    'rate',
  ],
  'operating-profit-absolute-growth-filter': ['operating', 'profit', 'growth'],
  'revenue-filter': ['revenue'],
  'other-operating-income-filter': [
    'other operating income',
    'operating income',
    'income',
  ],
  'cost-of-sales-filter': ['cost', 'sales', 'cost of sales'],
  'operating-expenses-filter': ['operating', 'expenses', 'operating expenses'],
  'interest-payable-filter': ['interest', 'payable', 'interest payable'],
  'ebitda-filter': [
    'ebitda',
    'earnings',
    'before interest',
    'tax',
    'depreciation',
    'amortization',
    'amortisation',
  ],
  'corporation-tax-filter': ['corporation tax', 'tax', 'corporation'],
  'tax-credit-filter': ['tax credit', 'credit', 'tax'],
  'fixed-assets-filter': ['fixed assets', 'assets'],
  'current-assets-filter': ['current assets', 'assets'],
  'cash-at-bank-filter': ['cash', 'bank', 'cash at bank'],
  'raw-materials-and-consumable-filter': [
    'raw materials',
    'consumable',
    'materials',
    'raw',
  ],
  'total-assets-filter': ['total assets', 'assets'],
  'debtors-due-within-one-year-filter': [
    'debtors',
    'due',
    'within one year',
    'one year',
  ],
  'debtors-due-after-one-year-filter': [
    'debtors',
    'due',
    'after one year',
    'one year',
  ],
  'trade-debtors-filter': ['trade debtors', 'debtors', 'trade'],
  'other-debtors-filter': ['other debtors', 'debtors', 'other'],
  'total-debtors-filter': ['total debtors', 'debtors'],
  'creditors-due-within-one-year-filter': [
    'creditors',
    'due',
    'within one year',
    'one year',
  ],
  'creditors-due-after-one-year-filter': [
    'creditors',
    'due',
    'after one year',
    'one year',
  ],
  'total-creditors-filter': ['total creditors', 'creditors'],
  'dividends-filter': ['dividends'],
  'total-assets-minus-current-liabilities-filter': [
    'total assets',
    'current liabilities',
    'assets minus liabilities',
  ],
  'capital-and-reserves-filter': [
    'capital',
    'reserves',
    'capital and reserves',
  ],
  'retained-earnings-filter': ['retained earnings', 'earnings'],
  'shareholders-funds-filter': ['shareholders funds', 'shareholders', 'funds'],
  'share-premium-filter': ['share premium', 'share', 'premium'],
  'gross-margin-percentage-filter': ['gross margin', 'margin', 'percentage'],
  'fx-gain-or-loss-filter': ['fx', 'foreign exchange', 'gain', 'loss'],
  'wages-and-salaries-filter': ['wages', 'salaries', 'compensation', 'payroll'],
  'revenue-growth-rate-filter': ['revenue', 'growth rate', 'rate'],
  'revenue-absolute-growth-filter': ['revenue', 'absolute growth', 'growth'],
  'other-operating-income-growth-rate-filter': [
    'other operating income',
    'growth rate',
    'income',
  ],
  'other-operating-income-absolute-growth-filter': [
    'other operating income',
    'absolute growth',
    'income',
  ],
  'cost-of-sales-growth-rate-filter': ['cost of sales', 'growth rate', 'sales'],
  'cost-of-sales-absolute-growth-filter': [
    'cost of sales',
    'absolute growth',
    'sales',
  ],
  'operating-expenses-growth-rate-filter': [
    'operating expenses',
    'growth rate',
    'expenses',
  ],
  'operating-expenses-absolute-growth-filter': [
    'operating expenses',
    'absolute growth',
    'expenses',
  ],
  'interest-payable-growth-rate-filter': [
    'interest payable',
    'growth rate',
    'interest',
  ],
  'interest-payable-absolute-growth-filter': [
    'interest payable',
    'absolute growth',
    'interest',
  ],
  'corporation-tax-growth-rate-filter': [
    'corporation tax',
    'growth rate',
    'tax',
  ],
  'corporation-tax-absolute-growth-filter': [
    'corporation tax',
    'absolute growth',
    'tax',
  ],
  'tax-credit-growth-rate-filter': ['tax credit', 'growth rate', 'tax'],
  'tax-credit-absolute-growth-filter': ['tax credit', 'absolute growth', 'tax'],
  'fixed-assets-growth-rate-filter': ['fixed assets', 'growth rate', 'assets'],
  'fixed-assets-absolute-growth-filter': [
    'fixed assets',
    'absolute growth',
    'assets',
  ],
  'current-assets-growth-rate-filter': [
    'current assets',
    'growth rate',
    'assets',
  ],
  'current-assets-absolute-growth-filter': [
    'current assets',
    'absolute growth',
    'assets',
  ],
  'cash-at-bank-growth-rate-filter': [
    'cash at bank',
    'bank',
    'growth rate',
    'cash',
  ],
  'cash-at-bank-absolute-growth-filter': [
    'cash at bank',
    'absolute growth',
    'cash',
    'bank',
  ],
  'raw-materials-and-consumable-growth-rate-filter': [
    'raw',
    'materials',
    'consumable',
    'growth rate',
  ],
  'raw-materials-and-consumable-absolute-growth-filter': [
    'raw',
    'materials',
    'consumable',
    'absolute growth',
  ],
  'total-assets-growth-rate-filter': ['total assets', 'growth rate', 'assets'],
  'total-assets-absolute-growth-filter': [
    'total assets',
    'absolute growth',
    'assets',
  ],
  'debtors-due-within-one-year-growth-rate-filter': [
    'debtors due within one year',
    'growth rate',
    'debtors',
  ],
  'debtors-due-within-one-year-absolute-growth-filter': [
    'debtors due within one year',
    'absolute growth',
    'debtors',
  ],
  'debtors-due-after-one-year-growth-rate-filter': [
    'debtors due after one year',
    'growth rate',
    'debtors',
  ],
  'debtors-due-after-one-year-absolute-growth-filter': [
    'debtors due after one year',
    'absolute growth',
    'debtors',
  ],
  'trade-debtors-growth-rate-filter': [
    'trade debtors',
    'growth rate',
    'debtors',
  ],
  'trade-debtors-absolute-growth-filter': [
    'trade debtors',
    'absolute growth',
    'debtors',
  ],
  'other-debtors-growth-rate-filter': [
    'other debtors',
    'growth rate',
    'debtors',
  ],
  'other-debtors-absolute-growth-filter': [
    'other debtors',
    'absolute growth',
    'debtors',
  ],
  'total-debtors-growth-rate-filter': [
    'total debtors',
    'growth rate',
    'debtors',
  ],
  'total-debtors-absolute-growth-filter': [
    'total debtors',
    'absolute growth',
    'debtors',
  ],
  'creditors-due-within-one-year-growth-rate-filter': [
    'creditors due within one year',
    'growth rate',
    'creditors',
  ],
  'creditors-due-within-one-year-absolute-growth-filter': [
    'creditors due within one year',
    'absolute growth',
    'creditors',
  ],
  'creditors-due-after-one-year-growth-rate-filter': [
    'creditors due after one year',
    'growth rate',
    'creditors',
  ],
  'creditors-due-after-one-year-absolute-growth-filter': [
    'creditors due after one year',
    'absolute growth',
    'creditors',
  ],
  'total-creditors-growth-rate-filter': [
    'total creditors',
    'growth rate',
    'creditors',
  ],
  'total-creditors-absolute-growth-filter': [
    'total creditors',
    'absolute growth',
    'creditors',
  ],
  'dividends-growth-rate-filter': ['dividends', 'growth rate'],
  'dividends-absolute-growth-filter': ['dividends', 'absolute growth'],
  'total-assets-minus-current-liabilities-growth-rate-filter': [
    'total assets minus current liabilities',
    'growth rate',
    'assets',
    'liabilities',
  ],
  'total-assets-minus-current-liabilities-absolute-growth-filter': [
    'total assets minus current liabilities',
    'absolute growth',
    'assets',
    'liabilities',
  ],
  'capital-and-reserves-growth-rate-filter': [
    'capital and reserves',
    'growth rate',
    'capital',
    'reserves',
  ],
  'capital-and-reserves-absolute-growth-filter': [
    'capital and reserves',
    'absolute growth',
    'capital',
    'reserves',
  ],
  'retained-earnings-growth-rate-filter': [
    'retained earnings',
    'growth rate',
    'earnings',
  ],
  'retained-earnings-absolute-growth-filter': [
    'retained earnings',
    'absolute growth',
    'earnings',
  ],
  'shareholders-funds-growth-rate-filter': [
    'shareholders funds',
    'growth rate',
    'funds',
    'shareholders',
  ],
  'shareholders-funds-absolute-growth-filter': [
    'shareholders funds',
    'absolute growth',
    'funds',
    'shareholders',
  ],
  'share-premium-growth-rate-filter': ['share premium', 'growth rate', 'share'],
  'share-premium-absolute-growth-filter': [
    'share premium',
    'absolute growth',
    'share',
  ],

  'number-of-employees-growth-rate-filter': [
    'number of employees',
    'growth rate',
    'employees',
  ],
  'number-of-employees-absolute-growth-filter': [
    'number of employees',
    'absolute growth',
    'employees',
  ],
  'fx-gain-or-loss-growth-rate-filter': [
    'fx',
    'fx gain or loss',
    'growth rate',
    'foreign exchange',
  ],
  'fx-gain-or-loss-absolute-growth-filter': [
    'fx',
    'fx gain or loss',
    'absolute growth',
    'foreign exchange',
  ],
  'wages-and-salaries-growth-rate-filter': [
    'wages',
    'salaries',
    'wages and salaries',
    'growth rate',
    'compensation',
  ],
  'wages-and-salaries-absolute-growth-filter': [
    'wages',
    'salaries',
    'wages and salaries',
    'absolute growth',
    'compensation',
  ],
  'accounts-overdue-checkbox-filter': ['accounts', 'filing', 'overdue', 'due'],
  'accounts-due-filter': ['accounts', 'filing', 'due', 'overdue'],
  'latest-accounts-submitted-filter': [
    'latest',
    'accounts',
    'submitted',
    'submission',
  ],
  'has-raised-investment-checkbox-filter': [
    'has raised investment',
    'raised',
    'investment',
  ],
  'investment-amount-raised-filter': [
    'investment amount',
    'raised amount',
    'investment',
    'amount',
  ],
  'has-shares-checkbox-filter': [
    'shares',
    'issued',
    'divided',
    'shares filing',
  ],
  'shares-filings-within-days-filter': [
    'shares',
    'filings',
    'shares filings',
    'within days',
  ],
  'share-filing-capital-value-filter': [
    'share filing',
    'capital value',
    'share',
    'filing',
    'capital',
  ],
  'has-issued-a-grant-checkbox-filter': ['issued a grant', 'issued', 'grant'],
  'grant-amount-filter': ['grant amount', 'grant', 'amount'],
  'grant-months-until-project-end-filter': [
    'grant',
    'months',
    'period',
    'project',
    'end',
  ],
  'has-charge-registered-checkbox-filter': [
    'charge registered',
    'charge',
    'registered',
  ],
  'has-outstanding-charge-checkbox-filter': [
    'outstanding charge',
    'charge',
    'one or more charge',
  ],
  'has-lei-number-checkbox-filter': ['lei number', 'lei'],
  'lei-registration-status-dropdown-filter': ['lei status', 'status', 'lei'],
  'accountant-name-contains-filter': ['accountant name', 'accountant', 'name'],
  'banker-name-contains-filter': ['banker name', 'banker', 'name'],
  'accounts-search-filter': [
    'accounts',
    'search',
    'accounts search',
    'keyword',
    'keywords',
    'boolean',
    'year accounts',
    'company accounts',
    'annual report',
    'financials report',
  ],

  'LandAndBuildingsTangibleAssets-filter': [
    'land and buildings',
    'tangible assets',
    'land',
    'buildings',
  ],
  'LandAndBuildingsCostTangibleAssets-filter': [
    'land and buildings cost',
    'cost',
    'tangible assets',
    'land',
    'buildings',
  ],
  'LandAndBuildingsAdditionsTangibleAssets-filter': [
    'land and buildings additions',
    'additions',
    'tangible assets',
    'land',
    'buildings',
  ],
  'LandAndBuildingsDisposalsTangibleAssets-filter': [
    'land and buildings disposals',
    'disposals',
    'tangible assets',
    'land',
    'buildings',
  ],
  'LandAndBuildingsDepreciationTangibleAssets-filter': [
    'land and buildings depreciation',
    'depreciation',
    'tangible assets',
    'land',
    'buildings',
  ],
  'PlantAndMachineryTangibleAssets-filter': [
    'plant and machinery',
    'tangible assets',
    'plant',
    'machinery',
  ],
  'PlantAndMachineryCostTangibleAssets-filter': [
    'plant and machinery cost',
    'cost',
    'tangible assets',
    'plant',
    'machinery',
  ],
  'PlantAndMachineryAdditionsTangibleAssets-filter': [
    'plant and machinery additions',
    'additions',
    'tangible assets',
    'plant',
    'machinery',
  ],
  'PlantAndMachineryDisposalsTangibleAssets-filter': [
    'plant and machinery disposals',
    'disposals',
    'tangible assets',
    'plant',
    'machinery',
  ],
  'PlantAndMachineryDepreciationTangibleAssets-filter': [
    'plant and machinery depreciation',
    'depreciation',
    'tangible assets',
    'plant',
    'machinery',
  ],
  'FurnitureAndFittingsTangibleAssets-filter': [
    'furniture and fittings',
    'tangible assets',
    'furniture',
    'fittings',
  ],
  'FurnitureAndFittingsCostTangibleAssets-filter': [
    'furniture and fittings cost',
    'cost',
    'tangible assets',
    'furniture',
    'fittings',
  ],
  'FurnitureAndFittingsAdditionsTangibleAssets-filter': [
    'furniture and fittings additions',
    'additions',
    'tangible assets',
    'furniture',
    'fittings',
  ],
  'FurnitureAndFittingsDisposalsTangibleAssets-filter': [
    'furniture and fittings disposals',
    'disposals',
    'tangible assets',
    'furniture',
    'fittings',
  ],
  'FurnitureAndFittingsDepreciationTangibleAssets-filter': [
    'furniture and fittings depreciation',
    'depreciation',
    'tangible assets',
    'furniture',
    'fittings',
  ],
  'VehiclesTangibleAssets-filter': [
    'vehicles',
    'tangible assets',
    'vehicle',
    'assets',
  ],
  'VehiclesCostTangibleAssets-filter': [
    'vehicles cost',
    'cost',
    'tangible assets',
    'vehicles',
    'assets',
  ],
  'VehiclesAdditionsTangibleAssets-filter': [
    'vehicles additions',
    'additions',
    'tangible assets',
    'vehicles',
    'assets',
  ],
  'VehiclesDisposalsTangibleAssets-filter': [
    'vehicles disposals',
    'disposals',
    'tangible assets',
    'vehicles',
    'assets',
  ],
  'VehiclesDepreciationTangibleAssets-filter': [
    'vehicles depreciation',
    'depreciation',
    'tangible assets',
    'vehicles',
    'assets',
  ],
  'ComputerEquipmentTangibleAssets-filter': [
    'computer equipment',
    'tangible assets',
    'computer',
    'equipment',
    'assets',
  ],
  'ComputerEquipmentCostTangibleAssets-filter': [
    'computer equipment cost',
    'cost',
    'tangible assets',
    'computer',
    'equipment',
    'assets',
  ],
  'ComputerEquipmentAdditionsTangibleAssets-filter': [
    'computer equipment additions',
    'additions',
    'tangible assets',
    'computer',
    'equipment',
    'assets',
  ],
  'ComputerEquipmentDisposalsTangibleAssets-filter': [
    'computer equipment disposals',
    'disposals',
    'tangible assets',
    'computer',
    'equipment',
    'assets',
  ],
  'ComputerEquipmentDepreciationTangibleAssets-filter': [
    'computer equipment depreciation',
    'depreciation',
    'tangible assets',
    'computer',
    'equipment',
    'assets',
  ],
  'LeaseholdAssetsTangibleAssets-filter': [
    'leasehold assets',
    'tangible assets',
    'leasehold',
    'assets',
  ],
  'LeaseholdAssetsCostTangibleAssets-filter': [
    'leasehold assets cost',
    'cost',
    'tangible assets',
    'leasehold',
    'assets',
  ],
  'LeaseholdAssetsAdditionsTangibleAssets-filter': [
    'leasehold assets additions',
    'additions',
    'tangible assets',
    'leasehold',
    'assets',
  ],
  'LeaseholdAssetsDisposalsTangibleAssets-filter': [
    'leasehold assets disposals',
    'disposals',
    'tangible assets',
    'leasehold',
    'assets',
  ],
  'LeaseholdAssetsDepreciationTangibleAssets-filter': [
    'leasehold assets depreciation',
    'depreciation',
    'tangible assets',
    'leasehold',
    'assets',
  ],
  'AssetsUnderConstructionTangibleAssets-filter': [
    'assets under construction',
    'tangible assets',
    'assets',
    'construction',
  ],
  'AssetsUnderConstructionCostTangibleAssets-filter': [
    'assets under construction cost',
    'cost',
    'tangible assets',
    'assets',
    'construction',
  ],
  'AssetsUnderConstructionAdditionsTangibleAssets-filter': [
    'assets under construction additions',
    'additions',
    'tangible assets',
    'assets',
    'construction',
  ],
  'AssetsUnderConstructionDisposalsTangibleAssets-filter': [
    'assets under construction disposals',
    'disposals',
    'tangible assets',
    'assets',
    'construction',
  ],
  'AssetsUnderConstructionDepreciationTangibleAssets-filter': [
    'assets under construction depreciation',
    'depreciation',
    'tangible assets',
    'assets',
    'construction',
  ],
  'OtherTangibleAssets-filter': ['other', 'tangible assets', 'other assets'],
  'OtherCostTangibleAssets-filter': [
    'other cost',
    'cost',
    'tangible assets',
    'other',
    'assets',
  ],
  'OtherAdditionsTangibleAssets-filter': [
    'other additions',
    'additions',
    'tangible assets',
    'other',
    'assets',
  ],
  'OtherDisposalsTangibleAssets-filter': [
    'other disposals',
    'disposals',
    'tangible assets',
    'other',
    'assets',
  ],
  'OtherDepreciationTangibleAssets-filter': [
    'other depreciation',
    'depreciation',
    'tangible assets',
    'other',
    'assets',
  ],
  'GoodwillIntangibleAssets-filter': [
    'goodwill intangible assets',
    'goodwill',
    'intangible assets',
    'intangible',
    'assets',
  ],
  'GoodwillCostIntangibleAssets-filter': [
    'goodwill cost intangible assets',
    'goodwill cost',
    'cost',
    'intangible assets',
    'intangible',
    'assets',
  ],
  'GoodwillAdditionsIntangibleAssets-filter': [
    'goodwill additions intangible assets',
    'goodwill additions',
    'additions',
    'intangible assets',
    'intangible',
    'assets',
  ],
  'GoodwillAmortisationIntangibleAssets-filter': [
    'goodwill amortisation intangible assets',
    'goodwill amortisation',
    'amortisation',
    'intangible assets',
    'intangible',
    'assets',
  ],
  'ComputerSoftwareIntangibleAssets-filter': [
    'computer software',
    'intangible assets',
    'computer',
    'software',
    'intangible',
    'assets',
  ],
  'ComputerSoftwareCostIntangibleAssets-filter': [
    'computer software cost',
    'cost',
    'intangible assets',
    'computer',
    'software',
    'intangible',
    'assets',
  ],
  'ComputerSoftwareAdditionsIntangibleAssets-filter': [
    'computer software additions',
    'additions',
    'intangible assets',
    'computer',
    'software',
    'intangible',
    'assets',
  ],
  'ComputerSoftwareAmortisationIntangibleAssets-filter': [
    'computer software amortisation',
    'amortisation',
    'intangible assets',
    'computer',
    'software',
    'intangible',
    'assets',
  ],
  'PatentsTrademarksLicencesIntangibleAssets-filter': [
    'patents trademarks licences',
    'intangible assets',
    'patents',
    'trademarks',
    'licences',
    'intangible',
    'assets',
  ],
  'PatentsTrademarksLicencesCostIntangibleAssets-filter': [
    'patents trademarks licences cost',
    'cost',
    'intangible assets',
    'patents',
    'trademarks',
    'licences',
    'intangible',
    'assets',
  ],
  'PatentsTrademarksLicencesAdditionsIntangibleAssets-filter': [
    'patents trademarks licences additions',
    'additions',
    'intangible assets',
    'patents',
    'trademarks',
    'licences',
    'intangible',
    'assets',
  ],
  'PatentsTrademarksLicencesAmortisationIntangibleAssets-filter': [
    'patents trademarks licences amortisation',
    'amortisation',
    'intangible assets',
    'patents',
    'trademarks',
    'licences',
    'intangible',
    'assets',
  ],
  'OtherIntangibleAssets-filter': [
    'other intangible assets',
    'intangible assets',
    'other',
    'assets',
  ],
  'OtherIntangibleAssetsCost-filter': [
    'other intangible assets cost',
    'cost',
    'intangible assets',
    'other',
    'assets',
  ],
  'OtherIntangibleAssetsAdditions-filter': [
    'other intangible assets additions',
    'additions',
    'intangible assets',
    'other',
    'assets',
  ],
  'OtherIntangibleAssetsAmortisation-filter': [
    'other intangible assets amortisation',
    'amortisation',
    'intangible assets',
    'other',
    'assets',
  ],

  'charge-holder-name-filter': [
    'charge holder',
    'name',
    'company charge holder',
    'holder',
  ],
  'charge-created-period-filter': [
    'charges',
    'charge',
    'charge period',
    'charge months',
    'company charges',
  ],
  'ignore-satisfied-charges-checkbox-filter': [
    'satisfied charges',
    'satisfied',
    'company charges',
    'charges',
  ],
  'low-energy-usage-checkbox-filter': [
    'low',
    'energy consumption',
    'low energy',
    'energy usage',
    'energy report',
    'low energy usage',
  ],
  'total-energy-filter': ['total energy', 'energy', 'total'],
  'gas-energy-usage-filter': [
    'gas energy usage',
    'gas energy',
    'energy usage',
    'gas',
    'energy',
    'usage',
  ],
  'electric-energy-usage-filter': [
    'electric energy usage',
    'electric energy',
    'energy usage',
    'electric',
    'energy',
    'usage',
  ],
  'scope-1-energy-usage-filter': [
    'scope 1 energy usage',
    'scope 1',
    'energy usage',
    'scope',
    'energy',
    'usage',
  ],
  'scope-2-energy-usage-filter': [
    'scope 2 energy usage',
    'scope 2',
    'energy usage',
    'scope',
    'energy',
    'usage',
  ],
  'scope-3-energy-usage-filter': [
    'scope 3 energy usage',
    'scope 3',
    'energy usage',
    'scope',
    'energy',
    'usage',
  ],
  'total-emissions-filter': ['total emissions', 'emissions', 'total'],
  'scope-1-emissions-filter': [
    'scope 1 emissions',
    'scope 1',
    'emissions',
    'scope',
  ],
  'scope-2-emissions-filter': [
    'scope 2 emissions',
    'scope 2',
    'emissions',
    'scope',
  ],
  'scope-3-emissions-filter': [
    'scope 3 emissions',
    'scope 3',
    'emissions',
    'scope',
  ],
  'kg-co2e-per-million-revenue-filter': [
    'kg co2e per million revenue',
    'kg co2e',
    'co2e per million revenue',
    'co2e',
    'million revenue',
    'emissions',
    'emissions revenue',
  ],
  'kg-co2e-per-employee-filter': [
    'kg co2e per employee',
    'kg co2e',
    'co2e per employee',
    'co2e',
    'employee',
    'emissions',
  ],
  /**********************===========================*************************/
  /**********************======== OPERATIONS ======*************************/
  /**********************===========================*************************/
  'import-export-overflowing-table-filter': [
    'import',
    'export',
    'imports',
    'exports',
    'products',
    'product table',
    'import export products',
  ],
  'import-export-option-dropdown-filter': [
    'imported',
    'exported',
    'import and export',
    'in the last',
  ],
  'import-export-month-range-filter': [
    'imported',
    'exported',
    'import and export',
    'in the last',
  ],
  'import-export-frequency-filter': [
    'import frequency',
    'export frequency',
    'import export frequency',
    'frequency',
  ],
  'import-export-frequency-range-filter': [
    'import frequency',
    'export frequency',
    'import export frequency',
    'frequency',
  ],
  'import-export-frequency-months-filter': [
    'import frequency',
    'export frequency',
    'import export frequency',
    'frequency',
  ],
  'exclude-estimated-checkbox-filter': [
    'head count',
    'head',
    'count',
    'employees',
    'headcount',
  ],
  'employee-range-filter': [
    'head count',
    'head',
    'count',
    'employees',
    'headcount',
  ],
  'last-year-head-count-growth-filter': [
    'last year head count growth',
    'last year',
    'head count growth',
    'growth',
    'headcount',
    'count',
  ],
  'departmental-breakdown-filter': [
    'departmental breakdown',
    'departmental',
    'breakdown',
    'employees',
    'department',
  ],
  'people-overflowing-table-filter': [
    'departments',
    'department',
    'list of departments',
    'departmental',
    'departmental breakdown',
  ],
  'Wages-filter': [
    'wages',
    'remuneration',
    'package',
    'salary',
    'salaries',
    'compensation',
  ],
  'average-director-age-filter': [
    'average director age',
    'average',
    'director',
    'director age',
    'age',
  ],
  'number-of-directors-filter': ['number of directors', 'number', 'directors'],
  'pscs-real-persons-only-checkbox-filter': [
    'real persons',
    'real pscs',
    'pscs',
    'psc',
    'real persons',
    'exclude',
    'legal entities',
  ],
  'number-of-pscs-filter': [
    'number of persons of significant control',
    'number',
    'persons',
    'significant control',
    'psc',
    'pscs',
  ],
  'new-officer-job-title-filter': [
    'new officer',
    'new officer job',
    'officer job title',
    'officer title',
    'job title',
    'director title',
    'title',
    'recent',
  ],
  'directorship-range-filter': [
    'directorship',
    'appointment',
    'appointed',
    'director appointed',
  ],
  'officer-nationalities-filter': [
    'officer nationalities',
    'nationalities',
    'citizenship',
    'countries of origin',
  ],
  'officer-nationalities-table-filter': [
    'officer nationalities',
    'nationalities',
    'citizenship',
    'countries of origin',
  ],
  'leadership-job-title-filter': [
    'leadership job title',
    'leadership role',
    'leadership title',
    'director job title',
    'title',
  ],
  'is-hiring-checkbox-filter': [
    'is hiring',
    'hiring',
    'recruiting',
    'recruitment',
  ],
  'include-recruiters-checkbox-filter': [
    'hiring',
    'include recruiters',
    'recruiters',
    'agency',
    'recruitment agency',
    'job agency',
    'job agencies',
    'recruiter listings',
  ],
  'hiring-job-title-contains-filter': [
    'hiring job title contains',
    'hiring job title',
    'hiring',
    'job title',
  ],
  'hiring-description-contains-filter': [
    'job description contains',
    'hiring description contains',
    'job description',
    'description',
  ],
  'hiring-day-limit-filter': ['hiring day limit', 'hiring', 'hiring period'],
  'number-of-roles-hiring-filter': [
    'number of roles',
    'roles hiring',
    'job posts',
    'vacancy',
    'jobs',
    'job',
  ],
  'number-of-roles-hiring-period-filter': [
    'number of roles period',
    'number of roles',
    'hiring period',
    'vacancy period',
    'vacancy',
    'availability',
  ],
  'fiscal-year-end-range-filter': [
    'days until financial year end',
    'days until',
    'financial year end',
    'fiscal year end',
    'fiscal',
  ],
  'fiscal-year-end-in-month-filter': [
    'fiscal',
    'fiscal year end in month',
    'financial year ends',
    'financial year',
    'fiscal year',
    'year end',
    'month',
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'september',
    'december',
  ],
  'anniversary-filter': [
    'days until incorporation anniversary',
    'days until',
    'incorporation anniversary',
    'anniversary',
  ],
  'event-name-filter': ['event', 'event name', 'exhibition', 'exhibition name'],
  'events-mile-radius-filter': [
    'event',
    'event distance',
    'event location',
    'event postcode',
    'event city',
    'event town',
    'mile',
    'radius',
    'postcode radius',
    'exhibition postcode',
  ],
  'events-day-range-filter': [
    'event day range',
    'upcoming event',
    'event',
    'event calendar',
    'event day',
  ],
  'news-article-text-filter': [
    'news article text',
    'news',
    'article',
    'text search',
  ],
  'news-source-dropdown-filter': [
    'news',
    'news source',
    'news sources',
    'publication',
    'self published',
    'internal news',
    'external news',
  ],
  'news-period-range-filter': [
    'news',
    'news day range',
    'news published',
    'news published date',
    'news age',
  ],
  'amazon-seller-checkbox-filter': [
    'amazon',
    'marketplace',
    'amazon seller',
    'seller',
  ],
  'gcloud-seller-checkbox-filter': [
    'gcloud seller',
    'gcloud',
    'seller',
    'government g cloud',
    'digital marketplace',
    'digital products/services',
    'public sector organisations',
  ],
  'has-awarded-contract-checkbox-filter': [
    'has been awarded contract',
    'has awarded contract',
    'has',
    'awarded',
    'contract',
  ],
  'contract-value-filter': ['contract value', 'contract', 'value'],
  'contract-awarded-period-filter': [
    'contract',
    'contract period',
    'contract awarded',
    'awarded',
    'awarded period',
  ],
  'contract-starts-within-filter': [
    'contract starts within',
    'contract',
    'contract starts',
    'contract period',
  ],
  'contract-ends-within-filter': [
    'contract ends within',
    'contract',
    'contract ends',
    'contract period',
  ],
  'contract-title-text-filter': [
    'contract title contains',
    'contract title text',
    'contract title',
    'contract',
    'title',
  ],
  'contract-description-text-filter': [
    'contract description contains',
    'contract description text',
    'contract description',
    'contract',
    'description',
  ],
  'trademark-name-contains-filter': [
    'trademark name contains',
    'trademark name',
    'trademark title',
    'trademark',
    'trademark text',
    'name',
  ],
  'trademark-description-contains-filter': [
    'trademark description contains',
    'trademark description',
    'trademark description text',
    'trademark',
    'description',
  ],
  'trademark-status-filter': [
    'trademark status',
    'active',
    'expired',
    'removed',
    'rejected',
    'dead',
    'processing',
  ],
  'trademark-overflowing-table-filter': [
    'trademark class',
    'trademark classes',
    'trademark classification',
    'trademark type',
    'trademarks',
  ],
  'NumberOfActiveTrademarks-filter': [
    'number of active trademarks',
    'number of trademarks',
    'active trademarks',
    'trademarks',
  ],
  'has-patent-checkbox-filter': [
    'has any patent record',
    'has any patent',
    'any patent record',
    'has patent',
    'patent record',
    'patent',
    'patents',
  ],
  'has-active-patent-checkbox-filter': [
    'has active patent',
    'active patent',
    'active patents',
    'has active',
    'patent',
    'patents',
    'active',
  ],
  'filed-patent-in-the-last-filter': [
    'filed patent in the last',
    'filed patent',
    'patent filing',
    'patent in the last',
    'filed',
    'patent',
  ],
  'awarded-patent-in-the-last-filter': [
    'awarded patent in the last',
    'awarded patent',
    'patent awarded',
    'awarded',
    'patent',
  ],
  'VehicleFleetSize-filter': [
    'vehicle fleet size',
    'fleet size',
    'vehicle fleet',
    'vehicle size',
    'fleet',
    'vehicle',
    'size',
  ],
  'VehicleFleetSizeAuthorised-filter': [
    'vehicle fleet size authorised',
    'fleet size authorised',
    'authorised vehicle fleet size',
    'fleet size authorized',
    'vehicle',
    'fleet',
    'size',
    'authorised',
  ],
  'TrailerFleetSize-filter': [
    'trailer fleet size',
    'fleet size',
    'trailer fleet',
    'trailer size',
    'fleet',
    'trailer',
    'size',
  ],
  'TrailerFleetSizeAuthorised-filter': [
    'trailer fleet size authorised',
    'fleet size authorised',
    'authorised trailer fleet size',
    'fleet size authorized',
    'trailer',
    'fleet',
    'size',
    'authorised',
  ],
  /**********************===========================*************************/
  /**********************======== MY ZINT ======*************************/
  /**********************===========================*************************/
  'custom-columns-filter': [
    'custom column',
    'custom columns',
    'custom',
    'columns',
    'score',
    'download',
    'campaign',
    'locked',
    'lockable',
    'shared',
    'sharing',
    'grow',
    'zint grow',
    'zint',
  ],
  'exclude-team-tag-checkbox-filter': [
    'exclude team tags',
    'tags',
    'tagged by team',
    'tagged by team members',
    'organisation tagged',
  ],
  'positive-tags-filter': ['is tagged', 'positive tags', 'tags'],
  'negative-tags-filter': ['is not tagged', 'negative tags', 'tags'],
  'organisations-alerted-range-filter': [
    'organisations alerted in the range',
    'organisations alerts',
    'recent alerts',
    'alerts',
  ],
  'show-unread-alerts-only-checkbox-filter': [
    'show unread alerts only',
    'show unread alerts',
    'unread alerts only',
    'unread alerts',
    'alerts',
  ],
  'alert-options-filter': [
    'alerts',
    'alert options',
    'my alerts',
    'organisation alerts',
    'unread alerts',
    'new alerts',
  ],
  'HeatScore-filter': [
    'heatscore',
    'heat score',
    'heat',
    'score',
    'chili score',
    'heat score slider',
    'heat score range',
    'popular',
  ],
  'propensity-scores-filter': [
    'propensity score',
    'propensity scores',
    'custom score',
    'propensity model',
    'propensity',
    'models',
    'scoring',
    'weightings',
    'zint',
  ],
  'cross-sell-people-checkbox-filter': [
    'cross sell linked through people',
    'connected through shared directorships',
    'individual share ownership',
    'cross sell',
    'linked through people',
    'shared directorships',
    'individual share ownership',
    'opportunities',
  ],
  'cross-sell-corporate-structure-checkbox-filter': [
    'cross sell linked through corporate structure',
    'connected through corporate structure',
    'corporate structure',
    'cross sell',
    'linked through corporate structure',
    'connected corporate structure',
    'corporate structure',
    'opportunities',
  ],
  'cross-sell-options-filter': [
    'cross sell',
    'cross sell option',
    'cross sell options',
    'cross sell tags',
    'cross sell tag',
  ],
  'salesforce-filter': [
    'salesforce',
    'salesforce objects',
    'synchronisation',
    'sync',
    'salesforce integration',
    'salesforce columns',
    'salesforce custom filter',
    'crm',
  ],
  'semantic-search-filter': [
    'semantic search term',
    'semantic search',
    'word closeness',
    'similarity',
    'similar words',
    'vectors',
  ],
  'likely-supplier-company-tag-filter': [
    'likely supplier companies tagged',
    'likely supplier',
    'supplier',
    'companies tagged',
    'tagged',
  ],
  'one-supplier-only-checkbox-filter': [
    'associate with one supplier',
    'one supplier only',
    'one supplier',
    'supplier',
  ],
  'likely-customer-company-tag-filter': [
    'likely customer companies tagged',
    'likely customer',
    'customer',
    'companies tagged',
    'tagged',
  ],
  'similar-org-filter': [
    'similar org',
    'similar orgs',
    'similar companies',
    'business URL',
    'find similar businesses',
    'competitions',
    'competitors',
    'rivals',
    'business similarity',
    'organisations',
    'similar businesses',
  ],
};

export const collapseCardToParamsMappings = {
  'status-collapse-card': [
    'companyNameContains',
    'selectedCompanyStatuses',
    'selectedCompanyCategories',
    'lowerAge',
    'upperAge',
  ],
  'industry-collapse-card': [
    'selectedSICCodes',
    'excludedSICCodes',
    'homepageSearchTerm',
    'fullWebsiteSearchTerm',
    'websiteKeywords',
  ],
  'geography-collapse-card': [
    'selectedAddressTypeOption',
    'postcodeBeginsWith',
    'postcodeDistrict',
    'ukRegion',
    'postcodeWithinRadius',
    'selectedTownId',
    'mileRadiusOfPostcode',
    'hasOverseasParent',
    'hasOfficesOverseas',
    'selectedOfficeCountries',
    'lowerNumberOfKnownOfficeLocations',
    'upperNumberOfKnownOfficeLocations',
    'selectedGeographicalRevenueCountries',
    'selectedGeographicalRevenueTerritories',
    'lowerGeographicalRevenueSelected',
    'upperGeographicalRevenueSelected',
  ],
  'structure-collapse-card': [
    'corporateStructureIsTopLevelParentCompany',
    'corporateStructureHasParent',
    'corporateStructureDoesNotHaveParent',
    'corporateStructureHasChild',
    'corporateStructureDoesNotHaveChild',
    'lowerNumberOfUBOs',
    'upperNumberOfUBOs',
    'selectedUBOCountries',
    'corporateStructureRelatedCompanies',
  ],
  'website-collapse-card': [
    'hasWebsite',
    'hasPhoneNumber',
    'hasEmailAddress',
    'selectedWebTechnologies',
    'webTrafficLow',
    'webTrafficHigh',
    'homepageSearchTerm',
    'fullWebsiteSearchTerm',
    'websiteKeywords',
  ],
  'detailed-financials-collapse-card': [
    'ignoreRevenueEstimates',
    'lowerRevenue',
    'upperRevenue',
    'accountsAreOverdue',
    'lowerOperating Expenses',
    'upperOperating Expenses',
    'lowerOther Operating Income',
    'upperOther Operating Income',
    'lowerCost Of Sales',
    'upperCost Of Sales',
    'lowerGross Profit',
    'upperGross Profit',
    'lowerInterest Payable',
    'upperInterest Payable',
    'lowerOperating Profit',
    'upperOperating Profit',
    'lowerEbitda',
    'upperEbitda',
    'lowerProfit Before Tax',
    'upperProfit Before Tax',
    'lowerCorporation Tax',
    'upperCorporation Tax',
    'lowerTax Credit',
    'upperTax Credit',
    'lowerProfit After Tax',
    'upperProfit After Tax',
    'lowerFixed Assets',
    'upperFixed Assets',
    'lowerCurrent Assets',
    'upperCurrent Assets',
    'lowerCash At Bank',
    'upperCash At Bank',
    'lowerRaw Materials And Consumable',
    'upperRaw Materials And Consumable',
    'lowerTotal Assets',
    'upperTotal Assets',
    'lowerDebtors Due Within One Year',
    'upperDebtors Due Within One Year',
    'lowerDebtors Due After One Year',
    'upperDebtors Due After One Year',
    'lowerTrade Debtors',
    'upperTrade Debtors',
    'lowerOther Debtors',
    'upperOther Debtors',
    'lowerTotal Debtors',
    'upperTotal Debtors',
    'lowerCreditors Due Within One Year',
    'upperCreditors Due Within One Year',
    'lowerCreditors Due After One Year',
    'upperCreditors Due After One Year',
    'lowerTotal Creditors',
    'upperTotal Creditors',
    'lowerDividends',
    'upperDividends',
    'lowerTotal Assets Minus Current Liabilities',
    'upperTotal Assets Minus Current Liabilities',
    'lowerCapital And Reserves',
    'upperCapital And Reserves',
    'lowerRetained Earnings',
    'upperRetained Earnings',
    'lowerShareholders Funds',
    'upperShareholders Funds',
    'lowerShare Premium',
    'upperShare Premium',
    'lowerGross Margin Percentage',
    'upperGross Margin Percentage',
    'lowerFx Gain Or Loss',
    'upperFx Gain Or Loss',
    'lowerWages And Salaries',
    'upperWages And Salaries',
    'lowerRevenue Growth Rate',
    'upperRevenue Growth Rate',
    'lowerRevenue Absolute Growth',
    'upperRevenue Absolute Growth',
    'lowerOther Operating Income Growth Rate',
    'upperOther Operating Income Growth Rate',
    'lowerOther Operating Income Absolute Growth',
    'upperOther Operating Income Absolute Growth',
    'lowerCost Of Sales Growth Rate',
    'upperCost Of Sales Growth Rate',
    'lowerCost Of Sales Absolute Growth',
    'upperCost Of Sales Absolute Growth',
    'lowerGross Profit Growth Rate',
    'upperGross Profit Growth Rate',
    'lowerGross Profit Absolute Growth',
    'upperGross Profit Absolute Growth',
    'lowerOperating Expenses Growth Rate',
    'upperOperating Expenses Growth Rate',
    'lowerOperating Expenses Absolute Growth',
    'upperOperating Expenses Absolute Growth',
    'lowerInterest Payable Growth Rate',
    'upperInterest Payable Growth Rate',
    'lowerInterest Payable Absolute Growth',
    'upperInterest Payable Absolute Growth',
    'lowerOperating Profit Growth Rate',
    'upperOperating Profit Growth Rate',
    'lowerOperating Profit Absolute Growth',
    'upperOperating Profit Absolute Growth',
    'lowerEbitda Growth Rate',
    'upperEbitda Growth Rate',
    'lowerEbitda Absolute Growth',
    'upperEbitda Absolute Growth',
    'lowerProfit Before Tax Growth Rate',
    'upperProfit Before Tax Growth Rate',
    'lowerProfit Before Tax Absolute Growth',
    'upperProfit Before Tax Absolute Growth',
    'lowerCorporation Tax Growth Rate',
    'upperCorporation Tax Growth Rate',
    'lowerCorporation Tax Absolute Growth',
    'upperCorporation Tax Absolute Growth',
    'lowerTax Credit Growth Rate',
    'upperTax Credit Growth Rate',
    'lowerTax Credit Absolute Growth',
    'upperTax Credit Absolute Growth',
    'lowerProfit After Tax Growth Rate',
    'upperProfit After Tax Growth Rate',
    'lowerProfit After Tax Absolute Growth',
    'upperProfit After Tax Absolute Growth',
    'lowerFixed Assets Growth Rate',
    'upperFixed Assets Growth Rate',
    'lowerFixed Assets Absolute Growth',
    'upperFixed Assets Absolute Growth',
    'lowerCurrent Assets Growth Rate',
    'upperCurrent Assets Growth Rate',
    'lowerCurrent Assets Absolute Growth',
    'upperCurrent Assets Absolute Growth',
    'lowerCash At Bank Growth Rate',
    'upperCash At Bank Growth Rate',
    'lowerCash At Bank Absolute Growth',
    'upperCash At Bank Absolute Growth',
    'lowerRaw Materials And Consumable Growth Rate',
    'upperRaw Materials And Consumable Growth Rate',
    'lowerRaw Materials And Consumable Absolute Growth',
    'upperRaw Materials And Consumable Absolute Growth',
    'lowerTotal Assets Growth Rate',
    'upperTotal Assets Growth Rate',
    'lowerTotal Assets Absolute Growth',
    'upperTotal Assets Absolute Growth',
    'lowerDebtors Due Within One Year Growth Rate',
    'upperDebtors Due Within One Year Growth Rate',
    'lowerDebtors Due Within One Year Absolute Growth',
    'upperDebtors Due Within One Year Absolute Growth',
    'lowerDebtors Due After One Year Growth Rate',
    'upperDebtors Due After One Year Growth Rate',
    'lowerDebtors Due After One Year Absolute Growth',
    'upperDebtors Due After One Year Absolute Growth',
    'lowerTrade Debtors Growth Rate',
    'upperTrade Debtors Growth Rate',
    'lowerTrade Debtors Absolute Growth',
    'upperTrade Debtors Absolute Growth',
    'lowerOther Debtors Growth Rate',
    'upperOther Debtors Growth Rate',
    'lowerOther Debtors Absolute Growth',
    'upperOther Debtors Absolute Growth',
    'lowerTotal Debtors Growth Rate',
    'upperTotal Debtors Growth Rate',
    'lowerTotal Debtors Absolute Growth',
    'upperTotal Debtors Absolute Growth',
    'lowerCreditors Due Within One Year Growth Rate',
    'upperCreditors Due Within One Year Growth Rate',
    'lowerCreditors Due Within One Year Absolute Growth',
    'upperCreditors Due Within One Year Absolute Growth',
    'lowerCreditors Due After One Year Growth Rate',
    'upperCreditors Due After One Year Growth Rate',
    'lowerCreditors Due After One Year Absolute Growth',
    'upperCreditors Due After One Year Absolute Growth',
    'lowerTotal Creditors Growth Rate',
    'upperTotal Creditors Growth Rate',
    'lowerTotal Creditors Absolute Growth',
    'upperTotal Creditors Absolute Growth',
    'lowerDividends Growth Rate',
    'upperDividends Growth Rate',
    'lowerDividends Absolute Growth',
    'upperDividends Absolute Growth',
    'lowerTotal Assets Minus Current Liabilities Growth Rate',
    'upperTotal Assets Minus Current Liabilities Growth Rate',
    'lowerTotal Assets Minus Current Liabilities Absolute Growth',
    'upperTotal Assets Minus Current Liabilities Absolute Growth',
    'lowerCapital And Reserves Growth Rate',
    'upperCapital And Reserves Growth Rate',
    'lowerCapital And Reserves Absolute Growth',
    'upperCapital And Reserves Absolute Growth',
    'lowerRetained Earnings Growth Rate',
    'upperRetained Earnings Growth Rate',
    'lowerRetained Earnings Absolute Growth',
    'upperRetained Earnings Absolute Growth',
    'lowerShareholders Funds Growth Rate',
    'upperShareholders Funds Growth Rate',
    'lowerShareholders Funds Absolute Growth',
    'upperShareholders Funds Absolute Growth',
    'lowerShare Premium Growth Rate',
    'upperShare Premium Growth Rate',
    'lowerShare Premium Absolute Growth',
    'upperShare Premium Absolute Growth',
    'lowerNumber Of Employees Growth Rate',
    'upperNumber Of Employees Growth Rate',
    'lowerNumber Of Employees Absolute Growth',
    'upperNumber Of Employees Absolute Growth',
    'lowerFx Gain Or Loss Growth Rate',
    'upperFx Gain Or Loss Growth Rate',
    'lowerFx Gain Or Loss Absolute Growth',
    'upperFx Gain Or Loss Absolute Growth',
    'lowerWages And Salaries Growth Rate',
    'upperWages And Salaries Growth Rate',
    'lowerWages And Salaries Absolute Growth',
    'upperWages And Salaries Absolute Growth',
  ],
  'other-financials-collapse-card': [
    'accountantNameContains',
    'bankerNameContains',
    'hasLEINumber',
    'selectedLEIStatus',
    'hasRaisedInvestment',
    'hasShareFilingEvent',
    'shareFilingEventWithinDays',
    'lowerShareFilingCapitalValue',
    'upperShareFilingCapitalValue',
    'lowerInvestmentAmount',
    'upperInvestmentAmount',
    'lowerAccountsDueSelected',
    'upperAccountsDueSelected',
    'lowerLatestAccountsSubmittedSelected',
    'upperLatestAccountsSubmittedSelected',
    'accountsAreOverdue',
    'issuedAGrant',
    'lowerGrantAmount',
    'upperGrantAmount',
    'grantLowerMonthsUntilProjectEnd',
    'grantUpperMonthsUntilProjectEnd',
    'chargeRegistered',
    'outstandingChargeRegistered',
  ],
  'accounts-search-collapse-card': [
    'accountsSearchTerm',
    'accountsSearchWithinDays',
  ],
  'assets-collapse-card': [
    'lowerLandAndBuildingsTangibleAssets',
    'lowerLandAndBuildingsCostTangibleAssets',
    'lowerLandAndBuildingsAdditionsTangibleAssets',
    'lowerLandAndBuildingsDisposalsTangibleAssets',
    'lowerLandAndBuildingsDepreciationTangibleAssets',
    'lowerPlantAndMachineryTangibleAssets',
    'lowerPlantAndMachineryCostTangibleAssets',
    'lowerPlantAndMachineryAdditionsTangibleAssets',
    'lowerPlantAndMachineryDisposalsTangibleAssets',
    'lowerPlantAndMachineryDepreciationTangibleAssets',
    'lowerFurnitureAndFittingsTangibleAssets',
    'lowerFurnitureAndFittingsCostTangibleAssets',
    'lowerFurnitureAndFittingsAdditionsTangibleAssets',
    'lowerFurnitureAndFittingsDisposalsTangibleAssets',
    'lowerFurnitureAndFittingsDepreciationTangibleAssets',
    'lowerVehiclesTangibleAssets',
    'lowerVehiclesCostTangibleAssets',
    'lowerVehiclesAdditionsTangibleAssets',
    'lowerVehiclesDisposalsTangibleAssets',
    'lowerVehiclesDepreciationTangibleAssets',
    'lowerComputerEquipmentTangibleAssets',
    'lowerComputerEquipmentCostTangibleAssets',
    'lowerComputerEquipmentAdditionsTangibleAssets',
    'lowerComputerEquipmentDisposalsTangibleAssets',
    'lowerComputerEquipmentDepreciationTangibleAssets',
    'lowerLeaseholdAssetsTangibleAssets',
    'lowerLeaseholdAssetsCostTangibleAssets',
    'lowerLeaseholdAssetsAdditionsTangibleAssets',
    'lowerLeaseholdAssetsDisposalsTangibleAssets',
    'lowerLeaseholdAssetsDepreciationTangibleAssets',
    'lowerAssetsUnderConstructionTangibleAssets',
    'lowerAssetsUnderConstructionCostTangibleAssets',
    'lowerAssetsUnderConstructionAdditionsTangibleAssets',
    'lowerAssetsUnderConstructionDisposalsTangibleAssets',
    'lowerAssetsUnderConstructionDepreciationTangibleAssets',
    'lowerOtherTangibleAssets',
    'lowerOtherCostTangibleAssets',
    'lowerOtherAdditionsTangibleAssets',
    'lowerOtherDisposalsTangibleAssets',
    'lowerOtherDepreciationTangibleAssets',
    'lowerGoodwillIntangibleAssets',
    'lowerGoodwillCostIntangibleAssets',
    'lowerGoodwillAdditionsIntangibleAssets',
    'lowerGoodwillAmortisationIntangibleAssets',
    'lowerComputerSoftwareIntangibleAssets',
    'lowerComputerSoftwareCostIntangibleAssets',
    'lowerComputerSoftwareAdditionsIntangibleAssets',
    'lowerComputerSoftwareAmortisationIntangibleAssets',
    'lowerPatentsTrademarksLicencesIntangibleAssets',
    'lowerPatentsTrademarksLicencesCostIntangibleAssets',
    'lowerPatentsTrademarksLicencesAdditionsIntangibleAssets',
    'lowerPatentsTrademarksLicencesAmortisationIntangibleAssets',
    'lowerOtherIntangibleAssets',
    'lowerOtherIntangibleAssetsCost',
    'lowerOtherIntangibleAssetsAdditions',
    'lowerOtherIntangibleAssetsAmortisation',
    'upperLandAndBuildingsTangibleAssets',
    'upperLandAndBuildingsCostTangibleAssets',
    'upperLandAndBuildingsAdditionsTangibleAssets',
    'upperLandAndBuildingsDisposalsTangibleAssets',
    'upperLandAndBuildingsDepreciationTangibleAssets',
    'upperPlantAndMachineryTangibleAssets',
    'upperPlantAndMachineryCostTangibleAssets',
    'upperPlantAndMachineryAdditionsTangibleAssets',
    'upperPlantAndMachineryDisposalsTangibleAssets',
    'upperPlantAndMachineryDepreciationTangibleAssets',
    'upperFurnitureAndFittingsTangibleAssets',
    'upperFurnitureAndFittingsCostTangibleAssets',
    'upperFurnitureAndFittingsAdditionsTangibleAssets',
    'upperFurnitureAndFittingsDisposalsTangibleAssets',
    'upperFurnitureAndFittingsDepreciationTangibleAssets',
    'upperVehiclesTangibleAssets',
    'upperVehiclesCostTangibleAssets',
    'upperVehiclesAdditionsTangibleAssets',
    'upperVehiclesDisposalsTangibleAssets',
    'upperVehiclesDepreciationTangibleAssets',
    'upperComputerEquipmentTangibleAssets',
    'upperComputerEquipmentCostTangibleAssets',
    'upperComputerEquipmentAdditionsTangibleAssets',
    'upperComputerEquipmentDisposalsTangibleAssets',
    'upperComputerEquipmentDepreciationTangibleAssets',
    'upperLeaseholdAssetsTangibleAssets',
    'upperLeaseholdAssetsCostTangibleAssets',
    'upperLeaseholdAssetsAdditionsTangibleAssets',
    'upperLeaseholdAssetsDisposalsTangibleAssets',
    'upperLeaseholdAssetsDepreciationTangibleAssets',
    'upperAssetsUnderConstructionTangibleAssets',
    'upperAssetsUnderConstructionCostTangibleAssets',
    'upperAssetsUnderConstructionAdditionsTangibleAssets',
    'upperAssetsUnderConstructionDisposalsTangibleAssets',
    'upperAssetsUnderConstructionDepreciationTangibleAssets',
    'upperOtherTangibleAssets',
    'upperOtherCostTangibleAssets',
    'upperOtherAdditionsTangibleAssets',
    'upperOtherDisposalsTangibleAssets',
    'upperOtherDepreciationTangibleAssets',
    'upperGoodwillIntangibleAssets',
    'upperGoodwillCostIntangibleAssets',
    'upperGoodwillAdditionsIntangibleAssets',
    'upperGoodwillAmortisationIntangibleAssets',
    'upperComputerSoftwareIntangibleAssets',
    'upperComputerSoftwareCostIntangibleAssets',
    'upperComputerSoftwareAdditionsIntangibleAssets',
    'upperComputerSoftwareAmortisationIntangibleAssets',
    'upperPatentsTrademarksLicencesIntangibleAssets',
    'upperPatentsTrademarksLicencesCostIntangibleAssets',
    'upperPatentsTrademarksLicencesAdditionsIntangibleAssets',
    'upperPatentsTrademarksLicencesAmortisationIntangibleAssets',
    'upperOtherIntangibleAssets',
    'upperOtherIntangibleAssetsCost',
    'upperOtherIntangibleAssetsAdditions',
    'upperOtherIntangibleAssetsAmortisation',
  ],
  'company-charges-collapse-card': [
    'ignoreSatisfiedCharges',
    'chargeHolderNameContains',
    'chargeCreatedMonthsAgoLower',
    'chargeCreatedMonthsAgoUpper',
  ],
  'energy-collapse-card': [
    'isLowEnergy',
    'lowerTotalEnergyKwhSelected',
    'upperTotalEnergyKwhSelected',
    'lowerGasKwhSelected',
    'upperGasKwhSelected',
    'lowerElectricKwhSelected',
    'upperElectricKwhSelected',
    'lowerScope1KwhSelected',
    'upperScope1KwhSelected',
    'lowerScope2KwhSelected',
    'upperScope2KwhSelected',
    'lowerScope3KwhSelected',
    'upperScope3KwhSelected',
  ],
  'emissions-collapse-card': [
    'lowerTotalEmissionsKgCo2eSelected',
    'upperTotalEmissionsKgCo2eSelected',
    'lowerScope1KgCo2eSelected',
    'upperScope1KgCo2eSelected',
    'lowerScope2KgCo2eSelected',
    'upperScope2KgCo2eSelected',
    'lowerScope3KgCo2eSelected',
    'upperScope3KgCo2eSelected',
    'lowerKgCo2ePerMillionRevenueSelected',
    'upperKgCo2ePerMillionRevenueSelected',
    'lowerKgCo2ePerEmployeeSelected',
    'upperKgCo2ePerEmployeeSelected',
  ],
  'importsexports-collapse-card': [
    'selectedImportExportCategories',
    'selectedImportingOrExportingOption',
    'importExportTimeScale',
    'importExportFrequencyImportingOrExporting',
    'importExportFrequency',
    'importExportFrequencyMonthCount',
  ],
  'employees-collapse-card': [
    'employeeLowerCount',
    'employeeUpperCount',
    'excludeEstimatedHeadcountFigures',
    'departmentHeadCountLowerCount',
    'departmentHeadCountUpperCount',
    'lowerHeadCountGrowthPercentage',
    'upperHeadCountGrowthPercentage',
    'selectedFunctionalDepartments',
    'lowerAverageDirectorAgeRange',
    'upperAverageDirectorAgeRange',
    'lowerNumberOfDirectors',
    'upperNumberOfDirectors',
    'lowerNumberOfPSCs',
    'upperNumberOfPSCs',
    'PSCsRealPersonsOnly',
    'recentDirectorshipTitleContains',
    'lowerRecentDirectorshipMonth',
    'upperRecentDirectorshipMonth',
    'nationalitiesOfDirectorsSelected',
    'nationalitiesOfPSCsSelected',
    'directorJobTitleContains',
    'lowerWages',
    'upperWages',
  ],
  'hiring-collapse-card': [
    'isHiring',
    'hiringJobTitleContains',
    'hiringDescriptionContains',
    'selectedHiringDayLimit',
    'lowerNumberOfRolesHiringFor',
    'upperNumberOfRolesHiringFor',
    'selectedNumberOfRolesHiringForDayLimit',
    'includeRecruiters',
  ],
  'timing-collapse-card': [
    'lowerDaysUntilFinancialYearEnd',
    'upperDaysUntilFinancialYearEnd',
    'selectedFinancialYearEnds',
    'lowerDaysUntilAnniversary',
    'upperDaysUntilAnniversary',
  ],
  'events-collapse-card': [
    'postcodeEventsWithinRadius',
    'mileRadiusOfEventsSelected',
    'eventsWithinDaysLower',
    'eventsWithinDaysUpper',
  ],
  'news-collapse-card': ['newsWithinDays', 'newsContaining', 'newsSource'],
  'marketplaces-collapse-card': ['amazonSeller', 'listedOnGCloud'],
  'government-contracts-collapse-card': [
    'hasContract',
    'lowerContractValueSelected',
    'upperContractValueSelected',
    'contractAwardedWithinMonths',
    'lowerContractStartMonthsSelected',
    'upperContractStartMonthsSelected',
    'lowerContractEndMonthsSelected',
    'upperContractEndMonthsSelected',
    'contractTitleContains',
    'contractDescriptionContains',
  ],
  'trademarks-collapse-card': [
    'trademarkClassIdsSelected',
    'trademarkDescriptionContains',
    'trademarkNameContains',
    'selectedTrademarkStatuses',
    'lowerNumberOfActiveTrademarks',
    'upperNumberOfActiveTrademarks',
  ],
  'patents-collapse-card': [
    'hasActivePatent',
    'hasAnyPatentRecord',
    'patentFiledWithinDays',
    'lowerPatentAwardedDays',
    'upperPatentAwardedDays',
  ],
  'fleet-collapse-card': [
    'lowerVehicleFleetSize',
    'upperVehicleFleetSize',
    'lowerVehicleFleetSizeAuthorised',
    'upperVehicleFleetSizeAuthorised',
    'lowerTrailerFleetSize',
    'upperTrailerFleetSize',
    'lowerTrailerFleetSizeAuthorised',
    'upperTrailerFleetSizeAuthorised',
  ],
  'aira-collapse-card': [
    //TODO:
    'lowerEstimatedCreditWorthinessScore',
    'upperEstimatedCreditWorthinessScore',
    'nameOfLogisticsProviderContains',
    'returnsPolicyContains',
  ],
  'custom-columns-collapse-card': ['selectedCustomColumns'],
  'tags-collapse-card': [
    'excludeCompaniesTaggedByTeam',
    'tagPositiveIdsSelected',
    'tagNegativeIdsSelected',
  ],
  'alerts-collapse-card': [
    'lowerAlertsDayRange',
    'upperAlertsDayRange',
    'alertIdsSelected',
  ],
  'heatscore-collapse-card': ['lowerHeatScore', 'upperHeatScore'],
  'propensity-scores-collapse-card': ['selectedCustomColumns'],
  'cross-sell-scores-collapse-card': [
    'crossSellTagIdsSelected',
    'crossSellLinkedThroughPeople',
    'crossSellLinkedThroughCorporateStructure',
  ],
  'salesforce-collapse-card': [
    'selectedSalesforceObjects',
    'selectedCustomColumns',
  ],
  'semantic-collapse-card': [
    'summarySemanticSearchTerm',
    'summarySemanticSearchExclude',
  ],
  'supplier-collapse-card': [
    'supplierTagSelected',
    'associateWithOneSupplierOnly',
    'customerTagSelected',
  ],
  'similar-org-collapse-card': ['similarCompanyUrl'],
};
