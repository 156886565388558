<div class="page-grid-container ">
    
    <!-- filters column with tabs -->
    <div class="sidebar-grid" [ngClass]="{'sidebar-expanded': !isSidebarCollapsed, 'sidebar-collapsed': isSidebarCollapsed, 'sidebar-mobile': isScreenTooSmall()}">                
        <div class="collapse-btn-container" [style.left]="isSidebarCollapsed ? '4.8rem' : '25.8rem'" (click)="expandOrCollapseSidebar()">
            <div id="collapse-button" class="d-flex align-items-center no-border-left collapse-button">                    
                <i class="fal fa-caret-left" *ngIf="!isSidebarCollapsed"></i>
                <i class="fal fa-caret-right" *ngIf="isSidebarCollapsed"></i>
            </div>
        </div>

        <button *ngIf="isScreenTooSmall()" class="mobile-btn rounded-btn-base rounded-btn-light btn-p-sm mb-2" [style.top]="mobileSidebarBtnFixedTop ? 0 : '4rem'"               
            (click)="toggleSidebarHeight()">
            {{mobileSidebarRolledUp ? 'Show ' : 'Hide '}}Filters
        </button>

            <ul class="nav nav-tabs mb-2" role="tablist" id="nav-items-holder">
                <li class="nav-list-item" [ngClass]="{'mb-1': isSidebarCollapsed}">
                    <span class="rounded-btn-base btn-p-xs full-width" 
                    [ngClass]="{'active': true, 'rounded-left-btn tab-btn': !isSidebarCollapsed}" 
                    role="tab" data-toggle="tab"
                        data-target="#filters-tab-content">
                        Filters
                    </span>                    
                </li>
                <li class="nav-list-item">
                    <span class="rounded-btn-base btn-p-xs prevent-multi-line full-width" 
                    [ngClass]="{'rounded-right-btn tab-btn': !isSidebarCollapsed, 'disabled': !hasAISearchAccess}"
                    [matTooltip]="hasAISearchAccess ? '' : 'Premium feature'"
                    role="tab" data-toggle="tab" [attr.data-target]="hasAISearchAccess ? '#ai-chat-agent': null"
                        (click)="openAISearchTab()">
                        {{ isSidebarCollapsed ? 'AI' : 'AI Search' }}
                    </span>
                </li>
            </ul>
        
        
            <div class="tab-content">                
                <div class="row tab-pane active mr-0" id="filters-tab-content" role="tabpanel">
                    <div class="sticky-main-search bg-light">
                        <div class="main-search-btn-container" [ngClass]="{'flex-column': isSidebarCollapsed}">
                        <div
                            [ngClass]="availableTerritories && availableTerritories.length > 0 && !this.isSidebarCollapsed ? 'col-md-9 pr-md-0': 'col pr-0'">
                            <div class="tooltip-wrapper" [attr.data-toggle]="isReadOnlyUser ? 'tooltip': null"
                                [attr.data-placement]="isReadOnlyUser ? 'top': null"
                                [matTooltip]="isReadOnlyUser ? 'Your account is read only': null">
                                <button type="button"
                                    class="rounded-btn-base rounded-btn-light btn-p-sm control-buttons mb-0"
                                    [ngClass]="{'text-truncate': !isSidebarCollapsed}"
                                    (click)="searchCompaniesAfterFilters(true)"
                                    [attr.disabled]="isLoading.loadingUI ||isLoading.loadingCompanies || isReadOnlyUser ? 'disabled' : null">
                                    <span *ngIf="!isSidebarCollapsed">SEARCH
                                        {{selectedTerritory?.name}}</span>
                                    <span *ngIf="isSidebarCollapsed"><i class="fal fa-search fa-xs"></i></span>
                                </button>
                            </div>
                        </div>
                        <div [ngClass]="{'col-md-3 pl-md-0 pr-1': !isSidebarCollapsed, 'col-md-12 pr-0': isSidebarCollapsed}"
                             *ngIf="availableTerritories && availableTerritories.length > 0">
                            <div class="dropdown" matTooltipPosition="above"
                                [matTooltip]="isReadOnlyUser ? 'Your account is read only': 'Search within your territories'"
                                id="search-territories-tooltip-element">
                                <button class="rounded-btn-base rounded-btn-light btn-p-sm control-buttons mb-0"
                                    [ngClass]="{'': !isSidebarCollapsed}" type="button" data-toggle="dropdown"
                                    [attr.disabled]="isLoading.loadingCompanies || isReadOnlyUser ? 'disabled': null">
                                    <span>
                                        <i class="fa fa-globe-africa"></i>
                                        <i *ngIf="!isSidebarCollapsed" class="fal fa-caret-down"></i>
                                    </span>
                                </button>
                                <div class="dropdown-menu" style="right:0 !important;">
                                    <span role="button" class="dropdown-item dropdown-wrap"
                                        (click)="searchCompaniesWithTerritoryFilter(availableTerritory)"
                                        *ngFor="let availableTerritory of availableTerritories">{{availableTerritory.name}}</span>
                                    <span role="button" class="dropdown-item" matTooltip="Default search without territories"
                                        *ngIf="selectedTerritory?.name" (click)="searchCompaniesWithTerritoryFilter(null)">Reset
                                        Territory</span>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-12 pr-0">
                            
                            <button *ngIf="isSidebarCollapsed" class="full-width rounded-btn-base rounded-btn-light" (click)="expandSideBarIfRequired()">
                                <i class="far fa-filter-list"></i>
                            </button>
                            
                            <div *ngIf="!isSidebarCollapsed" class="filter-search-container">
                                <input #filterSearchInput class="filter-search" type="text" minlength="2" maxlength="32" placeholder="Search for a filter" (keyup.enter)="showHideFilters($event.target.value)">
                                <i class="btn fal fa-undo" role="button" matTooltip="reset" matTooltipPosition="before" (click)="resetSearchForFilters(filterSearchInput)"></i>
                                <span *ngIf="filterSearchErrorMsg">
                                    {{filterSearchErrorMsg}}
                                </span>                            
                            </div>
                        </div>
                    </div>
                    <div class="col pr-0" id="search-area" [ngClass]="{'loading-ui': isLoading.loadingUI}" ngForm
                        #searchForm="ngForm" (click)="expandSideBarIfRequired()">
                        <details open (toggle)="isFilterSectionDetailsExpanded($event, 'firmographics')" id="searchable-show-hide-firmographics">
                            <summary>
                                <span *ngIf="!isSidebarCollapsed;" class="top-level" [ngClass]="{'border-bottom': expandedFilterSections['firmographics']}">FIRMOGRAPHICS</span>
                                <i *ngIf="isSidebarCollapsed" class="far fa-arrows-spin ml-1"></i>
                            </summary>
                        <section>
                            
                        <div class="card" id="status-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('status-collapse-card')}">
                            <div class="card-header" id="status-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#status-search-collapse">
                                        <i class="fal fa-building" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Status</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="status-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    <label  class="full-width mb-3" id="company-name-filter">Company name contains:
                                        <input type="text" class="form-control" name="companyNameContains"
                                            [(ngModel)]="companyNameContains"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                    </label>
                                    <div class="form-group select-table" id="company-status-filter">
                                        <label for="options">Company Status
                                            Options:</label>
                                        <div *ngFor="let companyStatusOption of companyStatusOptions">
                                            <div *ngIf="companyStatusOption.level == 0 || companyStatusOption.shouldShow">
                                                <label>
                                                    <input type="checkbox"
                                                        [attr.name]="'companyStatusOption' + companyStatusOption.name"
                                                        [ngModelOptions]="{standalone: true}"
                                                        value="{{companyStatusOption.name}}"
                                                        [(ngModel)]="companyStatusOption.checked" />
                                                </label>
                                                <span
                                                    (click)="expandOrCollapseItems(companyStatusOption.children, 'companyStatusOptions')"
                                                    [ngClass]="{'expandable-span': companyStatusOption.level==0}"><span
                                                        *ngIf="companyStatusOption.level==1">&nbsp;&nbsp;<i
                                                            class="fal fa-caret-down"></i>
                                                    </span>
                                                    {{companyStatusOption.status_clean}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group select-table" id="company-category-filter">
                                        <label for="options">Company Category
                                            Options:</label>
                                        <div *ngFor="let companyCategoryOption of companyCategoryOptions">
                                            <div *ngIf="companyCategoryOption.level == 0 || companyCategoryOption.shouldShow">
                                                <label>
                                                    <input type="checkbox"
                                                        [attr.name]="'companyCategoryOption' + companyCategoryOption.name"
                                                        [ngModelOptions]="{standalone: true}"
                                                        value="{{companyCategoryOption.name}}"
                                                        [(ngModel)]="companyCategoryOption.checked" />
                                                </label>
                                                <span
                                                    (click)="expandOrCollapseItems(companyCategoryOption.children, 'companyCategoryOptions')"
                                                    [ngClass]="{'expandable-span': companyCategoryOption.level==0}"><span
                                                        *ngIf="companyCategoryOption.level==1">&nbsp;&nbsp;<i
                                                            class="fal fa-caret-down"></i>
                                                    </span>
                                                    {{companyCategoryOption.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <zint-ngx-slider id="company-age-range-filter" *ngIf="companyAgeRange" #companyAgeSliderElement
                                    formName="company age"
                                        [min]="companyAgeRange.at(0)" [max]="companyAgeRange.at(-1)" postfix="years"
                                        maxPostfix="+" (sliderChange)="updateCompanyAgeRange($event)"></zint-ngx-slider>
        
                                </div>
                            </div>
                        </div>                                                                    

                        <div class="card overflow-xy-visible" id="industry-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('industry-collapse-card')}">
                            <div class="card-header" id="industry-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#industry-search-collapse">
                                        <i class="fal fa-industry-alt" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Industry</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="industry-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    <div class="" id="sics-overflowing-table-filter" appFixedOnHover>
                                        <span>Company SIC Codes:</span>
                                    
                                        <div class="form-group overflowing-table select-table">
                                            
                                            <div class="input-group xsmall-margin-top"
                                            style="padding-right: 10px; padding-bottom: 10px">
                                            
                                                    <div class="input-group-prepend">
                                                        <button class="btn basic-button-base button-dark" type="button"
                                                            (click)="resetSicSearch()">
                                                            <i class="fal fa-redo-alt"></i>
                                                            Reset
                                                        </button>
                                                    </div>
                                                    <input type="text" class="form-control" name="sicCodeTextSearch"
                                                        [(ngModel)]="sicCodeTextSearch" (ngModelChange)="searchSicCodes()"
                                                        placeholder="Search...">
                                                </div>
                                                <div *ngFor="let sicCodeChoice of companySICCodeOptions">
                                                    <div *ngIf="(sicCodeChoice.type == 'section' && !isSearchingSicCodes) || sicCodeChoice.shouldShow === true"
                                                        class="cut-off">
                
                                                        <span class="expandable-label" matTooltip="Include this SIC code">
                                                            <i [attr.name]="'sicCodeChoiceSelect' + sicCodeChoice.identifier"
                                                                class="fa-square-check mr-1" style="color: var(--zint-vivid-blue)"
                                                                [ngClass]="{'fa-regular': sicCodeChoice.checked, 'fa-thin': !sicCodeChoice.checked}"
                                                                (click)="sicCodeChoice.checked = !sicCodeChoice.checked">
                                                            </i>
                                                        </span>
                                                        <span class="expandable-label" matTooltip="Exclude this SIC code">
                                                            <i [attr.name]="'sicCodeChoiceExclude' + sicCodeChoice.identifier"
                                                                class="fa-square-xmark"
                                                                [ngClass]="{'fa-regular': sicCodeChoice.excluded, 'fa-thin': !sicCodeChoice.excluded}"
                                                                (click)="sicCodeChoice.excluded = !sicCodeChoice.excluded">
                                                            </i>
                                                        </span>
                
                                                        <span (click)="expandOrCollapseSICCodes(sicCodeChoice.id)"
                                                            class="expandable-span"><ng-container
                                                                *ngFor="let dummy of ' '.repeat(sicCodeChoice.level).split(' ')">&nbsp;&nbsp;</ng-container>
                                                            <i class="fal fa-caret-down" *ngIf="sicCodeChoice.level > 0"></i>
                                                            {{sicCodeChoice.identifier}}
                                                            {{sicCodeChoice.description}}</span>
                                                        <hr class="select-break-hr">
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    
                                    <div class="div-below-sics-table">
                                    
                                        <label id="homepage-text-filter" class="full-width">
                                        Homepage Website Text Search:
                                        <p class="mb-1" style="font-size: 10px">Use NOT for negative
                                            search, AND for multiple phrases, OR for
                                            different options, and inverted commas (") for
                                            exact phrases. Each term taken as OR by
                                            default.</p>
                                        <input type="text" class="form-control" name="homepageSearchTerm"
                                            [(ngModel)]="homepageSearchTerm"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                        </label>
            
                                        <div id="deep-crawl-search-filter" [ngClass]="{'hide-deep-crawl': !hasDeepCrawlAccess}">
                                            Zint Deep Crawl
                                            Website Text Search:
                                            <p style="font-size: 10px" *ngIf="hasDeepCrawlAccess">Use NOT for
                                                negative search, AND
                                                for multiple phrases, OR for different
                                                options, and inverted commas (") for exact
                                                phrases. Each term taken as OR by
                                                default.</p>
                                            <p style="font-size: 10px" *ngIf="!hasDeepCrawlAccess"><i class="fal fa-ban"></i> Zint
                                                Deep Crawl allows you to search company
                                                websites beyond just the homepage. You do
                                                not have access to this on your plan. Please
                                                <a href="mailto:sales@zint.io?Subject=Zint%20Deep%20Crawl%20Enquiry">contact
                                                    us</a>
                                                to upgrade.
                                            </p>
                                            <input type="text" class="form-control"
                                                [attr.disabled]="!hasDeepCrawlAccess ? 'disabled': null"
                                                name="fullWebsiteSearchTerm" [(ngModel)]="fullWebsiteSearchTerm"
                                                (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                                        </div>
                                                    
                                        <div class="dropdown" id="keyword-website-search-filter">
                                                Website Keywords
                                                <div *ngIf="websiteKeywords && websiteKeywords.length > 0">
                                                    <span *ngFor="let websiteKeyword of websiteKeywords" class="badge"
                                                        style="background-color: #000a36; color: white; margin-right: 7px; margin-bottom: 8px">
                                                        {{ websiteKeyword }} <i
                                                            class="fal fa-times tag-delete-button cursor-pointer"
                                                            (click)="removeWebsiteKeyword(websiteKeyword)"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control postcode-radius-text-box dropdown-toggle"
                                                    name="websiteKeywordInput" placeholder="e.g. cryptocurrency"
                                                    data-toggle="dropdown" [(ngModel)]="websiteKeywordInput"
                                                    (keyup.enter)="forceAddWebsiteKeyword($event)"
                                                    (ngModelChange)="websiteKeywordChanged()">
                                                <div class="dropdown-menu">
                                                    <div class="d-flex justify-content-center align-items-center">
                                                        <zint-loader size="xSmall"
                                                            *ngIf="isLoading.loadingWebsiteKeywords"></zint-loader>
                                                    </div>
                                                    <span *ngFor="let possibleWebsiteKeyword of possibleWebsiteKeywords"
                                                        class="dropdown-item cursor-pointer"
                                                        (click)="addWebsiteKeyword(possibleWebsiteKeyword)">
                                                        <span>{{ possibleWebsiteKeyword
                                                            }}</span>
                                                    </span>
                                                </div>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="card" id="geography-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('geography-collapse-card')}">
                            <div class="card-header" id="geography-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#geography-search-collapse">
                                        <i class="fal fa-flag" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Geography</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="geography-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">

                                    <details open (toggle)="isFilterSectionDetailsExpanded($event, 'addressType')" id="searchable-show-hide-address-postcode">
                                        <summary [ngClass]="{'border-bottom mb-2': expandedFilterSections['addressType']}">Address Type and Postcode</summary>
                                    <label class="full-width" id="address-type-dropdown-filter">
                                    Address type:
                                        <select [(ngModel)]="selectedAddressTypeOption" class="ml-1 custom-select"
                                            name="selectedAddressTypeOption" style="width: 180px">
                                            <option *ngFor="let addressOption of addressOptions" [ngValue]="addressOption">
                                                {{addressOption}}</option>
                                        </select>
                                    </label>

                                    <div id="postcode-begins-with-text-filter" class="row xsmall-margin-top">
                                        <div class="col"
                                            [ngClass]="{'transparent-text': geographyWithinRadiusOfPostcodeIsEditing || geographyWithinRadiusOfTownIsEditing}">
                                            Postcode begins with:
                                            <input type="text" class="form-control postcode-radius-text-box"
                                                name="postcodeBeginsWith" placeholder="e.g. EC2R 8"
                                                [(ngModel)]="postcodeBeginsWith" (ngModelChange)="postcodeBeginsWithChanged()"
                                                (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"
                                                [attr.disabled]="geographyPostcodeDistrictIsEditing || geographyWithinRadiusOfPostcodeIsEditing || geographyWithinRadiusOfTownIsEditing ? 'disabled': null"
                                                (focus)="geographyPostcodeBeginsWithIsEditing = true; postcodeDistrict = ''; postcodeWithinRadius = ''; townName = ''; selectedTownId = ''; ukRegion = null;"
                                                (focusout)="geographyPostcodeBeginsWithIsEditing = false">
                                        </div>
                                    </div>
        
                                    <div id="postcode-district-text-filter" class="row xsmall-margin-top">
                                        <div class="col"
                                            [ngClass]="{'transparent-text': geographyWithinRadiusOfPostcodeIsEditing || geographyWithinRadiusOfTownIsEditing}">
                                            Postcode district:
                                            <input type="text" class="form-control postcode-radius-text-box"
                                                name="postcodeDistrict" placeholder="e.g. EC2R" [(ngModel)]="postcodeDistrict"
                                                (ngModelChange)="postcodeDistrictChanged()"
                                                (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"
                                                [attr.disabled]="geographyPostcodeBeginsWithIsEditing || geographyWithinRadiusOfPostcodeIsEditing || geographyWithinRadiusOfTownIsEditing ? 'disabled': null"
                                                (focus)="geographyPostcodeDistrictIsEditing = true; postcodeBeginsWith = ''; postcodeWithinRadius = ''; townName = ''; selectedTownId = ''; ukRegion = null;"
                                                (focusout)="geographyPostcodeDistrictIsEditing = false">
                                        </div>
                                    </div>
        
                                    <div id="uk-region-dropdown-filter" class="row xsmall-margin-top">
                                        <div class="col">
                                            UK Region:
                                            <select [(ngModel)]="ukRegion" name="ukRegion" class="custom-select"
                                                (change)="ukRegionChanged()"
                                                [attr.disabled]="geographyPostcodeDistrictIsEditing || geographyPostcodeBeginsWithIsEditing || geographyWithinRadiusOfPostcodeIsEditing || geographyWithinRadiusOfTownIsEditing ? 'disabled': null"
                                                (focus)="postcodeDistrict = ''; postcodeWithinRadius = ''; postcodeBeginsWith = ''; townName = '';">
                                                <option *ngFor="let region of ukRegions" [ngValue]="region">{{region}}</option>
                                            </select>
                                        </div>
                                    </div>
        
                                    
                                    <div class="row mt-2">
                                        <div id="postcode-within-radius-filter" class="col-md-6"
                                                [ngClass]="{'transparent-text': geographyPostcodeBeginsWithIsEditing || geographyWithinRadiusOfTownIsEditing}">
                                                Within radius of postcode:
                                                <input type="text" class="form-control postcode-radius-text-box"
                                                    name="postcodeWithinRadius" placeholder="e.g. SW1A 1AA"
                                                    [(ngModel)]="postcodeWithinRadius"
                                                    (ngModelChange)="postcodeWithinRadiusChanged()"
                                                    (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"
                                                    [attr.disabled]="geographyPostcodeDistrictIsEditing || geographyPostcodeBeginsWithIsEditing || geographyWithinRadiusOfTownIsEditing ? 'disabled': null"
                                                    (focus)="geographyWithinRadiusOfPostcodeIsEditing = true; postcodeDistrict = ''; postcodeBeginsWith = ''; townName = ''; selectedTownId = ''; ukRegion = null;"
                                                    (focusout)="geographyWithinRadiusOfPostcodeIsEditing = false">
                                        </div>
                                        <div id="town-within-radius-filter" class="col-md-6">
                                                <div class="dropdown"
                                                    [ngClass]="{'transparent-text': geographyPostcodeBeginsWithIsEditing || geographyWithinRadiusOfPostcodeIsEditing}">
                                                    Within radius of town name:
                                                    <input type="text"
                                                        class="form-control postcode-radius-text-box dropdown-toggle"
                                                        name="townName" placeholder="e.g. Manchester" data-toggle="dropdown"
                                                        [(ngModel)]="townName" (ngModelChange)="townNameChanged()"
                                                        [attr.disabled]="geographyPostcodeDistrictIsEditing || geographyPostcodeBeginsWithIsEditing || geographyWithinRadiusOfPostcodeIsEditing ? 'disabled': null"
                                                        (focus)="geographyWithinRadiusOfTownIsEditing = true; postcodeDistrict = ''; postcodeWithinRadius = ''; postcodeBeginsWith = ''; ukRegion = null;"
                                                        (focusout)="geographyWithinRadiusOfTownIsEditing = false">
                                                    <i class="fal fa-check-square"
                                                        style="position: absolute; right: 10px; margin-top: -27px"
                                                        *ngIf="selectedTownId"></i>
                                                    <div class="dropdown-menu">
                                                        <span *ngFor="let possibleTownName of possibleTownNames"
                                                            class="dropdown-item cursor-pointer"
                                                            (click)="selectShortlistedTownName(possibleTownName._id, possibleTownName.name)">
                                                            <span>{{
                                                                possibleTownName.name
                                                                }}<span *ngIf="possibleTownName.county">,
                                                                    {{
                                                                    possibleTownName.county
                                                                    }}</span>, {{
                                                                possibleTownName.country
                                                                }}</span>
                                                        </span>
                                                    </div>
                                                </div>
    
                                        </div>
                                        <div id="postcode-radius-range-filter" class="col-md-12 xsmall-margin-top">
                                            <zint-ngx-slider #mileRadiusSliderElement
                                                *ngIf="mileRadiusRegisteredAddressRange" [isRange]="false"
                                                postfix="miles" [min]="mileRadiusRegisteredAddressRange.at(0)"
                                                [max]="mileRadiusRegisteredAddressRange.at(-1)"
                                                (sliderChange)="updatePostcodeMileageRange($event)"></zint-ngx-slider>
                                        </div>
                                    </div>
                                    
                                    

                                    </details>
                                    
                                    <details open (toggle)="isFilterSectionDetailsExpanded($event, 'overseasPresence')" id="searchable-show-hide-overseas-offices">
                                        <summary [ngClass]="{'border-bottom mb-2': expandedFilterSections['overseasPresence']}">Overseas Presence and Offices</summary>
                                    
                                    <label class="full-width" id="has-overseas-parent-checkbox-filter">
                                        <input type="checkbox" name="hasOverseasParent" value="false"
                                            [(ngModel)]="hasOverseasParent" />
                                        Has overseas parent?
                                    </label>        
                                    
                                    <label class="full-width" id="has-overseas-offices-checkbox-filter">
                                        <input type="checkbox" name="hasOfficesOverseas" value="false"
                                            [(ngModel)]="hasOfficesOverseas" />
                                        Has office overseas?
                                    </label>
        
                                    <div class="row mt-3">
                                        <div class="col-md" id="has-offices-in-countries-filter">
                                            Has Offices in:
                                            <div class="form-group overflowing-table select-table">
                                                <div *ngFor="let countriesOfOfficesOption of countriesOfOfficesOptions">
                                                    <label>
                                                        <input type="checkbox"
                                                            [attr.name]="'countriesOfOfficesOption' + countriesOfOfficesOption.code"
                                                            [ngModelOptions]="{standalone: true}"
                                                            value="{{countriesOfOfficesOption.code}}"
                                                            [(ngModel)]="countriesOfOfficesOption.checked" />
                                                        {{ countriesOfOfficesOption.name
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md" id="country-of-origin-filter">
                                            Country of Origin:
                                            <div class="form-group  overflowing-table select-table">
                                                <div *ngFor="let countriesOfOriginOption of countriesOfOriginOptions">
                                                    <label>
                                                        <input type="checkbox"
                                                            [attr.name]="'countriesOfOriginOption' + countriesOfOriginOption.code"
                                                            [ngModelOptions]="{standalone: true}"
                                                            value="{{countriesOfOriginOption.code}}"
                                                            [(ngModel)]="countriesOfOriginOption.checked" />
                                                        {{ countriesOfOriginOption.name
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                            
                                    <zint-ngx-slider id="known-office-locations-filter" #numberOfKnownOfficeLocationsSliderElement
                                        *ngIf="numberOfKnownOfficeLocationsRange" maxPostfix="+"
                                        formName="Number of known office locations"
                                        [min]="numberOfKnownOfficeLocationsRange.at(0)"
                                        [max]="numberOfKnownOfficeLocationsRange.at(-1)"
                                        (sliderChange)="updateNumberOfKnownOfficeLocationsRange($event)"></zint-ngx-slider>
        
                                    </details>

                                    <details open (toggle)="isFilterSectionDetailsExpanded($event, 'overseasRevenue')" id="searchable-show-hide-overseas-revenue">
                                        <summary [ngClass]="{'border-bottom mb-2': expandedFilterSections['overseasRevenue']}">Overseas Revenue and Territories</summary>
                                        
                                    
                                        <zint-ngx-slider id="overseas-revenue-filter" #geographicalRevenueSliderElement
                                                *ngIf="geographicalRevenueOptions" [min]="geographicalRevenueOptions.at(0)"
                                                formName="Overseas Revenue"
                                                [max]="geographicalRevenueOptions.at(-1)" prefix="£"
                                                (sliderChange)="updateGeographicalRevenueRangeSelection($event)">
                                        </zint-ngx-slider>
                                        
                                        <div id="geo-revenue-regions-countries-filter">
                                        <div class="input-group xsmall-margin-top" style="margin-bottom: -1px;">
                                                <div class="input-group-prepend">
                                                    <button class="btn basic-button-base button-dark" type="button"
                                                        (click)="resetGeographicalRevenueSearch()">
                                                        <i class="fal fa-redo-alt"></i>
                                                        Reset
                                                    </button>
                                                </div>
                                                <input type="text" class="form-control" style="border: solid 1px black"
                                                    name="geographicalRevenueTextSearch"
                                                    [(ngModel)]="geographicalRevenueTextSearch"
                                                    (ngModelChange)="searchGeographicalRevenue()" placeholder="Search...">
                                        </div>
                                        <div class="overflowing-table select-table" style="margin-bottom: -1px;">
                                            <small>- TERRITORIES -</small>
                                                <div
                                                    *ngFor="let territoryOfGeographicalRevenueOption of territoriesOfGeographicalRevenueOptions">
                                                    <ng-container *ngIf="territoryOfGeographicalRevenueOption.shouldShow">
                                                        <label>
                                                            <input type="checkbox"
                                                                [attr.name]="'territoryOfGeographicalRevenueOption' + territoryOfGeographicalRevenueOption.code"
                                                                [ngModelOptions]="{standalone: true}"
                                                                value="{{territoryOfGeographicalRevenueOption.code}}"
                                                                [(ngModel)]="territoryOfGeographicalRevenueOption.checked" />
                                                            {{
                                                            territoryOfGeographicalRevenueOption.name
                                                            }}
                                                        </label>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="form-group overflowing-table select-table">
                                            <small>- COUNTRIES -</small>
                                            <div
                                                    *ngFor="let countryOfGeographicalRevenueOption of countriesOfGeographicalRevenueOptions">
                                                    <ng-container *ngIf="countryOfGeographicalRevenueOption.shouldShow">
                                                        <label>
                                                            <input type="checkbox"
                                                                [attr.name]="'countryOfGeographicalRevenueOption' + countryOfGeographicalRevenueOption.code"
                                                                [ngModelOptions]="{standalone: true}"
                                                                value="{{countryOfGeographicalRevenueOption.code}}"
                                                                [(ngModel)]="countryOfGeographicalRevenueOption.checked" />
                                                            {{
                                                            countryOfGeographicalRevenueOption.name
                                                            }}
                                                        </label>
                                                    </ng-container>
                                            </div>
                                            <hr>
                                        </div>
                                        </div>
                                    </details>
                                </div>
                            </div>
                        </div>
        
                        <div class="card" id="structure-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('structure-collapse-card')}">
                            <div class="card-header" id="structure-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#structure-search-collapse">
                                        <i class="fal fa-sitemap" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Structure</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="structure-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label id="is-top-level-parent-checkbox-filter" class="mb-2">
                                                <input type="checkbox" name="corporateStructureIsTopLevelParentCompany"
                                                    value="false" [(ngModel)]="corporateStructureIsTopLevelParentCompany" />
                                                Is top-level parent
                                            </label>
                                            <label id="has-parent-checkbox-filter" class="mb-2">
                                                <input type="checkbox" name="corporateStructureHasParent" value="false"
                                                    [(ngModel)]="corporateStructureHasParent" />
                                                Has parent
                                            </label>
                                            <label class="mb-2" id="does-not-have-parent-checkbox-filter">
                                                <input type="checkbox" name="corporateStructureDoesNotHaveParent" value="false"
                                                    [(ngModel)]="corporateStructureDoesNotHaveParent" />
                                                Does not have parent
                                            </label>
                                            <label id="has-child-checkbox-filter" class="mb-2">
                                                <input type="checkbox" name="corporateStructureHasChild" value="false"
                                                    [(ngModel)]="corporateStructureHasChild" />
                                                Has child
                                            </label>
                                            <label id="does-not-have-child-checkbox-filter" class="mb-2">
                                                <input type="checkbox" name="corporateStructureDoesNotHaveChild" value="false"
                                                    [(ngModel)]="corporateStructureDoesNotHaveChild" />
                                                Does not have child
                                            </label>

                                            <zint-ngx-slider id="number-ultimate-beneficial-owners-filter" #numberUBOsSliderElement *ngIf="numberUBOsRange"
                                                formName="Number of Ultimate Beneficial Owners"
                                                [min]="numberUBOsRange.at(0)" [max]="numberUBOsRange.at(-1)" maxPostfix="+"
                                                (sliderChange)="updateNumberUBOsRange($event)"></zint-ngx-slider>
                                        </div>
        
                                        <div class="col-md-6" id="ubo-countries-filter">
                                            Countries of Ultimate Beneficial Owner:
                                            <div class="form-group overflowing-table select-table">
                                                <div *ngFor="let countriesOfUBOsOption of countriesOfUBOsOptions">
                                                    <label>
                                                        <input type="checkbox"
                                                            [attr.name]="'countriesOfUBOsOption' + countriesOfUBOsOption.code"
                                                            [ngModelOptions]="{standalone: true}"
                                                            value="{{countriesOfUBOsOption.code}}"
                                                            [(ngModel)]="countriesOfUBOsOption.checked" />
                                                        {{ countriesOfUBOsOption.name }}
                                                    </label>
                                                </div>
                                            </div>
        
                                        </div>
        
                                    </div>

                                    <label class="full-width" id="list-companies-in-group-filter">List all companies in
                                        group
                                        <input name="corporateStructureRelatedCompanies" id="corporateStructureRelatedCompanies" type="text"
                                            placeholder="Company Number" class="form-control mb-1" [(ngModel)]="corporateStructureRelatedCompanies"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="card" id="website-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('website-collapse-card')}">
                            <div class="card-header" id="website-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#website-search-collapse">
                                        <i class="fal fa-browser" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Website
                                            Analysis</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="website-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                        
                                    <label class="full-width mb-2" id="has-website-checkbox-filter">
                                        <input type="checkbox" name="hasWebsite" value="false" [(ngModel)]="hasWebsite" />
                                        Has website
                                    </label>
                                    
                        
                                    <label class="full-width mb-2" id="has-phone-checkbox-filter">
                                        <input type="checkbox" name="hasPhoneNumber" value="false" [(ngModel)]="hasPhoneNumber" />
                                        Has a phone number
                                    </label>
                                    
                        
                                    <label class="full-width mb-4" id="has-email-checkbox-filter">
                                        <input type="checkbox" name="hasEmailAddress" value="false" [(ngModel)]="hasEmailAddress" />
                                        Has an email address
                                    </label>
                                    
                        
                                    <div class="full-width" id="web-technologies-list-filter">Web Technologies:
                                    <div class="form-group overflowing-table select-table">
                                        <div class="input-group xsmall-margin-top" style="padding-right: 10px; padding-bottom: 10px">
                                            <div class="input-group-prepend">
                                                <button class="btn basic-button-base button-dark" type="button" (click)="resetWebTechs()">
                                                    <i class="fal fa-redo-alt"></i>
                                                    Reset
                                                </button>
                                            </div>
                                            <input type="text" class="form-control" name="sicCodeTextSearch" [(ngModel)]="webTechsTextSearch"
                                                (ngModelChange)="searchWebTechs()" placeholder="Search...">
                                        </div>
                                        <div *ngFor="let webTechnologyOption of webTechnologyOptions">
                                            <div *ngIf="webTechnologyOption.shouldShow">
                                                <label>
                                                    <input type="checkbox" [attr.name]="'webTechnologyOption' + webTechnologyOption.name"
                                                        [ngModelOptions]="{standalone: true}" value="{{webTechnologyOption.name}}"
                                                        [(ngModel)]="webTechnologyOption.checked" />
                                                </label>
                                                <span (click)="expandOrCollapseItems(webTechnologyOption.children, 'webTechnologyOptions')"
                                                    [ngClass]="{'expandable-span': webTechnologyOption.level==0}">
                                                    <span *ngIf="webTechnologyOption.level==1">&nbsp;&nbsp;
                                                        <i class="fal fa-caret-down"></i>
                                                    </span>
                                                    {{webTechnologyOption.description}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                        
                        
                                    <zint-ngx-slider id="website-visitor-filter" #websiteVisitorsSliderElement *ngIf="employeeRangeFigures" maxPostfix="+"
                                        formName="Estimated Average Monthly Website Visitors" [min]="webTrafficOptions.at(0)"
                                        [max]="webTrafficOptions.at(-1)" (sliderChange)="updateWebTraffic($event)"></zint-ngx-slider>
                    
                        
                                    <label id="website-homepage-text-filter" class="full-width mt-2">
                                        Homepage Website Text Search:
                                        <p style="font-size: 10px">Use NOT for negative
                                            search, AND for multiple phrases, OR for
                                            different options, and inverted commas (") for
                                            exact phrases. Each term taken as OR by
                                            default.</p>
                                        <input type="text" class="form-control" name="homepageSearchTerm" [(ngModel)]="homepageSearchTerm"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                    </label>
                        
                                    <div id="website-deep-crawl-search-filter" [ngClass]="{'hide-deep-crawl': !hasDeepCrawlAccess}">
                                        Zint Deep Crawl
                                        Website Text Search:
                                        <p style="font-size: 10px" *ngIf="hasDeepCrawlAccess">Use NOT for
                                            negative search, AND
                                            for multiple phrases, OR for different
                                            options, and inverted commas (") for exact
                                            phrases. Each term taken as OR by
                                            default.</p>
                                        <p style="font-size: 10px" *ngIf="!hasDeepCrawlAccess"><i class="fal fa-ban"></i> Zint
                                            Deep Crawl allows you to search company
                                            websites beyond just the homepage. You do
                                            not have access to this on your plan. Please
                                            <a href="mailto:sales@zint.io?Subject=Zint%20Deep%20Crawl%20Enquiry">contact
                                                us</a>
                                            to upgrade.
                                        </p>
                                        <input type="text" class="form-control" [attr.disabled]="!hasDeepCrawlAccess ? 'disabled': null"
                                            name="fullWebsiteSearchTerm" [(ngModel)]="fullWebsiteSearchTerm"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                                    </div>
                        
                                    <div id="website-keyword-search-filter" class="website-keyword-section">
                                        <div class="dropdown">
                                            Website Keywords
                                            <div *ngIf="websiteKeywords && websiteKeywords.length > 0">
                                                <span *ngFor="let websiteKeyword of websiteKeywords" class="badge"
                                                    style="background-color: #000a36; color: white; margin-right: 7px; margin-bottom: 8px">
                                                    {{ websiteKeyword }} <i class="fal fa-times tag-delete-button cursor-pointer"
                                                        (click)="removeWebsiteKeyword(websiteKeyword)"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control postcode-radius-text-box dropdown-toggle"
                                                name="websiteKeywordInput" placeholder="e.g. cryptocurrency" data-toggle="dropdown"
                                                [(ngModel)]="websiteKeywordInput" (keyup.enter)="forceAddWebsiteKeyword($event)"
                                                (ngModelChange)="websiteKeywordChanged()">
                                            <div class="dropdown-menu">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <zint-loader size="xSmall" *ngIf="isLoading.loadingWebsiteKeywords"></zint-loader>
                                                </div>
                                                <span *ngFor="let possibleWebsiteKeyword of possibleWebsiteKeywords"
                                                    class="dropdown-item cursor-pointer" (click)="addWebsiteKeyword(possibleWebsiteKeyword)">
                                                    <span>{{ possibleWebsiteKeyword
                                                        }}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                        
                                </div>
                            </div>
                        </div>                                                
                                                                        
                        </section>
                        </details>

                        <details open (toggle)="isFilterSectionDetailsExpanded($event, 'financials')" 
                        [ngClass]="{'border-top': isSidebarCollapsed}" id="searchable-show-hide-financials">
                            <summary>
                                <span *ngIf="!isSidebarCollapsed" class="top-level" [ngClass]="{'border-bottom': expandedFilterSections['financials']}">FINANCIALS</span>
                                <i *ngIf="isSidebarCollapsed" class="far fa-magnifying-glass-dollar ml-1"></i>
                            </summary>
                        <section>


                        <div class="card" id="detailed-financials-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('detailed-financials-collapse-card')}">
                            <div class="card-header" id="detailed-financials-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#detailed-financials-search-collapse">
                                        <i class="fal fa-chart-bar" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Core Financials</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="detailed-financials-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}" data-parent="#search-area">
                                <div class="card-body">
                                    @for (section of sliderSections; track $index) {
                                        <details (toggle)="isFilterSectionDetailsExpanded($event, section.sectionName)" [open]="filterSearchTerm" [id]="section.sectionName | kebabCase:null: 'searchable-show-hide'">
                                            <summary [ngClass]="{'border-bottom mb-2': expandedFilterSections[section.sectionName]}">{{section.sectionName}}</summary>
                                            @for (slider of zintSlidersConfigs[section.sectionName]; track $index) {
                                            <div [id]="slider.label | kebabCase:'filter'">
                                                @if (slider.label === 'Revenue'){
                                                    <label class="mb-1">
                                                        <input type="checkbox" name="ignoreRevenueEstimates" value="false"
                                                            [(ngModel)]="ignoreRevenueEstimates" />
                                                        Ignore revenue estimates
                                                    </label>
                                                }
                                                <zint-ngx-slider #genericConfigSliders [sliderConfig]="slider"
                                                    (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                            </div>
                                        }
                                        </details>
                                    }
                                </div>
                            </div>
                        </div>


                        <div class="card" id="other-financials-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('other-financials-collapse-card')}">
                            <div class="card-header" id="other-financials-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#other-financials-search-collapse">
                                        <i class="fal fa-chart-pie" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Other Financials</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="other-financials-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    
                                    <label class="mt-2 mb-1" id="accounts-overdue-checkbox-filter">
                                        <input type="checkbox" name="accountsAreOverdue" value="false"
                                        [(ngModel)]="accountsAreOverdue" />
                                        Accounts are overdue
                                    </label>
                                    <zint-ngx-slider id="accounts-due-filter" #accountsDueSliderElement *ngIf="accountsDueInRange" postfix=" days"
                                        formName="accounts due in"
                                        [min]="accountsDueInRange[0]" [max]="accountsDueInRange[1]"
                                        (sliderChange)="updateAccountsDueInRange($event)"></zint-ngx-slider>
                                    
                                    <zint-ngx-slider id="latest-accounts-submitted-filter" #latestAccountsSubmittedSliderElement *ngIf="latestAccountsSubmittedRange"
                                        formName="Latest accounts submitted"
                                        postfix=" days ago" [min]="latestAccountsSubmittedRange[0]"
                                        [max]="latestAccountsSubmittedRange[1]"
                                        (sliderChange)="updatelatestAccountsSubmittedInRange($event)"></zint-ngx-slider>
                                    
                                    
                                    <label class="mt-2 mb-1" id='has-raised-investment-checkbox-filter'>
                                        <input type="checkbox" name="hasRaisedInvestment" value="false"
                                            [(ngModel)]="hasRaisedInvestment" />
                                            Has raised investment?
                                    </label>

                                    <zint-ngx-slider id="investment-amount-raised-filter" #investmentRaisedSliderElement *ngIf="investmentRaisedOptions" prefix="£"
                                        formName="Amount Raised"
                                        [min]="investmentRaisedOptions.at(0)" [max]="investmentRaisedOptions.at(-1)"
                                        (sliderChange)="updateInvestmentRaisedRanges($event)"></zint-ngx-slider>
        
                                    <span *ngIf="hasShareFilingsAccess">                                        
                                        <label class="mt-2 mb-1" id="has-shares-checkbox-filter">
                                            <input type="checkbox" name="hasShareFilingEvent" value="false"
                                                [(ngModel)]="hasShareFilingEvent" /> 
                                                Has issued or divided shares?
                                        </label>
                                        
                                        <zint-ngx-slider id="shares-filings-within-days-filter" #shareFilingEventWithinDaysSliderElement
                                            *ngIf="shareFilingEventWithinDaysOptions" [isRange]="false" postfix=" days"
                                            formName="Issued or divided shares within"
                                            [min]="shareFilingEventWithinDaysOptions[0]"
                                            [max]="shareFilingEventWithinDaysOptions.at(-1)"
                                            (sliderChange)="updateShareFilingEventWithinDays($event)"></zint-ngx-slider>
                                       
                                        <zint-ngx-slider id="share-filing-capital-value-filter" #shareFilingCapitalValueSliderElement
                                            *ngIf="shareFilingCapitalValueOptions" maxPostfix="+"
                                            formName="With a currency value of"
                                            [min]="shareFilingCapitalValueOptions.at(0)"
                                            [max]="shareFilingCapitalValueOptions.at(-1)"
                                            (sliderChange)="updateShareFilingCapitalValue($event)">
                                        </zint-ngx-slider>
                                    </span>
        
                                    <label class="mt-2 mb-1" id="has-issued-a-grant-checkbox-filter">
                                        <input type="checkbox" name="issuedAGrant" value="false"
                                            [(ngModel)]="issuedAGrant" /> Has been issued a grant?
                                    </label>
        
                                    <zint-ngx-slider id="grant-amount-filter" #grantAmountSliderElement *ngIf="grantRangeOptions" prefix="£"
                                        formName="Grant Amount"
                                        maxPostfix="+" [min]="grantRangeOptions.at(0)" [max]="grantRangeOptions.at(-1)"
                                        (sliderChange)="updateGrantRangeSelected($event)"></zint-ngx-slider>
        
                                    
                                    <zint-ngx-slider id="grant-months-until-project-end-filter" #grantMonthsUntilProjectEndSliderElement
                                        *ngIf="grantMonthsUntilProjectEndRange" postfix=" months"
                                        formName="Grant Project Ends In"
                                        [min]="grantMonthsUntilProjectEndRange[0]" [max]="grantMonthsUntilProjectEndRange[1]"
                                        (sliderChange)="updateGrantMonthsUntilProjectEndSelected($event)"></zint-ngx-slider>
        
                                   
                                    <label class="mt-2" id="has-charge-registered-checkbox-filter">
                                        <input type="checkbox" name="chargeRegistered" value="false"
                                            [(ngModel)]="chargeRegistered" />
                                        Has one or more charge registered against it?
                                    </label>
                                    
                                    <label id="has-outstanding-charge-checkbox-filter">
                                        <input type="checkbox" name="outstandingChargeRegistered" value="false"
                                            [(ngModel)]="outstandingChargeRegistered" />
                                        Has one or more outstanding charge registered
                                        against it?
                                    </label>
        
                                    <label class="mb-2" id="has-lei-number-checkbox-filter">
                                        <input type="checkbox" name="hasLEINumber" value="false"
                                            [(ngModel)]="hasLEINumber" /> Has an LEI
                                        number?
                                    </label>
        
                                    <label class="full-width" id="lei-registration-status-dropdown-filter">LEI Registration Status
                                    <select [disabled]="!hasLEINumber" [(ngModel)]="selectedLEIStatus" name="selectedLEIStatus" class="custom-select">
                                        <option *ngFor="let LEIStatusOption of LEIStatusOptions" [ngValue]="LEIStatusOption">
                                            {{LEIStatusOption}}</option>
                                    </select> 
                                    </label>                                  
                                    
                                    <label class="full-width" id="accountant-name-contains-filter">
                                        Accountant
                                        <input type="text" class="form-control" name="accountantNameContains"
                                        [(ngModel)]="accountantNameContains"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                    </label>

                                    
                                    <label class="full-width" id="banker-name-contains-filter">Banker
                                        <input type="text" class="form-control" name="bankerNameContains"
                                        [(ngModel)]="bankerNameContains"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                    </label>
                                    
                                </div>
                            </div>
                        </div>

                        <div class="card" id="accounts-search-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('accounts-search-collapse-card')}">
                            <div class="card-header" id="accounts-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accounts-search-collapse">
                                        <i class="fal fa-table" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Search
                                            Company Accounts</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="accounts-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    <div id="accounts-search-filter">
                                        Search for businesses with the following phrases
                                        in their company accounts:<br>
                                        <span style="font-size: 10px">Use NOT for
                                            negative search, AND for multiple phrases,
                                            OR
                                            for different options, and inverted commas
                                            (") for exact phrases. Each term taken as
                                            OR by default.</span>
                                        <input type="text" class="form-control" name="accountsSearchTerm" [(ngModel)]="accountsSearchTerm"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                        <br>
                        
                                        Restrict to accounts submitted in the last:<br>
                                        <span style="font-size: 10px" *ngIf="!hasRecentAccountsAccess"><i class="fal fa-ban"></i> Your plan
                                            does
                                            not allow searching of accounts submitted
                                            in the most recent 12 months. Please <a
                                                href="mailto:sales@zint.io?Subject=Recent%20Accounts%20Search%20Enquiry">contact
                                                us</a> to upgrade.</span>
                                        <zint-ngx-slider #accountsSearchDayRangeSliderElement *ngIf="accountsSearchDayRange" [isRange]="false"
                                            postfix=" days" maxPostfix="+" [min]="hasRecentAccountsAccess ? accountsSearchDayRange[0]: 365"
                                            [max]="accountsSearchDayRange[1]"
                                            (sliderChange)="updateAccountsSearchDayRange($event)"></zint-ngx-slider>
                                    </div>
                                    <br>
                                </div>
                            </div>
                        </div>

                        <div class="card" id="assets-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('assets-collapse-card')}">
                            <div class="card-header" id="assets-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" data-target="#assetContainer">
                                        <i class="fal fa-car-building" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Assets</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="assetContainer" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body p-2" id="assetWrapperCard">
                        
                                    <div class="card mb-1" id="tangible-assets-collapse-card">
                                        <div class="card-header" role="button" data-toggle="collapse" data-target="#tangibleCollapse">
                                            <button class="btn btn-link">
                                                <i class="fal fa-warehouse fa-sm mr-1"></i><span *ngIf="!this.isSidebarCollapsed">Tangible
                                                    Assets</span>
                                            </button>
                                        </div>
                                        <div class="card-body p-2" [ngClass]="{'collapse': !filterSearchTerm}" id="tangibleCollapse">
                                            @for (tanCategory of tangibleCategories; track tanCategory; let catIndex = $index) {
                                                <details 
                                                (toggle)="isFilterSectionDetailsExpanded($event, 'tanAsset'+catIndex)" 
                                                [open]="filterSearchTerm" 
                                                [id]="tanCategory | kebabCase:null: 'searchable-show-hide'">
                                                    <summary [ngClass]="{'border-bottom mb-2': expandedFilterSections['tanAsset'+catIndex]}">{{tanCategory}}</summary>
                                                
                                                @for (slider of zintSlidersConfigs['tangibleAssets'].slice(getSliceRange(catIndex,5).start, getSliceRange(catIndex,5).end); track $index) {                                                  
                                                    <zint-ngx-slider [id]="slider.label+'-filter'" #tanAssetsSliders [sliderConfig]="slider"
                                                        (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                                }
                                            </details>
                                            }
                                        </div>
                                    </div>
                        
                                    <div class="card" id="intangible-assets-collapse-card">
                                        <div class="card-header" role="button" data-toggle="collapse" data-target="#intangibleCollapse">
                                            <button class="btn btn-link">
                                                <i class="fal fa-laptop-code fa-sm mr-1"></i><span *ngIf="!this.isSidebarCollapsed">Intangible
                                                    Assets</span>
                                            </button>
                        
                                        </div>
                                        <div class="card-body" [ngClass]="{'collapse': !filterSearchTerm}" id="intangibleCollapse">
                                            @for (inTanCategory of inTangibleCategories; track inTanCategory; let catIndex = $index) {
                                            <details 
                                            (toggle)="isFilterSectionDetailsExpanded($event, 'inTanAsset'+catIndex)" 
                                            [open]="filterSearchTerm" 
                                            [id]="inTanCategory | kebabCase:null: 'searchable-show-hide'">
                                                <summary [ngClass]="{'border-bottom mb-2': expandedFilterSections['inTanAsset'+catIndex]}">{{inTanCategory}}</summary>
                                        
                                                @for (slider of zintSlidersConfigs['intangibleAssets'].slice(getSliceRange(catIndex,4).start,
                                                getSliceRange(catIndex,4).end);
                                                track $index) {
                                                <zint-ngx-slider [id]="slider.label+'-filter'" #intanAssetsSliders [sliderConfig]="slider"
                                                    (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                                }
                                            </details>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>                        
                        </div>

                        <div class="card" [ngClass]="{'card-disabled': !hasCompanyChargesAccess}" id="company-charges-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('company-charges-collapse-card')}">
                            <div class="card-header" id="company-charges-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#company-charges-collapse">
                                        <i class="fal fa-university" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Company
                                            Charges</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="company-charges-collapse" [ngClass]="{'collapse': !filterSearchTerm}" data-parent="#search-area">
                                <ng-container *ngIf="hasCompanyChargesAccess">
                                    <div class="card-body">
                                        <label class="full-width mb-2" id="charge-holder-name-filter">Charge Holder Name Contains:
                                            <input type="text" class="form-control" name="chargeHolderNameContains"
                                            [(ngModel)]="chargeHolderNameContains"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                        </label>
                                        <zint-ngx-slider id="charge-created-period-filter" #chargeCreatedSliderElement *ngIf="chargeCreatedMonthLimits" postfix=" months ago"
                                            [min]="chargeCreatedMonthLimits[0]" formName="Charge Created" [max]="chargeCreatedMonthLimits[1]"
                                            (sliderChange)="updateChargeCreatedRangeSelection($event)"></zint-ngx-slider>
                                        
                                        <label class="mt-2" id="ignore-satisfied-charges-checkbox-filter">
                                            <input type="checkbox" name="hasContract" value="false" [(ngModel)]="ignoreSatisfiedCharges" />
                                            Ignore Satisfied Charges
                                        </label>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="card" [ngClass]="{'card-disabled': !hasEnergyAndEmissionsAccess}" id="energy-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('energy-collapse-card')}">
                            <div class="card-header" id="energy-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#energy-collapse">
                                        <i class="fal fa-plug" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Energy</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="energy-collapse" [ngClass]="{'collapse': !filterSearchTerm}" data-parent="#search-area">
                                <ng-container *ngIf="hasEnergyAndEmissionsAccess">
                                    <div class="card-body">
                                        <label class="mb-2" id="low-energy-usage-checkbox-filter">
                                            <input type="checkbox" name="isLowEnergy" value="false" [(ngModel)]="isLowEnergy" />
                                            Reported Low Energy Usage
                                            <i class="ml-2 fal fa-question-circle" matTooltipPosition="above" style="margin-top: 4px;"
                                                [matTooltip]="'Large companies that have consumed (in the UK), less than 40,000 kilowatt-hours (kWh) of energy'"></i>
                                        </label>
                                                                
                        
                                        <zint-ngx-slider id="total-energy-filter" #totalEnergyKwhSliderElement *ngIf="totalEnergyKwhOptions" formName="Total Energy"
                                            postfix=" kWh" maxPostfix="+" [min]="totalEnergyKwhOptions.at(0)"
                                            [max]="totalEnergyKwhOptions.at(-1)"
                                            (sliderChange)="updateTotalEnergyKwhRangeSelection($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="gas-energy-usage-filter" #gasKwhSliderElement *ngIf="gasKwhOptions" postfix=" kWh" formName="Gas Energy Usage"
                                            maxPostfix="+" [min]="gasKwhOptions.at(0)" [max]="gasKwhOptions.at(-1)"
                                            (sliderChange)="updateGasKwhRangeSelection($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="electric-energy-usage-filter" #electricKwhSliderElement *ngIf="electricKwhOptions" postfix=" kWh"
                                            formName="Electric Energy Usage" maxPostfix="+" [min]="electricKwhOptions.at(0)"
                                            [max]="electricKwhOptions.at(-1)"
                                            (sliderChange)="updateElectricKwhRangeSelection($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="scope-1-energy-usage-filter" #scope1KwhSliderElement *ngIf="scope1KwhOptions" postfix=" kWh"
                                            formName="Scope 1 Energy Usage" maxPostfix="+" [min]="scope1KwhOptions.at(0)"
                                            [max]="scope1KwhOptions.at(-1)"
                                            (sliderChange)="updateScope1KwhRangeSelection($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="scope-2-energy-usage-filter" #scope2KwhSliderElement *ngIf="scope2KwhOptions" postfix=" kWh"
                                            formName="Scope 2 Energy Usage" maxPostfix="+" [min]="scope2KwhOptions.at(0)"
                                            [max]="scope2KwhOptions.at(-1)"
                                            (sliderChange)="updateScope2KwhRangeSelection($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="scope-3-energy-usage-filter" #scope3KwhSliderElement *ngIf="scope3KwhOptions" postfix=" kWh"
                                            formName="Scope 3 Energy Usage" maxPostfix="+" [min]="scope3KwhOptions.at(0)"
                                            [max]="scope3KwhOptions.at(-1)"
                                            (sliderChange)="updateScope3KwhRangeSelection($event)"></zint-ngx-slider>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        
                        <div class="card" [ngClass]="{'card-disabled': !hasEnergyAndEmissionsAccess}" id="emissions-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('emissions-collapse-card')}">
                            <div class="card-header" id="emissions-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#emissions-collapse">
                                        <i class="fal fa-campfire" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Emissions</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="emissions-collapse" [ngClass]="{'collapse': !filterSearchTerm}" data-parent="#search-area">
                                <ng-container *ngIf="hasEnergyAndEmissionsAccess">
                                    <div class="card-body">
                                        <zint-ngx-slider id="total-emissions-filter" #totalEmissionsKgCo2eSliderElement *ngIf="totalEmissionsKgCo2eOptions"
                                            formName="Total Emissions" postfix=" KgCO2e" maxPostfix="+"
                                            [min]="totalEmissionsKgCo2eOptions.at(0)" [max]="totalEmissionsKgCo2eOptions.at(-1)"
                                            (sliderChange)="updateTotalEmissionsKgCo2eRangeSelection($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="scope-1-emissions-filter" #scope1KgCo2eSliderElement *ngIf="scope1KgCo2eOptions" postfix=" KgCO2e" maxPostfix="+"
                                            [min]="scope1KgCo2eOptions.at(0)" formName="Scope 1 Emissions" [max]="scope1KgCo2eOptions.at(-1)"
                                            (sliderChange)="updateScope1KgCo2eRangeSelection($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="scope-2-emissions-filter" #scope2KgCo2eSliderElement *ngIf="scope2KgCo2eOptions" postfix=" KgCO2e" maxPostfix="+"
                                            [min]="scope2KgCo2eOptions.at(0)" formName="Scope 2 Emissions" [max]="scope2KgCo2eOptions.at(-1)"
                                            (sliderChange)="updateScope2KgCo2eRangeSelection($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="scope-3-emissions-filter" #scope3KgCo2eSliderElement *ngIf="scope3KgCo2eOptions" postfix=" KgCO2e" maxPostfix="+"
                                            [min]="scope3KgCo2eOptions.at(0)" formName="Scope 3 Emissions" [max]="scope3KgCo2eOptions.at(-1)"
                                            (sliderChange)="updateScope3KgCo2eRangeSelection($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="kg-co2e-per-million-revenue-filter" #kgCo2ePerMillionRevenueSliderElement *ngIf="kgCo2ePerMillionRevenueOptions"
                                            postfix=" KgCO2e" maxPostfix="+" formName="KgCO2e Per Million Revenue"
                                            [min]="kgCo2ePerMillionRevenueOptions.at(0)" [max]="kgCo2ePerMillionRevenueOptions.at(-1)"
                                            (sliderChange)="updateKgCo2ePerMillionRevenueRangeSelection($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="kg-co2e-per-employee-filter" #kgCo2ePerEmployeeSliderElement *ngIf="kgCo2ePerEmployeeOptions" postfix=" KgCO2e"
                                            maxPostfix="+" [min]="kgCo2ePerEmployeeOptions.at(0)" formName="KgCO2e Per Employee"
                                            [max]="kgCo2ePerEmployeeOptions.at(-1)"
                                            (sliderChange)="updateKgCo2ePerEmployeeRangeSelection($event)"></zint-ngx-slider>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        </section>
                        </details>
        
                        <details open (toggle)="isFilterSectionDetailsExpanded($event, 'operations')" 
                        [ngClass]="{'border-top': isSidebarCollapsed}" id="searchable-show-hide-operations">
                            <summary>
                                <span *ngIf="!isSidebarCollapsed" class="top-level" [ngClass]="{'border-bottom': expandedFilterSections['operations']}">OPERATIONS</span>
                                <i  *ngIf="isSidebarCollapsed" class="fal fa-arrow-progress ml-1"></i>                                
                            </summary>
                        <section>
                        
                        <div class="card overflow-xy-visible" id="importsexports-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('importsexports-collapse-card')}">
                            <div class="card-header border-rounded-top-05" id="importsexports-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#importsexports-search-collapse">
                                        <i class="fal fa-ship" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Imports
                                            and Exports</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="importsexports-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    <div id="import-export-overflowing-table-filter" appFixedOnHover>
                                    Import Export Products:
                                    <div class="form-group overflowing-table select-table" >
                        
                                        <div class="input-group xsmall-margin-top" style="padding-right: 10px; padding-bottom: 10px">
                                            <div class="input-group-prepend">
                                                <button class="btn basic-button-base button-dark" type="button"
                                                    (click)="resetImportExportSearch()">
                                                    <i class="fal fa-redo-alt" *ngIf="!isLoading.loadingImportExportCodes"></i>
                                                    <i class="fal fa-spinner fa-spin" *ngIf="isLoading.loadingImportExportCodes"></i>
                                                    Reset
                                                </button>
                                            </div>
                                            <input type="text" class="form-control" name="importExportTextSearch"
                                                [(ngModel)]="importExportTextSearch" (keyup.enter)="searchImportExportCodes()"
                                                placeholder="Search...">
                                        </div>
                        
                                        <div *ngFor="let importExportCategory of importExportCategories">
                                            <div *ngIf="importExportCategory.shouldShow" class="cut-off">
                                                <label class="expandable-label">
                                                    <input type="checkbox"
                                                        [attr.name]="'importExportCategory' + importExportCategory.unique_identifier"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (change)="updateFilters(); updateToDefaultImportingOrExporting()"
                                                        value="{{importExportCategory.unique_identifier}}"
                                                        [(ngModel)]="importExportCategory.checked" />
                                                </label>
                                                <span (click)="expandOrCollapseImportExportCategories(importExportCategory)"
                                                    class="expandable-span"><ng-container
                                                        *ngFor="let dummy of ' '.repeat(importExportCategory.level).split(' ')">&nbsp;&nbsp;</ng-container><i
                                                        class="fal fa-caret-down" *ngIf="importExportCategory.level > 0"></i>
                                                    {{importExportCategory.description}}</span>
                                                <hr class="select-break-hr">
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                        
                                    <div class="div-below-impexp-table">
                                        <div id="import-export-option-dropdown-filter" class="row xsmall-margin-bottom" style="padding-left: 1.25rem;">
                                            <div style="display:inline; margin-top: 5px; margin-right: 2px">
                                                Has&nbsp;
                                            </div>
                                            <div style="display:inline">
                                                <select [(ngModel)]="selectedImportingOrExportingOption"
                                                    name="selectedImportingOrExportingOption" class="custom-select">
                                                    <option *ngFor="let importExportOption of importingExportingBothOptions"
                                                        [ngValue]="importExportOption.identifier">
                                                        {{importExportOption.description_past}}</option>
                                                </select>
                                            </div>
                                            <div style="display:inline; margin-top: 5px; margin-left: 2px">
                                                &nbsp;<span *ngIf="selectedImportExportCategories?.length > 0">any
                                                    of the above
                                                    selected products</span> <span
                                                    *ngIf="selectedImportExportCategories?.length == 0">anything</span>
                                                in the last:
                                            </div>
                                        </div>
                                        <zint-ngx-slider id="import-export-month-range-filter" #importExportSliderElement *ngIf="importExportMonthRange" [isRange]="false"
                                            postfix=" months" maxPostfix="+" [min]="importExportMonthRange[0]" [max]="importExportMonthRange[1]"
                                            (sliderChange)="updateImportExportTimeScale($event)"></zint-ngx-slider>                                                                                                                                
                                        <div id="import-export-frequency-filter" class="row my-3 pl-4 pt-2 border-top">
                                            <div style="display:inline; margin-top: 5px; margin-right: 2px">
                                                Has&nbsp;
                                            </div>
                                            <div style="display:inline">
                                                <select [(ngModel)]="importExportFrequencyImportingOrExporting"
                                                    name="importExportFrequencyImportingOrExporting" class="custom-select">
                                                    <option *ngFor="let importExportOption of importingExportingBothOptions"
                                                        [ngValue]="importExportOption.identifier">
                                                        {{importExportOption.description_past}}</option>
                                                </select>
                                            </div>
                                            <div style="display:inline; margin-top: 5px; margin-left: 2px">
                                                &nbsp;anything at least
                                            </div>
                                        </div>
                        
                                        <zint-ngx-slider id="import-export-frequency-range-filter" #importExportFrequencySliderElement *ngIf="importExportFrequencyRange"
                                            [isRange]="false" postfix=" times" maxPostfix="+" [min]="importExportFrequencyRange[0]"
                                            [max]="importExportFrequencyRange[1]"
                                            (sliderChange)="updateImportExportFrequency($event)"></zint-ngx-slider>
                                         
                                        <div id="import-export-frequency-months-filter" class="my-2">
                                            in the last                                            
                                            <zint-ngx-slider #importExportFrequencyMonthCountSliderElement
                                            *ngIf="importExportFrequencyMonthCountRange" [isRange]="false" postfix=" months" maxPostfix="+"
                                            [min]="importExportFrequencyMonthCountRange[0]" [max]="importExportFrequencyMonthCountRange[1]"
                                            (sliderChange)="updateImportExportFrequencyMonthCount($event)">
                                        </zint-ngx-slider>
                                    </div>    
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card overflow-xy-visible" id="employees-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('employees-collapse-card')}">
                            <div class="card-header" id="employees-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#employees-search-collapse">
                                        <i class="fal fa-users" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">People</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="employees-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">

                                    <details open (toggle)="isFilterSectionDetailsExpanded($event, 'headCount')" id="searchable-show-hide-headcount-department">
                                        <summary [ngClass]="{'border-bottom mb-2': expandedFilterSections['headCount']}">Head Count, Department and Wages</summary>
                                        
                                    
                                            
                                    <label class="mt-2 mb-1" id="exclude-estimated-checkbox-filter">
                                        <input type="checkbox" name="excludeEstimatedHeadcountFigures" value="false"
                                            [(ngModel)]="excludeEstimatedHeadcountFigures" />
                                        Exclude estimated?
                                    </label>
        
                                    <zint-ngx-slider id="employee-range-filter" #headcountSliderElement *ngIf="employeeRangeFigures" postfix=" employees"
                                        formName="Head count"
                                        maxPostfix="+" [min]="employeeRangeFigures.at(0)" [max]="employeeRangeFigures.at(-1)"
                                        (sliderChange)="updateEmployeeRange($event)"></zint-ngx-slider>
                                            
                                    <zint-ngx-slider id="last-year-head-count-growth-filter" #headcountGrowthSliderElement *ngIf="headcountGrowthPercentageRange"
                                        formName="Last year head count growth"
                                        postfix="%" [min]="headcountGrowthPercentageRange.at(0)"
                                        [max]="headcountGrowthPercentageRange.at(-1)"
                                        (sliderChange)="updateHeadcountGrowthRange($event)"></zint-ngx-slider>                                    
        
                                    <zint-ngx-slider id="departmental-breakdown-filter" #departmentHeadcountSliderElement *ngIf="employeeRangeFigures"
                                        formName="Departmental Breakdown"
                                        postfix=" employees" maxPostfix="+" [min]="employeeRangeFigures.at(0)"
                                        [max]="employeeRangeFigures.at(-1)"
                                        (sliderChange)="updateDepartmentHeadcountRange($event)"></zint-ngx-slider>        
                                    <br>

                                    <div id="people-overflowing-table-filter" class="form-group overflowing-table select-table" appFixedOnHover 
                                    (mouseenter)="checkIfTableHoveredAndFixed($event, 'people')"
                                    (mouseleave)="checkIfTableHoveredAndFixed($event, 'people', true)"
                                    >
        
                                        <div class="input-group xsmall-margin-top"
                                            style="padding-right: 10px; padding-bottom: 10px">
                                            <div class="input-group-prepend">
                                                <button class="btn basic-button-base button-dark" type="button"
                                                    (click)="resetFunctionalDepartmentSearch()">
                                                    <i class="fal fa-redo-alt"></i>
                                                    Reset
                                                </button>
                                            </div>
                                            <input type="text" class="form-control" name="sicCodeTextSearch"
                                                [(ngModel)]="functionalDepartmentTextSearch"
                                                (ngModelChange)="searchFunctionalDepartment()" placeholder="Search...">
                                        </div>
        
                                        <label *ngIf="hasSearchedFunctionalDepartment">
                                            <input type="checkbox" name="selectAllVisibleFunctionalDepartments" value="false"
                                                (change)="checkUncheckAllVisibleFunctionalDepartments()"
                                                [(ngModel)]="selectAllVisibleFunctionalDepartments" />
                                            Select all visible
                                        </label>
        
                                        <div *ngFor="let functionalDepartment of functionalDepartmentOptions">
                                            <div class="cut-off" *ngIf="functionalDepartment.shouldShow">
                                                <label class="expandable-label">
                                                    <input type="checkbox" [attr.name]="functionalDepartment.name"
                                                        [ngModelOptions]="{standalone: true}"
                                                        value="{{functionalDepartment.name}}"
                                                        [(ngModel)]="functionalDepartment.checked" />
                                                    {{functionalDepartment.name}}
                                                </label>
                                                <hr class="select-break-hr">
                                            </div>
                                        </div>
        
                                    </div>
                                    
                                    <div [ngClass]="{'mt-316': hoveredOverflowingTables['people']}">
                                    @for (slider of zintSlidersConfigs['wages']; track $index) {
                                        <zint-ngx-slider [id]="slider.label+'-filter'" #wagesSliders [sliderConfig]="slider"
                                            (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                        }                                    
                                    </div>
                                    </details>
                                    
                                    <details open (toggle)="isFilterSectionDetailsExpanded($event, 'officersPSCs')" id="searchable-show-hide-officers-psc">
                                       <summary [ngClass]="{'border-bottom mb-2': expandedFilterSections['officersPSCs']}">Officers and PSCs</summary>     
                                    <zint-ngx-slider id="average-director-age-filter" #averageDirectorAgeSliderElement *ngIf="averageDirectorAgeRange"
                                        formName="Average Director Age"
                                        postfix=" years" [min]="averageDirectorAgeRange[0]" [max]="averageDirectorAgeRange[1]"
                                        (sliderChange)="updateAverageDirectorAgeRange($event)"></zint-ngx-slider>
                                           
                                    <zint-ngx-slider id="number-of-directors-filter" #numberOfDirectorsSliderElement *ngIf="numberOfDirectorsRange"
                                        formName="Number of Directors"
                                        maxPostfix="+" [min]="numberOfDirectorsRange[0]" [max]="numberOfDirectorsRange[1]"
                                        (sliderChange)="updateNumberOfDirectorsRange($event)"></zint-ngx-slider>
        
                                    
                                    <label id="pscs-real-persons-only-checkbox-filter" class="mt-2 mb-1">
                                        <input type="checkbox" name="PSCsRealPersonsOnly" value="false"
                                            [(ngModel)]="PSCsRealPersonsOnly" />
                                        Include real persons only (and exclude legal
                                        entities)?
                                    </label>
                                    <zint-ngx-slider id="number-of-pscs-filter" #numberOfPSCsSliderElement *ngIf="numberOfPSCsRange" maxPostfix="+"
                                        formName="Number of Persons of Significant Control"
                                        [min]="numberOfPSCsRange[0]" [max]="numberOfPSCsRange[1]"
                                        (sliderChange)="updateNumberOfPSCsRange($event)"></zint-ngx-slider>
                                                                                

                                    <label class="mt-2 full-width" id="new-officer-job-title-filter">New officer with job title 
                                        <input type="text" class="form-control mb-1" name="recentDirectorshipTitleContains"
                                        [(ngModel)]="recentDirectorshipTitleContains"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"
                                        placeholder="Leave blank for any title">
                                    </label>
                                    
                                    <zint-ngx-slider id="directorship-range-filter" #recentDirectorshipMonthRangeSliderElement
                                        *ngIf="recentDirectorshipMonthRange" postfix=" months"
                                        formName="appointed in the last"
                                        [min]="recentDirectorshipMonthRange[0]" [max]="recentDirectorshipMonthRange[1]"
                                        (sliderChange)="updateRecentDirectorshipMonthRange($event)"></zint-ngx-slider>
                                            
        
                                    <div class="mt-2 row" id="officer-nationalities-filter">
                                        <div class="col-md-6 d-sm-none d-xs-none d-md-block">
                                            Nationalities of Officers:
                                        </div>
                                        <div class="col-md-6 d-sm-none d-xs-none d-md-block">
                                            Nationalities of Persons of Significant
                                            Control:
                                        </div>
                                    </div>
                
                                    <div class="row" id="officer-nationalities-table-filter">
                                        <div class="col-md-6">
                                            <span class="d-md-none">Nationalities of
                                                Officers:</span>
                                            <div class="form-group overflowing-table select-table">
                                                <div
                                                    *ngFor="let nationalitiesOfDirectorsOption of nationalitiesOfDirectorsOptions">
                                                    <label>
                                                        <input type="checkbox"
                                                            [attr.name]="'nationalitiesOfDirectorsOption' + nationalitiesOfDirectorsOption.code"
                                                            [ngModelOptions]="{standalone: true}"
                                                            value="{{nationalitiesOfDirectorsOption.code}}"
                                                            [(ngModel)]="nationalitiesOfDirectorsOption.checked" />
                                                        {{
                                                        nationalitiesOfDirectorsOption.name
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <span class="d-md-none">Nationalities of
                                                Persons of Significant Control:</span>
                                            <div class="form-group overflowing-table select-table">
                                                <div *ngFor="let nationalitiesOfPSCsOption of nationalitiesOfPSCsOptions">
                                                    <label>
                                                        <input type="checkbox"
                                                            [attr.name]="'nationalitiesOfPSCsOption' + nationalitiesOfPSCsOption.code"
                                                            [ngModelOptions]="{standalone: true}"
                                                            value="{{nationalitiesOfPSCsOption.code}}"
                                                            [(ngModel)]="nationalitiesOfPSCsOption.checked" />
                                                        {{
                                                        nationalitiesOfPSCsOption.name
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                            
                                    <label class="full-width" id="leadership-job-title-filter">Member of Leadership Team has Job Title Containing:
                                        <input type="text" class="form-control" name="directorJobTitleContains"
                                        [(ngModel)]="directorJobTitleContains"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                    </label>                                     
                                    </details>
                                    
                                </div>
                            </div>
                        </div>                                                                               
        
                        <div class="card" id="hiring-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('hiring-collapse-card')}">
                            <div class="card-header" id="hiring-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#hiring-search-collapse">
                                        <i class="fal fa-user-tie" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Hiring</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="hiring-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    <label class="mb-2" id="is-hiring-checkbox-filter">
                                        <input type="checkbox" name="isHiring" value="false" [(ngModel)]="isHiring" /> Is
                                        hiring?
                                    </label>
                                    
                                    <label id="include-recruiters-checkbox-filter">
                                        <input type="checkbox" name="includeRecruiters" value="false"
                                            [(ngModel)]="includeRecruiters" />
                                        Include
                                        recruiter listings?
                                    </label>
        
                                    <label class="full-width mt-3" id="hiring-job-title-contains-filter">
                                    For position(s) containing job title:
                                        <input type="text" class="form-control" name="hiringJobTitleContains"
                                        [(ngModel)]="hiringJobTitleContains"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                    </label>
        
                                    <label class="full-width my-2" id="hiring-description-contains-filter">
                                    Job description contains the following text:<br>
                                    <span style="font-size: 10px">Use NOT for negative
                                        search, AND for multiple phrases, OR for
                                        different options, and inverted commas (") for
                                        exact phrases. Each term taken as OR by
                                        default.</span>
                                    <input type="text" class="form-control" name="hiringDescriptionContains"
                                        [(ngModel)]="hiringDescriptionContains"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                    </label>
                                        
                                            
                                    <zint-ngx-slider id="hiring-day-limit-filter" #hiringDayLimitSliderElement *ngIf="hiringDayLimits" [isRange]="false"
                                        formName="in the last"
                                        postfix=" days" maxPostfix="+" [min]="hiringDayLimits[0]" [max]="hiringDayLimits[1]"
                                        (sliderChange)="updateHiringDayRange($event)"></zint-ngx-slider>
                                    <hr>                                                                                                                   
                                    <zint-ngx-slider id="number-of-roles-hiring-filter" #numberOfRolesHiringForSliderElement *ngIf="numberOfRolesHiringForLimits"
                                        formName="Number of roles hiring for"
                                        postfix=" roles" [min]="numberOfRolesHiringForLimits[0]"
                                        [max]="numberOfRolesHiringForLimits[1]"
                                        (sliderChange)="updateNumberOfRolesHiringForRange($event)"></zint-ngx-slider>
                                    
                                    <zint-ngx-slider id="number-of-roles-hiring-period-filter"  #numberOfRolesHiringForDayLimitSliderElement
                                        *ngIf="numberOfRolesHiringForDayLimits" [isRange]="false" postfix=" days" maxPostfix="+"
                                        formName="in the last"
                                        [min]="numberOfRolesHiringForDayLimits[0]" [max]="numberOfRolesHiringForDayLimits[1]"
                                        (sliderChange)="updateNumberOfRolesHiringForDayRange($event)"></zint-ngx-slider>        
                                </div>
                            </div>
                        </div>
        
                        <div class="card" id="timing-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('timing-collapse-card')}">
                            <div class="card-header" id="timing-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#timing-search-collapse">
                                        <i class="fal fa-clock" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Timing</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="timing-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                            
                                    <zint-ngx-slider id="fiscal-year-end-range-filter" #daysUntilFinancialYearEndSliderElement
                                        *ngIf="daysUntilFinancialYearEndLimits" postfix=" days"
                                        formName="Days until financial year end"
                                        [min]="daysUntilFinancialYearEndLimits[0]" [max]="daysUntilFinancialYearEndLimits[1]"
                                        (sliderChange)="updateFinancialYearEndRange($event)"></zint-ngx-slider>                                                    
                                    <div id="fiscal-year-end-in-month-filter" class="my-3 form-group select-table">
                                        <label for="options">Financial year
                                            ends:</label>
                                        <div *ngFor="let financialYearEndsOption of financialYearEndsOptions">
                                            <label>
                                                <input type="checkbox"
                                                    [attr.name]="'financialYearEndsOption' + financialYearEndsOption.value"
                                                    [ngModelOptions]="{standalone: true}"
                                                    value="{{financialYearEndsOption.value}}"
                                                    [(ngModel)]="financialYearEndsOption.checked" />
                                                {{ financialYearEndsOption.name }}
                                            </label>
                                        </div>
                                    </div>        
        
                                    <zint-ngx-slider id="anniversary-filter" #daysUntilAnniversarySliderElement *ngIf="daysUntilAnniversaryLimits"
                                        postfix=" days" [min]="daysUntilAnniversaryLimits[0]"
                                        [max]="daysUntilAnniversaryLimits[1]"
                                        formName="Days until incorporation anniversary"
                                        (sliderChange)="updateAnniversaryRange($event)"></zint-ngx-slider>
        
                                </div>
                            </div>
                        </div>
        
                        <div class="card" id="events-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('events-collapse-card')}">
                            <div class="card-header" id="events-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#events-search-collapse">
                                        <i class="fal fa-calendar-alt" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Events</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="events-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    <div class="mb-3">
                                        <div class="dropdown" id="event-name-filter">
                                            <span
                                                [ngClass]="{'very-transparent-text': postcodeEventsWithinRadius && postcodeEventsWithinRadius.length > 0}">Search
                                                for events by name:
                                                <sup>(beta)</sup></span>
                                            <div *ngIf="selectedEvents && selectedEvents.length > 0"
                                                [ngClass]="{'very-transparent-text': postcodeEventsWithinRadius && postcodeEventsWithinRadius.length > 0}">
                                                <span *ngFor="let selectedEvent of selectedEvents" class="badge"
                                                    style="background-color: #000a36; color: white; margin-right: 7px; margin-bottom: 8px">
                                                    {{ selectedEvent.name }} <i
                                                        class="fal fa-times tag-delete-button cursor-pointer"
                                                        (click)="removeMatchingEvent(selectedEvent)"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control postcode-radius-text-box dropdown-toggle"
                                                name="eventNameSearch" placeholder="e.g. sales expo" data-toggle="dropdown"
                                                [(ngModel)]="eventNameSearchInput" (ngModelChange)="eventNameSearchChanged()"
                                                [ngClass]="{'very-transparent-text': postcodeEventsWithinRadius && postcodeEventsWithinRadius.length > 0}">
                                            <div class="dropdown-menu">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <zint-loader size="xSmall"
                                                        *ngIf="isLoading.loadingMatchingEventNames"></zint-loader>
                                                </div>
                                                <span *ngFor="let possibleMatchingEvent of possibleMatchingEvents"
                                                    class="dropdown-item cursor-pointer"
                                                    (click)="addMatchingEvent(possibleMatchingEvent)">
                                                    <span>{{ possibleMatchingEvent.name
                                                        }}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div id="events-mile-radius-filter" [ngClass]="{'very-transparent-text': selectedEvents && selectedEvents.length > 0}">
                                        <label class="mb-3">Exhibiting at an event within radius of
                                        postcode:
                                        <input type="text" class="form-control" name="postcodeEventsWithinRadius"
                                            (ngModelChange)="this.selectedEvents = []" [(ngModel)]="postcodeEventsWithinRadius"
                                            style="width: 100px; display:inline"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                        </label>
                                        <zint-ngx-slider #eventsMileRadiusSliderElement *ngIf="mileRadiusEventsRange"
                                            [isRange]="false" postfix=" miles" [min]="mileRadiusEventsRange[0]"
                                            [max]="mileRadiusEventsRange[1]"
                                            (sliderChange)="updateEventsMileageRange($event)"></zint-ngx-slider>
                                    </div>
        
                                    <label class="mt-3 full-width" id="events-day-range-filter">
                                    ...in the next...                                    
                                    <zint-ngx-slider #eventsDayRangeSliderElement *ngIf="eventDaysRange" postfix=" days"
                                        maxPostfix="+" [min]="eventDaysRange[0]" [max]="eventDaysRange[1]"
                                        (sliderChange)="updateEventsDaysRange($event)"></zint-ngx-slider>
                                    </label>    
                                </div>
                            </div>
                        </div>
        
                        <div class="card" id="news-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('news-collapse-card')}">
                            <div class="card-header" id="news-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#news-search-collapse">
                                        <i class="fal fa-newspaper" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">News</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="news-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    <div id="news-article-text-filter">
                                        Mentioned in news article containing the
                                        following text:<br>
                                        <span style="font-size: 10px">Use NOT for
                                            negative search, AND for multiple phrases,
                                            OR
                                            for different options, and inverted commas
                                            (") for exact phrases. Each term taken as
                                            OR by default.</span>
                                        <input type="text" class="form-control" name="newsContaining"
                                            [(ngModel)]="newsContaining"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                    </div>
                                    <label class="full-width" id="news-source-dropdown-filter">News Source:
                                    <select [(ngModel)]="newsSource" name="newsSource" class="custom-select">
                                        <option value="external">
                                            External Publication
                                        </option>
                                        <option value="selfPublished">
                                            Self Published
                                        </option>
                                        <option value="either">
                                            Either
                                        </option>
                                    </select>
                                    </label>

                                    <label class="full-width" id="news-period-range-filter">Within:
                                    <zint-ngx-slider #newsDayRangeSliderElement *ngIf="newsDaysRange" [isRange]="false"
                                        postfix=" days" maxPostfix="+" [min]="newsDaysRange[0]" [max]="newsDaysRange[1]"
                                        (sliderChange)="updateNewsDays($event)"></zint-ngx-slider>
                                    </label>
                                </div>
                            </div>
                        </div>
        
                        <div class="card" id="marketplaces-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('marketplaces-collapse-card')}">
                            <div class="card-header" id="other-attrs-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#other-attrs-search-collapse">
                                        <i class="fal fa-shopping-cart" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Marketplaces</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="other-attrs-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    <label class="mb-2" id="amazon-seller-checkbox-filter">
                                        <input type="checkbox" name="amazonSeller" value="false" [(ngModel)]="amazonSeller" />
                                        Is an Amazon seller
                                    </label>
                                    <label class="" id="gcloud-seller-checkbox-filter">
                                        <input type="checkbox" name="listedOnGCloud" value="false"
                                            [(ngModel)]="listedOnGCloud" />
                                        Is listed on the Government "G Cloud" Digital
                                        Marketplace as a seller of digital
                                        products/services available to public sector
                                        organisations
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="card" id="government-contracts-collapse-card" [ngClass]="{'card-disabled': !hasGovernmentContractsAccess, 'active-filter-highlight': highlightFilterSection('government-contracts-collapse-card')}">
                            <div class="card-header" id="government-contracts-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#government-contracts-collapse">
                                        <i class="fal fa-file-signature" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Government
                                            Contracts</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="government-contracts-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <ng-container *ngIf="hasGovernmentContractsAccess">
                                    <div class="card-body">
                                        <label class="mb-1" id="has-awarded-contract-checkbox-filter">
                                            <input type="checkbox" name="hasContract" value="false" [(ngModel)]="hasContract" />
                                            Has been awarded contract
                                        </label>
                        
                                        <zint-ngx-slider id="contract-value-filter" #contractValueSliderElement *ngIf="contractValueOptions" prefix="£" maxPostfix="+"
                                            [min]="contractValueOptions.at(0)" formName="Contract Value" [max]="contractValueOptions.at(-1)"
                                            (sliderChange)="updateContractValueRangeSelection($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="contract-awarded-peroid-filter" #contractAwardedWithinMonthsSliderElement *ngIf="contractAwardedWithinMonthsOptions"
                                            [isRange]="false" postfix=" months" formName="Awarded in the last" maxPostfix="+"
                                            [min]="contractAwardedWithinMonthsOptions.at(0)" [max]="contractAwardedWithinMonthsOptions.at(-1)"
                                            (sliderChange)="updateContractAwardedWithinMonths($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="contract-starts-within-filter" #contractStartMonthsSliderElement *ngIf="contractStartMonthsOptions" postfix=" months"
                                            maxPostfix="+" [min]="contractStartMonthsOptions.at(0)" formName="Contract starts within"
                                            [max]="contractStartMonthsOptions.at(-1)"
                                            (sliderChange)="updateContractStartMonthsRangeSelection($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="contract-ends-within-filter" #contractEndMonthsSliderElement *ngIf="contractEndMonthsOptions" postfix=" months"
                                            maxPostfix="+" [min]="contractEndMonthsOptions.at(0)" formName="Contract ends within"
                                            [max]="contractEndMonthsOptions.at(-1)"
                                            (sliderChange)="updateContractEndMonthsRangeSelection($event)"></zint-ngx-slider>
                                        <label class="full-width my-3" id="contract-title-text-filter">Contract Title Contains:
                                            <input type="text" class="form-control" name="contractTitleContains" [(ngModel)]="contractTitleContains"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                        </label>
                                        <label class="full-width" id="contract-description-text-filter">
                                        Contract Description Contains:
                                        <input type="text" class="form-control" name="contractDescriptionContains"
                                            [(ngModel)]="contractDescriptionContains"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                        </label>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="card overflow-xy-visible" id="trademarks-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('trademarks-collapse-card')}">
                            <div class="card-header" id="trademarks-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#trademarks-search-collapse">
                                        <i class="fal fa-registered" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Trademarks</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="trademarks-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    
                                    <label class="full-width mb-2" id="trademark-name-contains-filter">Trademark Name Contains:
                                        <input type="text" class="form-control" name="trademarkNameContains" [(ngModel)]="trademarkNameContains"
                                        style="width: 100%" placeholder="e.g. Marmite"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                    </label>
                                    <label class="full-width mb-2" id="trademark-description-contains-filter">Trademark Description Contains:
                                    <input type="text" class="form-control" name="trademarkDescriptionContains"
                                        [(ngModel)]="trademarkDescriptionContains" style="width: 100%"
                                        placeholder="e.g. artificial intelligence"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                    </label>

                                    <div id="trademark-status-filter">Trademark Status:
                                    <div class="form-group select-table py-2">
                                        <div *ngFor="let trademarkStatusOption of trademarkStatusOptions">
                                            <div *ngIf="trademarkStatusOption.level == 0 || trademarkStatusOption.shouldShow">
                                                <label>
                                                    <input type="checkbox" [attr.name]="'trademarkStatusOption' + trademarkStatusOption.name"
                                                        [ngModelOptions]="{standalone: true}" value="{{trademarkStatusOption.name}}"
                                                        [(ngModel)]="trademarkStatusOption.checked" />
                                                </label>
                                                <span (click)="expandOrCollapseItems(trademarkStatusOption.children, 'trademarkStatusOptions')"
                                                    [ngClass]="{'expandable-span': trademarkStatusOption.level==0}"><span
                                                        *ngIf="trademarkStatusOption.level==1">&nbsp;&nbsp;
                                                        <i class="fal fa-caret-down"></i>
                                                    </span>
                                                    {{trademarkStatusOption.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                        
                                    <div id="trademark-overflowing-table-filter" appFixedOnHover>Trademark Class:
                                        <div class="form-group select-table overflowing-table" >
                                            <div *ngFor="let trademarkClassOption of trademarkClassOptions">
                                                <div class="cut-off">
                                                    <label>
                                                        <input type="checkbox" [attr.name]="'trademarkClassOption' + trademarkClassOption.id"
                                                            [ngModelOptions]="{standalone: true}" value="{{trademarkClassOption.id}}"
                                                            [(ngModel)]="trademarkClassOption.checked" />
                                                            <span>&nbsp;&nbsp;
                                                                {{trademarkClassOption.number}}:
                                                                {{trademarkClassOption.description
                                                        }}</span>
                                                        </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="div-below-trademark-table">
                                        <br>
                                        @for (slider of zintSlidersConfigs['numberOfActiveTrademarks']; track $index) {
                                            <zint-ngx-slider [id]="slider.label+'-filter'" #numberOfActiveTrademarksSliders [sliderConfig]="slider" (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card" id="patents-collapse-card" [ngClass]="{'card-disabled': !hasPatentsAccess, 'active-filter-highlight': highlightFilterSection('patents-collapse-card')}">
                            <div class="card-header" id="patents-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#patents-collapse">
                                        <i class="fal fa-book" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Patents</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="patents-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <ng-container *ngIf="hasPatentsAccess">
                                    <div class="card-body">
                                        <label class="full-width" id="has-patent-checkbox-filter">
                                            <input type="checkbox" name="hasAnyPatentRecord" value="false" [(ngModel)]="hasAnyPatentRecord" />
                                            Has Any Patent Record
                                            <i class="fal fa-question-circle ml-2" matTooltipPosition="above" style="margin-top: 4px;"
                                                [matTooltip]="'Companies with any patent of any status, past or present.'"></i>
                                        </label>
                        
                                        <label class="full-width my-2" id="has-active-patent-checkbox-filter">
                                            <input type="checkbox" name="hasActivePatent" value="false" [(ngModel)]="hasActivePatent" />
                                            Has Active Patent
                                            <i class="fal fa-question-circle ml-2" matTooltipPosition="above" style="margin-top: 4px;"
                                                [matTooltip]="'Companies with granted patents that are not expired, revoked or ceased.'"></i>
                            
                                        </label>
                                        
                        
                                        <zint-ngx-slider id="filed-patent-in-the-last-filter" #patentFiledWithinDaysSliderElement *ngIf="patentFiledWithinDaysOptions"
                                            [isRange]="false" postfix=" days" formName="Filed patent in the last"
                                            [min]="patentFiledWithinDaysOptions.at(0)" [max]="patentFiledWithinDaysOptions.at(-1)"
                                            (sliderChange)="updatePatentFiledWithinDays($event)"></zint-ngx-slider>
                        
                                        <zint-ngx-slider id="awarded-patent-in-the-last-filter" #patentAwardedWithinDaysSliderElement *ngIf="patentAwardedWithinDaysOptions"
                                            postfix=" days" formName="Awarded patent in the last" [min]="patentAwardedWithinDaysOptions.at(0)"
                                            [max]="patentAwardedWithinDaysOptions.at(-1)"
                                            (sliderChange)="updatePatentAwardedWithinDays($event)"></zint-ngx-slider>
                                    </div>
                                </ng-container>
                            </div>
                        </div>                           
                        
                        <div class="card" id="fleet-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('fleet-collapse-card')}">
                            <div class="card-header" id="fleet-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#fleet-collapse">
                                        <i class="fal fa-truck" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Fleet</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="fleet-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    @for (slider of zintSlidersConfigs['fleet']; track $index) {
                                    <zint-ngx-slider [id]="slider.label+'-filter'" #fleetSliders [sliderConfig]="slider"
                                        (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                    }
                                </div>
                            </div>
                        </div>

                        @if (hasAIRAAccess){
                            <div class="card" id="aira-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('aira-collapse-card')}">
                                <div class="card-header" id="aira-heading">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#aira-collapse">
                                            <i class="fal fa-brain" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                            <span *ngIf="!this.isSidebarCollapsed">Deep Research</span>
                                        </button>
                                    </h5>
                                </div>
                                <div id="aira-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                    <div class="card-body">
                                        @if (!pubMethods.hasData(rawAiraFiltersData)){                                            
                                            <span>Something big is coming to Zint. Find out more <a href="https://www.zint.io/platform-features/zint-research-assistant" target="_blank">here</a>.</span>
                                        }
                                        @else{
                                            @for (section of linkedAiraFilters | keyvalue; track $index) {
                                                <details (toggle)="isFilterSectionDetailsExpanded($event, section.key)">
                                                    <summary [ngClass]="{'border-bottom mb-2': expandedFilterSections[section.key]}">{{section.key}}</summary>
                                                    
                                                    @for (group of section.value | keyvalue; track $index) {
                                                    <div class="mb-3" [ngClass]="{'grouped-filter': group.value?.length > 1}">
                                                        @if(group.value?.length > 1){
                                                        <small class="zint-card-title text-break">{{ group.key | underscoreToUpper }}</small>
                                                        }
                                                        @for (filter of group.value; track $index) {
                                                            
                                                            <div *ngIf="filter.filterType === 'multi_select'" [id]="filter.label | kebabCase:'filter'">
                                                                <span>{{filter.label}}</span>
                                                                <div class="form-group select-table max-height-20vh py-2">
                                                                    @for(optionElement of filter.options; track $index){
                                                                        <label class="full-width">
                                                                            <input type="checkbox" name="{{filter.label + optionElement.label}}"
                                                                                [(ngModel)]="optionElement.checked"
                                                                                (ngModelChange)="triggerConfigMultiSelectInputWithObjectUpdate(filter)" />
                                                                            {{optionElement.label}}
                                                                        </label>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div *ngIf="filter.filterType === 'range_slider'" [id]="filter.label | kebabCase:'filter'">
                                                               
                                                                <zint-ngx-slider #genericConfigSliders [sliderConfig]="filter"
                                                                    (sliderChange)="triggerConfigSliderWithObjectUpdate($event, filter)">
                                                                </zint-ngx-slider>
                                                            </div>
                                            
                                                            <div *ngIf="filter.filterType === 'text_input'" [id]="filter.label | kebabCase:'filter'">
                                                                <label class="full-width mb-2">
                                                                    {{filter.label}}
                                                                    <input type="text" class="form-control" name="{{filter.label}}" [(ngModel)]="filter.value"
                                                                        (change)="triggerConfigTextInputWithObjectUpdate(filter.value, filter)" />
                                                                </label>
                                                            </div>                                            
                                                        }
                                                    </div>
                                                    }
                                            
                                                </details>
                                            
                                        }
                                        }

                                    </div>
                                </div>
                            </div>
                        }

                        
                        </section>
                        </details>
                        
                        <details open (toggle)="isFilterSectionDetailsExpanded($event, 'myZint')" 
                        [ngClass]="{'border-top': isSidebarCollapsed}" id="searchable-show-hide-my-zint">
                            <summary>
                                <span *ngIf="!isSidebarCollapsed" class="top-level" [ngClass]="{'border-bottom': expandedFilterSections['myZint']}">MY ZINT</span>
                                <i *ngIf="isSidebarCollapsed" class="fa-kit fa-zint ml-1"></i>
                            </summary>
                            <section>   
                                
                        
                            <div class="card" id="custom-columns-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('custom-columns-collapse-card')}">
                                <div class="card-header" id="custom-columns-heading">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#custom-columns-collapse">
                                            <i class="fal fa-columns" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                            <span *ngIf="!this.isSidebarCollapsed">Your
                                                Custom Columns</span>
                                        </button>
                                    </h5>
                                </div>
                                <div id="custom-columns-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                    <div class="card-body">
                                        <custom-column-filter id="custom-columns-filter"
                                            [inputParams]="{'customColumns': customColumns, 'filterOnAttribute': 'is_basic_column', 'equalsOrNot': true, 'teamMembers': teamMembersForCustomColumns}"></custom-column-filter>
                                    </div>
                                </div>
                            </div>

                            <div class="card" id="tags-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('tags-collapse-card')}">
                            <div class="card-header" id="tags-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#tags-search-collapse">
                                        <i class="fal fa-tag" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Tags</span>
                                    </button>
                                </h5>
                            </div>
        
                            <div id="tags-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
        
                                    <label class="mb-5" id="exclude-team-tag-checkbox-filter">
                                        <input type="checkbox" name="excludeCompaniesTaggedByTeam" value="false"
                                            [(ngModel)]="excludeCompaniesTaggedByTeam" />
                                        Exclude organisations tagged by team members
                                    </label>
        
                                    <span *ngIf="tagsPositiveOptions && tagsPositiveOptions.length == 0">You
                                        have not yet tagged
                                        any businesses. Why don't you <button class="custom-button-a-link"
                                            style="margin-left: 0px; text-decoration: underline" data-toggle="modal"
                                            data-target="#tagModal">create a
                                            tag?</button></span>
                                    <div id="positive-tags-filter" class="form-group select-table overflowing-table"
                                        *ngIf="!tagsPositiveOptions || tagsPositiveOptions.length != 0" >
                                        <label>Is tagged:</label>
                                        <div *ngFor="let tagPositiveOption of tagsPositiveOptions">
                                            <label class="tags-options-container">
                                                <div>
                                                    <input type="checkbox"
                                                        class="mr-2"
                                                        [attr.name]="'tagPositiveOption' + tagPositiveOption.id"
                                                        [ngModelOptions]="{standalone: true}" value="{{tagPositiveOption.id}}"
                                                        [(ngModel)]="tagPositiveOption.checked" />
                                                        <span class="fa-stack fa-2x mr-1" style="font-size: 0.5em" *ngIf="tagPositiveOption.isOrgTag">
                                                            <i class="fal fa-tag fa-stack-2x"></i>
                                                            <i class="fal fa-users fa-stack-1x"></i>
                                                        </span>
                                                        <i class="fal fa-tag mr-1" *ngIf="!tagPositiveOption.isOrgTag"></i>
                                                        
                                                        <span>{{tagPositiveOption.name}}</span>
                                                        
                                                        <span class="ml-1">|&nbsp;
                                                            {{tagPositiveOption.created |
                                                        date: 'd MMM yy' }}
                                                        </span>
                                                </div>
                                                <div *ngIf="isSuperUser" class="align-self-end">
                                                        <span
                                                        >by 
                                                        {{tagPositiveOption.owner}}
                                                        </span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="div-below-pos-tags"></div>
                                    <div id="negative-tags-filter" class="form-group select-table overflowing-table"
                                        *ngIf="!tagsNegativeOptions || tagsNegativeOptions.length != 0">
                                        <label>Is not tagged:</label>
                                        <div *ngFor="let tagNegativeOption of tagsNegativeOptions">
                                            <label class="tags-options-container">
                                                <div>
                                                    <input type="checkbox"
                                                        class="mr-2"
                                                        [attr.name]="'tagNegativeOption' + tagNegativeOption.id"
                                                        [ngModelOptions]="{standalone: true}" value="{{tagNegativeOption.id}}"
                                                        [(ngModel)]="tagNegativeOption.checked" />
                                                                                                                                                            <span class="fa-stack fa-2x mr-1" style="font-size: 0.5em"
                                                            *ngIf="tagNegativeOption.isOrgTag">
                                                            <i class="fal fa-tag fa-stack-2x"></i>
                                                            <i class="fal fa-users fa-stack-1x"></i>
                                                        </span>
                                                        <i class="fal fa-tag mr-1" *ngIf="!tagNegativeOption.isOrgTag"></i>
                                                        {{tagNegativeOption.name}}
                                                        <span>|&nbsp;
                                                            {{tagNegativeOption.created |
                                                        date:'d MMM yy' }}</span>
                                                </div>
                                                <div *ngIf="isSuperUser" class="align-self-end">
                                                    <span>by
                                                        {{tagNegativeOption.owner}}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="div-below-neg-tags"></div>
                                </div>
                            </div>
                        </div>                                
        
                        <div class="card" id="alerts-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('alerts-collapse-card')}">
                            <div class="card-header" id="alerts-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#alerts-search-collapse">
                                        <i class="fal fa-bell" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Alerts</span>
                                    </button>
                                </h5>
                            </div>
        
                            <div id="alerts-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    
                                    <zint-ngx-slider id="organisations-alerted-range-filter" #alertsDayRangeSliderElement *ngIf="alertsDaysRange" postfix=" days"
                                        formName="Organisations alerted in the range"
                                        [min]="alertsDaysRange[0]" [max]="alertsDaysRange[1]"
                                        (sliderChange)="updateAlertsDayRange($event)"></zint-ngx-slider>
                                    <br>
        
                                    <label id="show-unread-alerts-only-checkbox-filter">
                                        <input type="checkbox" name="unreadAlertsOnly" value="false"
                                            [(ngModel)]="unreadAlertsOnly" />
                                        Show unread alerts only
                                    </label>                                            
        
                                    <div id="alert-options-filter" class="form-group select-table overflowing-table">
                                        <label>Alerts</label>
                                        <p *ngIf="alertOptions && alertOptions.length == 0">You
                                            have not created any alerts yet.
                                        </p>
                                        <div *ngFor="let alertOption of alertOptions">
                                            <div>
                                                <label>
                                                    <input type="checkbox" [attr.name]="'alertOption' + alertOption.id"
                                                        [ngModelOptions]="{standalone: true}" value="{{alertOption.id}}"
                                                        [(ngModel)]="alertOption.checked" />
                                                </label>
                                                <span>&nbsp;&nbsp;<i class="fal fa-bell"></i>
                                                    {{alertOption.name}}</span>
                                            </div>
                                        </div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
        
                        <div class="card" id="heatscore-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('heatscore-collapse-card')}">
                            <div class="card-header" id="heat-score-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#heat-score-collapse">
                                        <i class="fal fa-pepper-hot" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Heat
                                            Score</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="heat-score-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    <ng-container *ngFor="let slider of zintSlidersConfigs['heatScore']">
                                        <zint-ngx-slider [id]="slider.label+'-filter'" #heatScoreSliders [sliderConfig]="slider"
                                            (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card" id="propensity-scores-collapse-card" [ngClass]="{'card-disabled': !hasPropensityScoreAccess, 'active-filter-highlight': highlightFilterSection('propensity-scores-collapse-card')}">
                                                        <div class="card-header" id="propensity-score-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#propensity-score-collapse">
                                        <i class="fal fa-star" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Your
                                            Propensity Scores</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="propensity-score-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <ng-container *ngIf="hasPropensityScoreAccess">
                                    <div class="card-body">
                                        <custom-column-filter id="propensity-scores-filter"
                                            [inputParams]="{'customColumns': customColumns, 'filterOnAttribute': 'is_propensity', 'equalsOrNot': true}"></custom-column-filter>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
        
                        <div class="card" id="cross-sell-collapse-card" [ngClass]="{'card-disabled': !hasCrossSellAccess, 'active-filter-highlight': highlightFilterSection('cross-sell-scores-collapse-card')}">
                            <div class="card-header" id="cross-sell-tags-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#cross-sell-tags-search-collapse">
                                        <i class="fal fa-link" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Cross
                                            Sell Opportunities</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="cross-sell-tags-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <ng-container *ngIf="hasCrossSellAccess">
                                    <div class="card-body">
                                        <label id="cross-sell-people-checkbox-filter">
                                            <input type="checkbox" name="crossSellLinkedThroughPeople" value="false"
                                                [(ngModel)]="crossSellLinkedThroughPeople" />
                                            Is connected through shared directorships /
                                            individual share ownership?
                                        </label>
        
                                        <label class="full-width" id="cross-sell-corporate-structure-checkbox-filter">
                                            <input type="checkbox" name="crossSellLinkedThroughCorporateStructure" value="false"
                                                [(ngModel)]="crossSellLinkedThroughCorporateStructure" />
                                            Is connected through the corporate
                                            structure?
                                        </label>
                                        
                                        <div id="cross-sell-options-filter">
                                            <span *ngIf="crossSellTagOptions && crossSellTagOptions.length == 0">
                                                You have not yet run a cross-sell analysis. Why don't you
                                                <a class="custom-button-a-link" style="margin-left: 0px;"
                                                    href="/cross-sell-opportunities">run
                                                    one?</a></span>
                                            <div class="form-group select-table overflowing-table">
                                                <label for="options">Cross Sell
                                                    Option:</label>
                                                <div *ngFor="let crossSellTagOption of crossSellTagOptions">
                                                    <div>
                                                        <label>
                                                            <input type="checkbox"
                                                                [attr.name]="'crossSellTagOption' + crossSellTagOption.id"
                                                                [ngModelOptions]="{standalone: true}"
                                                                value="{{crossSellTagOption.id}}"
                                                                [(ngModel)]="crossSellTagOption.checked" />
                                                        </label>
                                                        <span>&nbsp;&nbsp;<i class="fal fa-tag"></i>
                                                            {{crossSellTagOption.name}}
                                                            <span style="float: right">-
                                                                created
                                                                {{crossSellTagOption.created
                                                                |
                                                                date:'d MMM y'
                                                                }}</span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
        
                        <div class="card" id="salesforce-collapse-card" [ngClass]="{'card-disabled': !hasSalesforceV2AccessMessage, 'active-filter-highlight': highlightFilterSection('salesforce-collapse-card')}">
                            <div class="card-header" id="salesforce-columns-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#salesforce-columns-collapse">
                                        <i class="fab fa-salesforce salesforce-icon"
                                            [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Salesforce</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="salesforce-columns-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <ng-container *ngIf="hasSalesforceV2AccessMessage">
                                    <div class="card-body" id="salesforce-filter">
                                       
                                        <ng-container *ngFor="let salesforceObject of salesforceObjects">
                                            <span class="xsmall-margin-bottom">{{salesforceObject.name}}</span>
                                            <div>
                                                <select [(ngModel)]="salesforceObject.dropdownInput"
                                                    name="salesforceObjectDropDownChoice" class="custom-select"
                                                    style="width: 150px; margin-top: -3px; margin-right: 5px;">
                                                    <option value selected="selected"></option>
                                                    <option value="Exists">Exists</option>
                                                    <option value="DoesNotExist">Does
                                                        Not Exist</option>
                                                </select>
                                            </div>
                                        </ng-container>
                                        <hr>
                                        <custom-column-filter
                                            [inputParams]="{'customColumns': customColumns, 'filterOnAttribute': 'is_salesforce', 'equalsOrNot': true}"></custom-column-filter>                                        
                                    </div>
                                </ng-container>
                            </div>
                        </div>
        
                        

                        <div class="card" id="semantic-collapse-card" [ngClass]="{'card-disabled': !hasSemanticSearchAccess, 'active-filter-highlight': highlightFilterSection('semantic-collapse-card')}">
                            <div class="card-header" id="semantic-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" role="button" data-toggle="collapse"
                                        data-target="#semantic-search-collapse">
                                        <i class="fal fa-dice-d20" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i><span
                                            *ngIf="!this.isSidebarCollapsed" class>Semantic Search</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="semantic-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <ng-container *ngIf="hasSemanticSearchAccess">
                                    <div class="card-body" id="semantic-search-filter">
                                        <label for="summarySemanticSearchTerm" class="mb-0">Semantic search term</label>
                                        <input name="summarySemanticSearchTerm" id="summarySemanticSearchTerm" type="text"
                                            placeholder="term to include" class="form-control mb-1"
                                            [(ngModel)]="summarySemanticSearchTerm"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)" />
                                        <label for="summarySemanticSearchExclude" class="mb-0">Semantic search term to
                                            exclude</label>
                                        <input name="summarySemanticSearchExclude" id="summarySemanticSearchExclude" type="text"
                                            placeholder="feature coming soon" class="form-control" disabled
                                            [(ngModel)]="summarySemanticSearchExclude"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)" />
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <ng-container *ngIf="hasSupplierDataAccessMessage">
                            <div class="card" id="supplier-collapse-card" [ngClass]="{'card-disabled': !hasSupplierDataAccessMessage, 'active-filter-highlight': highlightFilterSection('supplier-collapse-card')}">
                                <div class="card-header" id="suppliers-heading">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#suppliers-collapse">
                                            <i class="fal fa-store" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                            <span *ngIf="!this.isSidebarCollapsed">Supplier
                                                Relationships</span>                                            
                                        </button>
                                    </h5>
                                </div>
                                <div id="suppliers-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                    <div class="card-body">
                                        <label id="likely-supplier-company-tag-filter">
                                            Is a likely supplier of companies tagged
                                            with:
                                            <select *ngIf="tagsPositiveOptions && tagsPositiveOptions.length === 0" class="custom-select">
                                                <option>No Tags Available</option>
                                            </select>
                                            <select *ngIf="tagsPositiveOptions && tagsPositiveOptions.length > 0"
                                                [(ngModel)]="supplierTagSelected" name="supplierTagSelected" class="custom-select">
                                                <option></option>
                                                <ng-container *ngFor="let tagsPositiveOption of tagsPositiveOptions">
                                                    <ng-container
                                                        *ngIf="!tagsPositiveOption.isOrgTag && tagsPositiveOption.owner == emailOnAccount">
                                                        <option [value]="tagsPositiveOption.id">{{tagsPositiveOption.name}}
                                                        </option>
                                                    </ng-container>
                                                </ng-container>
                                            </select>
                                        </label>
                                        <br>
                                        <label id="one-supplier-only-checkbox-filter">
                                            <input type="checkbox" name="associateWithOneSupplierOnly" value="false"
                                                [(ngModel)]="associateWithOneSupplierOnly" />
                                            Associate with one supplier only?
                                        </label>
                                        <br><br>
                                        <label id="likely-customer-company-tag-filter">
                                            Is a likely customer of companies tagged
                                            with:
                                            <select *ngIf="tagsPositiveOptions && tagsPositiveOptions.length === 0" class="custom-select">
                                                <option>No Tags Available</option>
                                            </select>
                                            <select *ngIf="tagsPositiveOptions && tagsPositiveOptions.length > 0"
                                                [(ngModel)]="customerTagSelected" name="customerTagSelected" class="custom-select">
                                                <option></option>
                                                <ng-container *ngFor="let tagsPositiveOption of tagsPositiveOptions">
                                                    <ng-container
                                                        *ngIf="!tagsPositiveOption.isOrgTag && tagsPositiveOption.owner == emailOnAccount">
                                                        <option [value]="tagsPositiveOption.id">{{tagsPositiveOption.name}}
                                                        </option>
                                                    </ng-container>
                                                </ng-container>
                                            </select>
                                        </label><br>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="card" id="similar-org-collapse-card" [ngClass]="{'active-filter-highlight': highlightFilterSection('similar-org-collapse-card')}">
                            <div class="card-header" id="similar-company-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#similar-company-search-collapse">
                                        <i class="fal fa-vials" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Similar
                                            Companies</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="similar-company-search-collapse" [ngClass]="{'collapse': !filterSearchTerm}">
                                <div class="card-body">
                                    <label class="full-width" id="similar-org-filter">
                                    Enter the URL of any business.<br>
                                    Our machine-learning powered algorithm will analyse
                                    the content of that website and find
                                    similar businesses:<br>
                                    <input type="text" class="form-control" name="similarCompanyUrl" [(ngModel)]="similarCompanyUrl"
                                        style="width: 200px; display: inline" placeholder="e.g. zint.io"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                    </label>
                                </div>
                            </div>
                        </div>
                            </section>
                        </details>
                    </div>
                    
                </div>
                <div class="tab-pane" *ngIf="hasAISearchAccess" role="tabpanel" id="ai-chat-agent">
                    <div class="">
                        <chatbot></chatbot>
                    </div>
                </div>
        
            </div>
        
        
        </div>

        <mat-drawer-container autosize>

            <mat-drawer-content>
            <div class="main-grid">

                <!-- reset and search params tags box -->            
                @if (searchTags && searchTags.length > 0) {
                    <div class="card mb-2" style="border-radius: 8px">
                        <div class="card-body overflow-scroll search-actions-container">
                            <div class="search-actions-dropdown">
                                <button class="rounded-btn-base btn-p-sm rounded-btn-light" data-toggle="dropdown"
                                    matTooltip="Current search result actions" matTooltipPosition="above">
                                    <i class="fal fa-bars-filter fa-sm"></i>
                                    Actions
                                </button>
                                <div class="dropdown-menu">
                                    <span class="dropdown-item" role="button"
                                        (click)="searchForm.resetForm(); resetAllFiltersAndUpdateFilters()">
                                        <i class="fal fa-redo-alt fa-sm mr-1"></i>Reset
                                    </span>
                
                                    <span class="dropdown-item" role="button" (click)="openResultsActionModal('saveSearch')">
                                        <i class="fal fa-save fa-sm mr-1"></i>Save Search
                                    </span>
                
                                    @if (hasPropensityScoreAccess && (isSuperUser || (editablePropensityModels &&
                                    editablePropensityModels.length > 0))) {
                                    <span class="dropdown-item" role="button" (click)="openResultsActionModal('addToPropensityModel')">
                                        <i class="fal fa-star fa-sm mr-1"></i>Add to Propensity Model
                                    </span>
                                    }
                
                                    @if (hasTerritoryAssignmentAccessMessage && isSuperUser) {
                                    <span class="dropdown-item" role="button" (click)="openResultsActionModal('assignTerritory')">
                                        <i class="fal fa-map fa-sm mr-1"></i>Assign Territory
                                    </span>
                                    }
                
                                    <span class="dropdown-item" role="button" (click)="chooseToAllowCreateAlertForm()">
                                        <i class="fal fa-bell fa-sm mr-1"></i>Create Alert
                                    </span>
                
                                </div>
                            </div>
                
                            @if (hasPropensityScoreAccess && (isSuperUser || (editablePropensityModels &&
                            editablePropensityModels.length > 0))) {
                            <span role="button" *ngIf="canEditTAM" class="rounded-btn-base rounded-btn-orange btn-p-sm mr-2"
                                data-toggle="modal" data-target="#editPropensityModelTAM">
                                <i class="fal fa-edit fa-sm mr-1"></i>Edit TAM
                            </span>
                            }
                            <search-parameters [inputParams]="{'searchTags': searchTags, 'enableClearing': true}"
                                [savedSearchName]="savedSearchParamName"
                                (onClearSearchParam)="clearSearchParam($event[0])"></search-parameters>
                        </div>
                    </div>
                }
                

                <!-- company counts and page numbers box -->            
                    <div class="count-and-pagination-container px-1">
                        <div class="count-result-actions-container">
                            <div *ngIf="!showSelectedNumbersSection && !totalCompaniesSelected">                                
                                <div *ngIf="isLoading.loadingCompanyCount" class="mt-2">
                                    <zint-loader size="xSmall" [isInline]="true"></zint-loader>
                                </div>
                                <div *ngIf="companyCount && !isLoading.loadingCompanyCount">
                                    <ng-container *ngIf="areTerritoriesEnforced">
                                        <i class="fal fa-exclamation-triangle" style="display: inline" matTooltip="Your team superuser has restricted your searches by territory."></i>&nbsp;
                                    </ng-container>
                                    <span *ngIf="!companyCount.exact">Estimated</span>
                                    {{ companyCount.count | number}}
                                    <span *ngIf="companyCount.active_only">active</span> companies</div>
                            </div>
                            <div *ngIf="showSelectedNumbersSection || totalCompaniesSelected" class="d-flex flex-wrap align-items-baseline">
                                <button type="button"
                                    class="rounded-btn-base rounded-btn-light control-buttons selected-companies-button"
                                    data-html="true" matTooltip="Tag or untag these organisations"
                                    data-toggle="modal"
                                    data-target="#tagModal">
                                    <i class="fal fa-tag"></i>
                                </button>
                                <button type="button"
                                    class="rounded-btn-base rounded-btn-light control-buttons selected-companies-button"
                                    data-html="true" matTooltip="Download these organisations"
                                    data-toggle="modal"
                                    data-target="#downloadModal">
                                    <i class="fal fa-download"></i>
                                </button>
                                <button *ngIf="hasSalesforceAccessMessage && !hasSalesforceV2AccessMessage"
                                    type="button"
                                    class="rounded-btn-base rounded-btn-light control-buttons selected-companies-button"
                                    data-html="true" matTooltip="Export these companies to Salesforce"
                                    data-toggle="modal"
                                    data-target="#salesforceModal">
                                    <i class="fab fa-salesforce salesforce-icon"></i>
                                </button>
                                <button *ngIf="hasSalesforceV2AccessMessage && totalCompaniesSelected == 1"
                                    type="button"
                                    class="rounded-btn-base rounded-btn-light control-buttons selected-companies-button"
                                    data-html="true" matTooltip="Export these companies to Salesforce"
                                    data-toggle="modal"
                                    data-target="#exportToSalesforceModal">
                                    <i class="fab fa-salesforce salesforce-icon"></i>
                                </button>
                                <button *ngIf="hasHubspotAccess"
                                    type="button"
                                    class="rounded-btn-base rounded-btn-light control-buttons selected-companies-button"
                                    data-html="true" matTooltip="Export these companies to Hubspot"
                                    data-toggle="modal"
                                    data-target="#exportToHubspotModal">
                                    <i class="fab fa-hubspot"></i>
                                </button>
                                <button *ngIf="hasCustomInsightsAccess"
                                    type="button"
                                    class="rounded-btn-base rounded-btn-light control-buttons selected-companies-button"
                                    data-html="true" matTooltip="Generate custom insights for these companies"
                                    data-toggle="modal"
                                    data-target="#generateCustomInsightsModal">
                                    <i class="fal fa-microchip"></i>
                                </button>
                                <button type="button"
                                    class="rounded-btn-base rounded-btn-light control-buttons selected-companies-button"
                                    data-html="true"
                                    matTooltip="Bulk edit attributes of these organisations"
                                    data-toggle="modal" data-target="#customColumnEditModal">
                                    <i class="fal fa-arrow-to-right"></i>
                                </button>
                                <button type="button"
                                    class="rounded-btn-base rounded-btn-light control-buttons selected-companies-button"
                                    data-html="true" matTooltip="Mark alerted companies as unread"
                                    data-toggle="modal"
                                    data-target="#markAlertedCompaniesAsReadModal">
                                    <i class="fal fa-envelope-open"></i>
                                </button>
                                <div>
                                    <span *ngIf="!hasSelectedTotalNumberMatchingFilter">{{
                                        totalCompaniesSelected }}
                                        selected.</span>
                                    <span *ngIf="hasSelectedTotalNumberMatchingFilter"><span
                                            *ngIf="!companyCount.exact">~</span>{{ companyCount.count |
                                        number}} selected.</span>
                                    <button class="custom-button-a-link" (click)="hasSelectedAllCompaniesByQuery()"
                                        *ngIf="companyCount.count != totalCompaniesSelected">Select all
                                        <span *ngIf="!companyCount.exact">~</span>{{ companyCount.count |
                                        number }}
                                        instead?</button>
                                </div>
                            </div>
                        </div>
                        <div class="table-config-pagination-container">
                
                                <button type="button" id="edit-columns-button" data-toggle="modal" data-target="#editColumnsModal"
                                    (click)="loadResultsView = true" class="rounded-btn-base rounded-btn-light right-hand-control-buttons"
                                    matTooltipPosition="above" matTooltip="Edit Columns">
                                    <i class="fal fa-columns"></i>
                                </button>
                            
                                <div>
                                <button class="rounded-btn-base rounded-btn-light right-hand-control-buttons" type="button" data-toggle="collapse"
                                    data-target="#collapse-show-selected-view-options">
                                    <i class="fal fa-eye"></i>
                                </button>
                                <div class="collapse" id="collapse-show-selected-view-options">
                                    <select class="custom-select" name="selectedView" [(ngModel)]="selectedView"
                                        style="position: absolute; z-index: 4; width: 200px; height: 29px; padding-top: 0px; padding-bottom: 0px;">
                                        <option *ngFor="let individualView of individualViews" [ngValue]="individualView"
                                            [attr.selected]="individualView.is_default === true ? 'selected' : null">
                                            {{individualView.name}}</option>
                                        <option *ngFor="let organisationView of organisationViews" [ngValue]="organisationView"
                                            [attr.selected]="organisationView.is_default === true ? 'selected' : null">
                                            {{organisationView.name}}</option>
                                    </select>
                                </div>
                                </div>
                            
                            
                                <button type="button" (click)="toggleFixedHeaders()" id="toggle-freeze-headers-button"
                                    class="rounded-btn-base rounded-btn-light right-hand-control-buttons"
                                    [ngClass]="{'button-dark-selected': freezeHeadersIsActive}" matTooltipPosition="above"
                                    matTooltip="Toggle fixed headers">
                                    <i class="fal fa-th-list" [ngClass]="{'white-text': freezeHeadersIsActive}"></i>
                                </button>
                            
                                <div class="dropdown page-size-limit-container">
                                    <button class="rounded-btn-base rounded-btn-light right-hand-control-buttons" id="dropdownMenuButton"
                                        data-toggle="dropdown" matTooltip="Results Per Page" matTooltipPosition="above">
                                        {{selectedPageSizeLimit}}                              
                                    </button>
                                    <div class="dropdown-menu page-size-limit-dropdown">
                                        @for (limit of [10,25,50,100]; track $index) {
                                            <button [disabled]="limit > maxPageSizeLimit"  
                                            class="dropdown-item"
                                            (click)="selectedPageSizeLimit = limit">{{limit}}
                                            </button>
                                        }
                                    </div>
                                </div>
                                
                                <div class=" pagination-button">
                                    <button type="button" class="rounded-btn-base rounded-btn-light control-buttons"
                                        [attr.disabled]="pageNumber == 1 || isLoading.loadingCompanies ? 'disabled': null"
                                        [ngClass]="{'button-disabled': pageNumber == 1 || isLoading.loadingCompanies}"
                                        (click)="previousPage()">
                                        <i class="fal fa-caret-left"></i>
                                    </button>
                                </div>
                                <div class=" pagination-button" *ngIf="maxPageNumber && pageNumber != 1">
                                    <button type="button" class="rounded-btn-base rounded-btn-light control-buttons"
                                        (click)="definedPage(1)" [attr.disabled]="isLoading.loadingCompanies ? 'disabled': null"
                                        [ngClass]="{'button-disabled': isLoading.loadingCompanies}">1</button>
                                </div>
                                <div class=" pagination-button" *ngIf="maxPageNumber && pageNumber - 1 > 1">
                                    <button type="button" class="rounded-btn-base rounded-btn-light control-buttons" disabled>...</button>
                                </div>
                                <div class=" pagination-button">
                                    <button type="button"
                                        class="rounded-btn-base rounded-btn-light control-buttons button-dark-selected"
                                        style="color:white !important" disabled>{{ pageNumber
                                        }}</button>
                                </div>
                                <div class=" pagination-button"
                                    *ngIf="maxPageNumber && pageNumber != maxPageNumber">
                                    <button type="button"
                                        class="rounded-btn-base rounded-btn-light control-buttons"
                                        (click)="definedPage(pageNumber + 1)"
                                        [attr.disabled]="isLoading.loadingCompanies ? 'disabled': null"
                                        [ngClass]="{'button-disabled': isLoading.loadingCompanies}">{{
                                        pageNumber + 1
                                        }}</button>
                                </div>
                                <div class=" pagination-button"
                                    *ngIf="maxPageNumber && maxPageNumber - pageNumber > 2">
                                    <button type="button"
                                        class="rounded-btn-base rounded-btn-light control-buttons"
                                        disabled>...</button>
                                </div>
                                <div class=" pagination-button"
                                    *ngIf="maxPageNumber && pageNumber != maxPageNumber && pageNumber + 1 != maxPageNumber">
                                    <button type="button"
                                        class="rounded-btn-base rounded-btn-light control-buttons"
                                        (click)="definedPage(maxPageNumber)"
                                        [attr.disabled]="isLoading.loadingCompanies ? 'disabled': null"
                                        [ngClass]="{'button-disabled': isLoading.loadingCompanies}">
                                        {{
                                        maxPageNumber }}
                                        </button>
                                </div>
                                <div class=" pagination-button">
                                    <button type="button" class="rounded-btn-base rounded-btn-light control-buttons"
                                        [attr.disabled]="pageNumber == maxPageNumber || isLoading.loadingCompanies ? 'disabled': null"
                                        [ngClass]="{'button-disabled': pageNumber == maxPageNumber || isLoading.loadingCompanies}"
                                        (click)="nextPage()"><i class="fal fa-caret-right"></i></button>
                                </div>
                        </div>
                    </div>
                    <div class="row ml-1 mr-1">
                            <div class="dummy-top-scrollbar-wrapper ">
                                <div class="dummy-top-scrollbar">

                                </div>
                            </div>
                    </div>

                <!-- result table box -->
                <div id="table-results-section"
                    [ngClass]="{'table-section-border': companies && companies.length > 0}">
                            
                    <table class="table company-results-table table-headers mb-0"
                        id="company-results-table-header"                    
                        [ngClass]="{'table-loading': isLoading.loadingCompanies, 'sticky-table company-results-table-with-fixed-headers': freezeHeadersIsActive,
                        'show-scroll-x-border': freezeHeadersIsActive && resultTableScrollLeft
                        }">
                        <thead>
                            <tr>
                                <th scope="col" *ngIf="companies && companies.length > 0"
                                    style="text-align: center; padding-left: 1rem;"
                                    [ngClass]="{'hidden-checkbox-and-company-name-headers': showFakeCompanyNameAndCheckboxSection}">
                                    <input type="checkbox" name="checkboxSelectAllCompanies" value="true"
                                        [(ngModel)]="checkboxSelectAllCompanies"
                                        (ngModelChange)="showOrHideInfoOnAllSelectedCompanies()" />
                                </th>
                                <ng-container *ngIf="companies && companies.length > 0">
                                    <ng-container
                                        *ngFor="let first_company_k_v of companies[0] | mapToIterable; let columnIndex = index">
                                        <th scope="col"
                                            *ngIf="columnsToHide.indexOf(first_company_k_v.key) < 0"
                                            [ngStyle]="{'width': columnNamesToWidths[first_company_k_v.key] + 'px'}"
                                            [ngClass]="{'clicked-table-row-or-column': clickedColumn == columnIndex,'col-hovered': hoveredColIndex === columnIndex, 'hidden-checkbox-and-company-name-headers': first_company_k_v.key == 'Company Name' && showFakeCompanyNameAndCheckboxSection}"
                                            style="position: relative"
                                            (mouseenter)="tableColumnNumberCurrentlyHovered = columnIndex;"
                                            (mouseleave)="tableColumnNumberCurrentlyHovered = null; mouseHasLeftHeaderCell($event, first_company_k_v.key)"
                                            (mousedown)="tableHeaderColumnNumberCurrentlyClicked = columnIndex"
                                            (mouseup)="tableHeaderColumnNumberCurrentlyClicked = null; columnResized($event, first_company_k_v.key)">
                                            <i class="fal fa-plus plus-column-button cursor-pointer"
                                                *ngIf="tableColumnNumberCurrentlyHovered == columnIndex"
                                                (click)="loadResultsView = true; showEditColumnsModal()"></i>
                                            <span *ngIf="companyColumnsNotAllowedToFilter.indexOf(first_company_k_v.key) < 0"
                                                class="cursor-pointer" style="margin-left: 2px; margin-right: 5px"
                                                [ngClass]="{'sort-column-selected': sortKey==first_company_k_v.key}"
                                                (click)="sortBy(first_company_k_v.key)">
                                                <i class="far fa-arrow-up fa-sm"
                                                    *ngIf="sortKey==first_company_k_v.key && sortDirectionIsAscending"></i>
                                                <i class="far fa-arrow-down fa-sm"
                                                    *ngIf="sortKey==first_company_k_v.key && !sortDirectionIsAscending"></i>
                                                <i class="fal fa-arrow-up-arrow-down fa-sm"
                                                    *ngIf="sortKey!=first_company_k_v.key"></i>
                                            </span>
                                            <span
                                                *ngIf="customColumnNamesToObjects && customColumnNamesToObjects[first_company_k_v.key]">
                                                <ng-container
                                                    *ngIf="customColumnNamesToObjects[first_company_k_v.key]['is_collaborative_column']">
                                                    <i class="fal fa-users" style="font-size: 14px"></i>
                                                    <ng-container
                                                        *ngIf="customColumnNamesToObjects[first_company_k_v.key]['is_lockable']">
                                                        <i class="fal fa-lock"
                                                            style="font-size: 14px; margin-left: 5px"></i>
                                                    </ng-container>
                                                </ng-container>
                                            </span>
                                            <span class="col-title"
                                                [innerHTML]="first_company_k_v.key | columnNameToIcon"></span>
                                            <span class="wrap-button-outer-span"
                                                *ngIf="tableColumnNumberCurrentlyHovered == columnIndex"
                                                (click)="addOrRemoveColumnToWrappedColumnNumbers(columnIndex)">
                                                <span class="wrap-button-first-line">|</span>
                                                <div class="wrap-button-block"></div>
                                                <i class="fal fa-redo wrap-button-arrow"></i>|
                                            </span>
                                        </th>
                                    </ng-container>
                                </ng-container>
                                <th *ngIf="companies && companies.length == 0 && !isLoading.loadingCompanies">No
                                    Companies Found
                                </th>
                                <th id="spacing-header-element"
                                    *ngIf="isCompanyResultsTableHeaderRowFixed"></th>
                            </tr>
                        </thead>
                    </table>
                    <table class="table company-results-table mb-0"
                        [ngClass]="{'table-loading': isLoading.loadingCompanies, 'company-results-table-with-fixed-headers': freezeHeadersIsActive, 
                        'show-scroll-x-border': freezeHeadersIsActive && resultTableScrollLeft}">
                        <tbody>
                            <tr style="text-align: center; font-size: 50px; font-weight: 100"
                                *ngIf="isLoading && isLoading.loadingCompanies">
                                <td colspan="1000">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <zint-loader></zint-loader>&nbsp;Loading
                                        Companies
                                    </div>
                                </td>
                            </tr>
                            <ng-container *ngIf="!isLoading.loadingCompanies">
                                <tr *ngFor="let company of companies; let rowIndex = index"
                                    class="clickable-table cut-off">
                                    <td [ngClass]="{'clicked-table-row-or-column': clickedRow == rowIndex}"
                                    style="text-align: center">
                                    <input type="checkbox" name="companySelectedOption"
                                    (change)="updateCountOfSelectedCompanies()"
                                    [(ngModel)]="company.checked" />
                                </td>
                                <ng-container
                                *ngFor="let company_k_v of company | mapToIterable; let columnIndex = index">
                                    <td *ngIf="columnsToHide.indexOf(company_k_v.key) < 0"
                                            [ngStyle]="{'width': columnNamesToWidths[company_k_v.key] + 'px'}"
                                            (click)="clickedRow = rowIndex; clickedColumn = columnIndex;"
                                            (mouseenter)="currentHoverRowName = company.id; currentHoverColumnName = company_k_v.key; hoveredColIndex = columnIndex;"
                                            (mouseleave)="currentHoverRowName = ''; currentHoverColumnName = ''"
                                            (dblclick)="updateCurrentlyEditingCols(company.id, company_k_v.key)"
                                            [ngClass]="{'wrap-column-text': wrappedTableColumnNumbers.indexOf(columnIndex) >= 0, 
                                            'col-hovered': hoveredColIndex === columnIndex,
                                            'no-padding-cell': currentlyEditingRowName == company.id && currentlyEditingColumnName == company_k_v.key,
                                            }"
                                            class="relative-position text-grey-600">
                                            <i *ngIf="company.hasViewed && company_k_v.key == 'Company Name'" class="fal fa-eye"
                                                matTooltipPosition="above" matTooltip="You have viewed this company before"
                                                style="margin-right: 5px"></i>
                                            <i *ngIf="company.isPlaceholderCompany && columnIndex == 0" class="fal fa-lock-alt"
                                                matTooltipPosition="above" matTooltip="Please contact us to view more results"
                                                style="margin-right: 5px"></i>
                                            <ng-container
                                                *ngIf="customColumnNamesToObjects[company_k_v.key] && currentlyEditingRowName == company.id && currentlyEditingColumnName == company_k_v.key">
                                                <custom-column-edit [inputParams]="{
                                                            'customColumn': customColumnNamesToObjects[company_k_v.key],
                                                            'companyIds': [company.id],
                                                            'displayTight': true,
                                                            'inputValue': company_k_v.val ? company_k_v.val.value || company_k_v.val: '',
                                                            'isSuperUser': isSuperUser,
                                                            'lockablePermittedEditValue': emailOnAccount,
                                                            'teamMembers': teamMembersForCustomColumns
                                                        }"
                                                    (onCustomColumnSaved)="clearAllCurrentlyEditingAndUpdateValue($event[0], company_k_v)"></custom-column-edit>
                                            </ng-container>
                                            <div
                                                *ngIf="customColumnNamesToObjects[company_k_v.key] && customColumnNamesToObjects[company_k_v.key].is_propensity && company_k_v.val && company_k_v.val.value">
                                                {{ company_k_v.val.value | number:'1.1-1' }} %
                                            </div>
                                            <ng-container
                                                *ngIf="!customColumnNamesToObjects[company_k_v.key] || !customColumnNamesToObjects[company_k_v.key].is_propensity">
                                                <div style="display: inline"
                                                    [innerHtml]="company_k_v.val | companyDataPipe:company_k_v.key:company.number:accountsSearchTerm:homepageSearchTerm:fullWebsiteSearchTerm:newsContaining"
                                                    [ngClass]="{'blur-company-result': company.isPlaceholderCompany}"
                                                    *ngIf="currentlyEditingRowName != company.id || currentlyEditingColumnName != company_k_v.key">
                                                </div>
                                            </ng-container>
                                            <i *ngIf="
                                                customColumnNamesToObjects[company_k_v.key] &&
                                                customColumnNamesToObjects[company_k_v.key]['can_edit'] &&
                                                currentHoverRowName == company.id &&
                                                currentHoverColumnName == company_k_v.key &&
                                                (currentlyEditingRowName != company.id || currentlyEditingColumnName != company_k_v.key)"
                                                class="fal fa-pencil right-align-edit cursor-pointer"
                                                (click)="updateCurrentlyEditingCols(company.id, company_k_v.key)">
                                            </i>
                                            @if (!isScreenTooSmall() && currentHoverRowName === company.id && currentHoverColumnName === company_k_v.key && hoveredColIndex === 3) {
                                                <button
                                                (click)="toggleProfilePreview(company.number)" class="right-align-edit rounded-btn-base rounded-btn-light mt-0 px-2 py-1"
                                                matTooltip="Preview">
                                                <span class="custom-fa-stack">
                                                    <i class="fat fa-page"></i>
                                                    <i class="far fa-eye"></i>
                                                </span>
                                                    
                                                </button>
                                            }
                                    </td>
                                </ng-container>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            </mat-drawer-content>
            @if(!isScreenTooSmall()){
            <mat-drawer #profilePreview [style.width]="previewDrawerWidth()" position="end" mode="side">
                <button class="rounded-btn-base rounded-btn-white rounded-left-btn preview-drawer-close btn-p-sm no-focus-outline" (click)="toggleProfilePreview()" matTooltip="Close" [style.right]="previewDrawerWidth() === '820px' ? '825px': '590px'">
                    <i class="fal fa-right-to-bracket"></i>
                </button>
                @if (dataStore.companyNumberFromParent()) {
                    <company-profile-page [companyNumberFromParent]="dataStore.companyNumberFromParent()"></company-profile-page>                                
                }
            </mat-drawer>
        }
        </mat-drawer-container>

</div>

<mark-as-read-modal></mark-as-read-modal>
<tagging></tagging>
<downloads
    [inputParams]="{'totalCompaniesSelected': totalCompaniesSelected, 'totalCompanyCount': companyCount.count, 'hasSelectedTotalNumberMatchingFilter': hasSelectedTotalNumberMatchingFilter}">
</downloads>
<salesforce [inputParams]="{'maximumCompanies': selectedPageSizeLimit}"></salesforce>
<custom-column-bulk-edit
    [inputParams]="{'customColumns': customColumns, 'teamMembers': teamMembersForCustomColumns}"></custom-column-bulk-edit>
<salesforce-export-modal></salesforce-export-modal>

<div class="modal fade" id="propensityScoreTAMDefinition" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Define the Total Addressable Market</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <p>Before you can add individual scoring components to your propensity
                            model, you must specify
                            your Total Addressable Market (TAM).</p>
                        <p>This is defined as the highest level filter that all organisations must
                            match before any
                            analysis takes place, e.g. ">£1m in revenue" or
                            "manufacturing organisations". Your TAM
                            cannot exceed {{ propensityTAMLimitCount | number }} organisations.</p>
                    </div>
                    <div class="col-md-12 small-margin-top">
                        <div *ngIf="companyCount && !isLoading.loadingCompanyCount">
                            <span
                                *ngIf="companyCount && companyCount.count > propensityTAMLimitCount"
                                class="badge badge-danger" matTooltipPosition="above"
                                matTooltip="More than {{ propensityTAMLimitCount | number }} organisations">
                                <i class="fal fa-exclamation-triangle"></i>
                            </span>
                            <span *ngIf="!companyCount.exact">Estimated</span>
                            {{ companyCount.count | number}} organisations
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card xsmall-margin-top" style="border-radius: 1px">
                            <div class="card-body overflow-scroll">
                                Current TAM filters: <search-parameters
                                    [inputParams]="{'searchTags': searchTags, 'enableClearing': false}"></search-parameters>
                            </div>
                        </div>
                        <div class="small-margin-top">
                            <span>Name your Propensity Model:</span>
                            <input type="text" class="form-control" name="propensityScoreTAMName"
                                [(ngModel)]="propensityScoreTAMName" placeholder>
                        </div>
                        <button type="button"
                            class="basic-button-base button-dark control-buttons small-margin-top"
                            (click)="definePropensityScoreTAM()"
                            [attr.disabled]="isLoadingZintPropensityScore.loadingIsSavingTAM || isLoading.loadingCompanyCount || companyCount.count > propensityTAMLimitCount ? 'disabled': null">
                            <i class="fal fa-spinner fa-spin"
                                *ngIf="isLoadingZintPropensityScore.loadingIsSavingTAM"></i> Create
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editPropensityModelTAM" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-matTooltip">Edit the Total Addressable Market</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 small-margin-top">
                        <div *ngIf="companyCount && !isLoading.loadingCompanyCount">
                            <span
                                *ngIf="companyCount && companyCount.count > propensityTAMLimitCount"
                                class="badge badge-danger" matTooltipPosition="above"
                                matTooltip="More than {{ propensityTAMLimitCount | number }} organisations">
                                <i class="fal fa-exclamation-triangle"></i>
                            </span>
                            <span *ngIf="!companyCount.exact">Estimated</span>
                            {{ companyCount.count | number}} organisations
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card xsmall-margin-top" style="border-radius: 1px">
                            <div class="card-body overflow-scroll">
                                New TAM filters: <search-parameters
                                    [inputParams]="{'searchTags': searchTags, 'enableClearing': false}"></search-parameters>
                            </div>
                        </div>
                        <div class="small-margin-top">
                            Choose a Propensity Model to overwrite the TAM:
                            <select *ngIf="propensityModels && propensityModels.length > 0"
                                [(ngModel)]="selectedPropensityModelIdToUpdateTAM"
                                name="selectedPropensityModelIdToUpdateTAM" class="custom-select">
                                <option *ngFor="let propensityModel of editablePropensityModels"
                                    [ngValue]="propensityModel.id">{{propensityModel.name}}</option>
                            </select>
                        </div>
                        <button type="button"
                            class="basic-button-base button-dark control-buttons small-margin-top"
                            (click)="updatePropensityModelTAM()"
                            [attr.disabled]="!selectedPropensityModelIdToUpdateTAM || isLoadingZintPropensityScore.loadingIsSavingTAM || isLoading.loadingCompanyCount || companyCount.count > propensityTAMLimitCount ? 'disabled': null">
                            <i class="fal fa-spinner fa-spin"
                                *ngIf="isLoadingZintPropensityScore.loadingIsSavingTAM"></i> Update
                            TAM
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editColumnsModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">Edit Columns</h1>
                <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <results-format-editor *ngIf="loadResultsView"
                            [inputParams]="{'isSuperUser': isSuperUser}"></results-format-editor>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="searchResultsActionsModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header py-2 d-flex align-items-baseline">
                <h5 class="modal-title">{{resultsActionModalTitle | camelCaseToProper}}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                @switch (resultsActionModalTitle) {
                    @case ('saveSearch') {                        
                        <div class="input-group">
                            <div class="input-group-prepend" *ngIf="isSuperUser">
                                <label class="input-group-text" style="font-size: 10px; border-radius: 8px 0 0 8px;height: 33px;">
                                    <input type="checkbox" name="makeSavedSearchShared" value="false" [(ngModel)]="makeSavedSearchShared" />
                                    <span>&nbsp;Shared across org?</span>
                                </label>
                            </div>
                            <input type="text" class="form-control" style="height: 33px;" [style.border-radius]="!isSuperUser ? '8px 0 0 8px !important': null" name="savedSearchName" [(ngModel)]="savedSearchName"
                                placeholder="Choose a name">
                            <div class="input-group-append">
                                <button class="rounded-btn-base rounded-btn-light btn-p-sm rounded-right-btn" type="button" (click)="saveSearch()">
                                    <i class="fal fa-save"></i> Save
                                </button>
                            </div>
                        </div>
                    }

                    @case ('createAlert') {
                        <div class="input-group">
                            <input type="text" class="form-control" name="savedAlertName" [(ngModel)]="savedAlertName"
                                style="min-width: 160px; border-radius: 8px 0 0 8px !important;height: 33px;" placeholder="Choose a name">
                            <select [(ngModel)]="selectedAlertFrequency" name="selectedAlertFrequency" class="custom-select"
                                style="height: 33px;padding-top: 3px;">
                                <option *ngFor="let alertFrequencyOption of alertFrequencyOptions" [ngValue]="alertFrequencyOption.identifier">
                                    {{alertFrequencyOption.description}}</option>
                            </select>
                            <select [(ngModel)]="teamMemberIdToCreateAlertFor" class="custom-select" style="height: 33px;padding-top: 3px"
                                *ngIf="isSuperUser && teamMembers">
                                <option *ngFor="let teamMember of teamMembers" [ngValue]="teamMember.id">{{
                                    teamMember.email }}</option>
                            </select>
                            <div class="input-group-append">
                                <button class="rounded-btn-base rounded-btn-light btn-p-sm rounded-right-btn" (click)="createAlert()">
                                    Create
                                </button>                                
                            </div>
                        </div>
                    }

                    @case ('addToPropensityModel') {
                        <div class="input-group">
                            <div class="input-group-prepend" *ngIf="isSuperUser && (!propensityModels || propensityModels.length === 0)">
                                <button class="btn btn-outline-dark btn-sm" type="button" data-toggle="modal"
                                    data-target="#propensityScoreTAMDefinition"><i class="fal fa-plus-circle"></i>
                                    New Propensity Model</button>
                            </div>
                            <select *ngIf="propensityModels && propensityModels.length > 0" [(ngModel)]="selectedPropensityModelId"
                                name="selectedPropensityModelId" (change)="checkIfAddingNewPropensityScoreModel($event.target.value)"
                                class="custom-select" style="height: 33px; padding-top: 3px;border-radius: 8px 0 0 8px;">
                                <option *ngFor="let propensityModel of editablePropensityModels" [ngValue]="propensityModel.id">
                                    {{propensityModel.name}}</option>
                                <option *ngIf="isSuperUser" value="{{stringToTriggerNewPropensityModel}}">+ Add New
                                    Propensity Model</option>
                            </select>
                            <input type="text" class="form-control" style="height: 33px;" name="propensityScoreComponentDescription"
                                [(ngModel)]="propensityScoreComponentDescription" placeholder="Description"
                                [attr.disabled]="!propensityModels || propensityModels.length == 0 ? 'disabled': null">
                            <input type="number" style="height: 33px;" (keyup)="validatepropensityScoreWeightingValue()" class="form-control"
                                name="propensityScoreWeighting" [(ngModel)]="propensityScoreWeighting" placeholder="Weighting"
                                [attr.disabled]="!propensityModels || propensityModels.length == 0 ? 'disabled': null">
                            <div class="input-group-append">
                                <button class="rounded-btn-base rounded-btn-light btn-p-sm rounded-right-btn" (click)="savePropensityScoreComponent()"
                                    [attr.disabled]="!propensityModels || propensityModels.length == 0 ? 'disabled': null">
                                    <i class="fal fa-save" *ngIf="!isLoadingZintPropensityScore.loadingSavePropensityScoreComponent"></i>
                                    <i class="fal fa-spinner fa-spin"
                                        *ngIf="isLoadingZintPropensityScore.loadingSavePropensityScoreComponent"></i>
                                    Save
                                </button>
                            </div>
                        </div>
                    }

                    @case ('assignTerritory') {
                        <div class="input-group">
                            <input type="text" class="form-control" name="territoryName"
                                style="font-size: 12px; border-radius: 8px 0 0 8px !important;height: 33px;" [(ngModel)]="territoryName"
                                placeholder="Choose a name">
                            <div class="input-group-append">
                                <button class="rounded-btn-base rounded-btn-light btn-p-sm rounded-right-btn" (click)="saveTerritory()">
                                    <i class="fal fa-save"></i>
                                    Save
                                </button>                                
                            </div>
                        </div>
                    }
                }
                    
            </div>
        </div>
    </div>
</div>



<hubspot-export></hubspot-export>
<custom-insights></custom-insights>
