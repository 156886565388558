import { Component, Input } from '@angular/core';

@Component({
  selector: 'aira-number',
  templateUrl: './aira-number.component.html',
  styleUrl: './aira-number.component.css'
})
export class AiraNumberComponent {
  @Input() label;
  @Input() value;
  @Input() prepend = '';
  @Input() append = '';
  @Input() displayFormat = '';
}
