<div *ngIf="chartConfig.series" class="donut-chart">
  <apx-chart #donutChart [series]="chartConfig.series" [chart]="chartConfig.chart" [labels]="chartConfig.labels"
    [dataLabels]="chartConfig.dataLabels" [title]="chartConfig.title" [fill]="chartConfig.fill"
    [states]="chartConfig.states" [plotOptions]="chartConfig.plotOptions" [stroke]="chartConfig.stroke" [legend]="chartConfig.legend"
    [colors]="chartConfig.colors" [theme]="chartConfig.theme" [responsive]="chartConfig.responsive" [tooltip]="chartConfig.tooltip">
  </apx-chart>

  @if(donutData.extraInfo){
  <div>
    @for (item of donutData.extraInfo; track $index) {
    <span>&blacktriangleright; {{item}}</span>
    }
  
  </div>
  }
</div>

