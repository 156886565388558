import { Component, Input } from '@angular/core';
import { ApexNonAxisChartSeries, ApexPlotOptions } from 'ng-apexcharts';

export type GaugeDataInputType = {
  value: number; //required
  height?: number;
  colours?: string[];
  gaugeLabel?: string;
  extraInfo?: string[];
};

@Component({
  selector: 'gauge',
  templateUrl: './gauge.component.html',
  styleUrl: './gauge.component.css',
})
export class GaugeComponent {
  @Input() gaugeData: GaugeDataInputType = { value: 0 };

  chartSeries: ApexNonAxisChartSeries = [];
  chartOptions: Record<string, any> = {};
  plotOptions: Partial<ApexPlotOptions> = {};
  fill: Record<string, any> = {};
  stroke: Record<string, any> = {};
  clampedValue: number = 0;
  gaugeFillColour: string = '';

  ngOnInit(): void {
    this.getValueAndColour();
    this.initializeChart();
  }

  getValueAndColour(): void {
    this.clampedValue = Math.max(0, Math.min(100, this.gaugeData.value));

    this.gaugeFillColour = this.getColor(this.clampedValue);
  }

  initializeChart(): void {
    this.chartOptions = {
      type: 'radialBar', //type for gauge chart
      height: this.gaugeData.height || 250,
      labels: [this.gaugeData.gaugeLabel || ''],
    };

    this.chartSeries = [this.clampedValue];

    this.plotOptions = {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 5,
          size: '70%',
        },
        dataLabels: {
          name: {
            //show the labels[0] in chartOptions
            show: true,
            fontSize: '14px',
            color: this.gaugeFillColour,
            offsetY: -16,
          },
          value: {
            fontSize: '22px',
            color: 'var(--zint-grey-600)',
            offsetY: this.gaugeData.gaugeLabel ? -8 : -16,
            formatter: val => `${this.clampedValue}`,
          },
        },
        track: {
          background: 'var(--zint-grey-300)',
        },
      },
    };

    this.fill = {
      type: 'solid',
      colors: this.gaugeData.colours || this.gaugeFillColour,
    };

    this.stroke = {
      lineCap: 'round', //rounded corners
    };
  }

  //Color generation based on number
  getColor(value: number): string {
    // Linear interpolation between red and green
    let red = 255;
    let green = 0;

    if (value < 50) {
      // Red to yellow (255, 255, 0)
      green = Math.round((value / 50) * 255);
    } else {
      // Yellow to green (0, 255, 0)
      red = Math.round(255 - ((value - 50) / 50) * 255);
      green = 255;
    }

    return `rgb(${red}, ${green}, 0)`;
  }
}
