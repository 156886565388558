import { Component, Input } from '@angular/core';

@Component({
  selector: 'aira-card-list',
  templateUrl: './aira-card-list.component.html',
  styleUrl: './aira-card-list.component.css',
})
export class AiraCardListComponent {
  @Input() data: any;
  @Input() schema: Record<string, any>;
  @Input() headerKeyName = '';
  @Input() contentKeyName: any;

  contentKeys = [];
  formatterKeys = [];
  cardsToRender: any[] = [];
  leftoverCount: number = 0;

  ngOnInit() {
    this.handleContentKey();
    this.handleContentFormatter();
    this.handleCardsData();
  }

  handleContentFormatter(): void {
    if (!this.schema.content_formatter) return;
    if (typeof this.schema.content_formatter === 'string') {
      this.formatterKeys = [this.schema.content_formatter];
    } else {
      this.formatterKeys = this.schema.content_formatter;
    }
  }

  handleContentKey(): void {
    if (!this.contentKeyName) return;
    if (typeof this.contentKeyName === 'string') {
      this.contentKeys = [this.contentKeyName];
    } else {
      this.contentKeys = this.contentKeyName;
    }
  }

  handleCardsData() {
    if (!this.data?.length) return;

    if (this.data.length <= 4) {
      this.cardsToRender = this.data;
      return;
    }

    if (this.data.length > 4) {
      this.leftoverCount = this.data.length - 4;
      this.cardsToRender = this.data.slice(0, 4);
    }
  }

  showAllCards() {
    this.cardsToRender = this.data;
    this.leftoverCount = null;
  }

  isContentArray(content: any): boolean {
    return Array.isArray(content);
  }
}
