import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { DataSharingService } from '../../../services/data-sharing.service';
import { publicMethods } from '../../../globals';
declare var $;

@Component({
  selector: 'aira-switch-cases',
  templateUrl: './aira-switch-cases.component.html',
  styleUrl: './aira-switch-cases.component.css',
})
export class AiraSwitchCasesComponent {
  @Input() displaySchema: any;
  @Input() displayData: any;
  @Input() completeAnswer: any;
  @Input() moduleSectionId: string = '';

  constructor(
    private cdr: ChangeDetectorRef,
    public dataStore: DataSharingService,
    public pubMethods: publicMethods
  ) {}

  modalQuestion = '';
  modalCommentary = '';
  showModal = false;
  noInsights = false;

  ngOnInit() {
    if (!this.pubMethods.hasData(this.displayData)) {
      this.noInsights = true;
    }
  }

  openRefModal() {
    if (this.noInsights) return;

    if (window.location.pathname.includes('dashboard')) {
      this.redirectIfClickedFromPreview();
      return;
    }

    this.showModal = true;
    this.cdr.detectChanges();
    $('#aira-ref-modal').modal('show');

    $('#aira-ref-modal').on('hide.bs.modal', e => {
      this.showModal = false;
    });
  }

  redirectIfClickedFromPreview(): void {
    window.open(
      '/profile/' +
        this.dataStore.companyNumberFromParent() +
        '#' +
        this.moduleSectionId,
      '_blank'
    );
  }

  getConfidenceColor(confidence: number): string {
    if (confidence < 50) {
      return 'red'; // Pure red for < 50
    } else {
      const greenPortion = (confidence - 50) / 50; // Scale 50-100 to 0-1
      const redPortion = 1 - greenPortion;

      const red = Math.round(255 * redPortion);
      const green = Math.round(255 * greenPortion);
      return `rgb(${red}, ${green}, 0)`;
    }
  }
}
