<h5 class="zint-card-title">{{ label }}</h5>
@if(!data?.length) {
<div class="aira-nodata-icon-backdrop">
    <i class="fa-duotone fa-thin fa-list-check"></i>
    <p>No insights have been derived.</p>
</div>
}
@else {
    <div class="pill-container">
        @for (item of pillsToRender; track $index) {

            @if(textPropertyName){
                <span matTooltipPosition="above" matTooltip="{{item[onHoverPropertyName]}}">&check; {{ item[textPropertyName] }}</span>
            }
            @else {
                <span>&check; {{ item }}</span>
            }
            
        }
        @if (leftoverCount) {
        <span role="button" class="badge-vivid-blue" (click)="showAllPills()">{{leftoverCount}}+</span>
        }
    </div>
}
