import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'companyDataPipe',
})
export class CompanyDataPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(
    value: any,
    keyName: string,
    companyNumber?: string,
    accountsSearchTerm?: string,
    homepageSearchTerm?: string,
    fullWebsiteSearchTerm?: string,
    newsContaining?: string,
    postfixLimit?: number,
    showAllInitials?: boolean
  ) {
    if (!keyName) {
      return value;
    }

    if (
      value &&
      value.value !== undefined &&
      keyName !== 'value_and_percentage'
    ) {
      // covers situation where value is an object (lockable columns)
      value = value.value;
    }
    let financials = [
      'Amount of Investment Raised',
      'Revenue',
      'Debtors',
      'Creditors',
      'Assets',
      'Fixed Assets',
      'Gross Profit',
      'Total Grant Funding Received',
      'EBITDA',
      'FX Gain or Loss',
      'Corporation Tax',
      'Tax Credit',
      'Wages',
      'currency',
    ];
    let booleans = [
      'Is Website Valid?',
      'Is Revenue Estimated?',
      'Is Direct Linkedin URL?',
      'Is Direct Twitter URL?',
      'Is Direct Facebook URL?',
      'Has Parent Company?',
      'Has Child Company?',
      'Is Listed on G Cloud?',
      'Is Headcount Estimated?',
    ];
    let roundedNumbers = ['Monthly Website Visitors', 'round numbers'];
    let links = [
      'URL',
      'LinkedIn URL',
      'Twitter URL',
      'Facebook URL',
      'Link to Latest Accounts',
      'Website Screenshot',
      'Amazon Seller URL',
    ];
    let percentages = [
      'Annual Gross Profit Growth Percentage',
      'Annual Revenue Growth Percentage',
      'Annual Assets Growth Percentage',
      'Annual Fixed Assets Growth Percentage',
      'Annual Headcount Growth Percentage',
    ];
    let energy = [
      'Total Energy Usage',
      'Gas Energy Usage',
      'Electric Energy Usage',
      'Scope 1 Energy Usage',
      'Scope 2 Energy Usage',
      'Scope 3 Energy Usage',
    ];
    let emissions = [
      'Total Emissions',
      'Scope 1 Emissions',
      'Scope 2 Emissions',
      'Scope 3 Emissions',
      'Emissions Per Million Revenue',
      'Emissions Per Employee',
    ];
    const tangibleIntangibleAssets = [
      'Land And Buildings',
      'Plant And Machinery',
      'Furniture And Fittings',
      'Vehicles',
      'Computer Equipment',
      'Leasehold Assets',
      'Assets Under Construction',
      'Goodwill',
      'Computer Software',
      'Patents Trademarks And Licences',
    ];
    let companyName = 'Company Name';

    if (financials.indexOf(keyName) > -1) {
      return this.showFinancialFigures(value);
    }
    if (tangibleIntangibleAssets.some(str => keyName.startsWith(str))) {
      return this.showFinancialFigures(value);
    }
    if (booleans.indexOf(keyName) > -1) {
      return this.showBooleans(value);
    }
    if (roundedNumbers.indexOf(keyName) > -1) {
      return this.showRoundedNumbers(value);
    }
    if (links.indexOf(keyName) > -1) {
      return this.showLinks(value);
    }
    if (percentages.indexOf(keyName) > -1) {
      return this.showPercentages(value);
    }
    if (energy.indexOf(keyName) > -1) {
      return this.showEnergy(value);
    }
    if (emissions.indexOf(keyName) > -1) {
      return this.showEmissions(value);
    }
    if (keyName == companyName) {
      return this.showCompanyName(value, companyNumber);
    }
    if (keyName == 'Financial Accounts Preview') {
      return this.showSearchPreview(
        value,
        companyNumber,
        'accountsSearch',
        accountsSearchTerm
      );
    }
    if (keyName == 'Website Homepage Preview') {
      return this.showSearchPreview(
        value,
        companyNumber,
        'fullWebsiteSearch',
        homepageSearchTerm
      );
    }
    if (keyName == 'Full Website Preview') {
      return this.showSearchPreview(
        value,
        companyNumber,
        'fullWebsiteSearch',
        fullWebsiteSearchTerm
      );
    }
    if (keyName == 'News Article Preview') {
      return this.showSearchPreview(
        value,
        companyNumber,
        'companyNewsSearch',
        newsContaining
      );
    }
    if (keyName == 'Self Published News Article Preview') {
      return this.showSearchPreview(
        value,
        companyNumber,
        'selfPublishedCompanyNewsSearch',
        newsContaining
      );
    }
    if (
      keyName.endsWith('Scoring Components') ||
      keyName === 'Shared Propensity Score Components'
    ) {
      return this.showPropensityModelComponents(value, companyNumber);
    }
    if (keyName === 'company logo') {
      return this.createLogoFromInitials(value, showAllInitials);
    }
    if (keyName === 'profileNavCount') {
      return this.addPostfixAtLimit(value, postfixLimit);
    }
    if (keyName === 'value_and_percentage') {
      return this.getValueAndPercentage(value);
    }
    if (keyName === 'chunk_numbers') {
      return this.chunkNumber(value);
    }
    if (keyName === 'flag') {
      return this.prependFlag(value);
    }
    return value;
  }

  showCompanyName(input, companyNumber) {
    return this.sanitizer.bypassSecurityTrustHtml(
      '<a href="/profile/' +
        companyNumber +
        '" target="_blank" style="color: #3c5dc3 !important; font-weight: 400 !important; text-decoration: underline !important;">' +
        input +
        '</a>'
    );
  }

  prependFlag(input: string) {
    if (typeof input !== 'string') {
      return this.sanitizer.bypassSecurityTrustHtml(
        `<i class="fal fa-flag fa-xs"></i>`
      );
    }

    const countryCode = input ? input.trim().toLowerCase() : '';
    if (!countryCode || countryCode?.length !== 2) {
      return this.sanitizer.bypassSecurityTrustHtml(
        `<i class="fal fa-flag fa-xs"></i>`
      );
    }
    return this.sanitizer.bypassSecurityTrustHtml(
      `<span class="flag-icon flag-icon-${countryCode}"></span>`
    );
  }

  showSearchPreview(input, companyNumber, key, value) {
    if (input === '') {
      return input;
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(
        '<a href="/profile/' +
          companyNumber +
          '?' +
          key +
          '=' +
          encodeURIComponent(value) +
          '" class="searchPreview" target="_blank" style="color: #333333 !important; font-weight: 400 !important;"> <i class="fal fa-external-link" style="margin-right: 5px"></i> ' +
          input +
          '</a>'
      );
    }
  }

  showImportsExports(input) {
    if (input && input.length > 0) {
      let total = 0;
      for (let i = 0; i < input.length; i++) {
        total = total + parseInt(input[i].value);
      }
      return total + ' products in 12 month period since ' + input[0].name;
    }
    return '';
  }

  showPercentages(input: number) {
    if (!input && input !== 0) {
      return '';
    }
    if (input < 0) {
      return this.sanitizer.bypassSecurityTrustHtml(
        '<span class="badge badge-danger">' +
          parseFloat(input.toPrecision(2)) +
          '%</span>'
      );
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(
        '<span class="badge badge-success">' +
          parseFloat(input.toPrecision(2)) +
          '%</span>'
      );
    }
  }

  showLinks(input) {
    return this.sanitizer.bypassSecurityTrustHtml(
      '<a href="' + input + '" target="_blank">' + input + '</a>'
    );
  }

  showRoundedNumbers(input) {
    let number = Math.abs(input);
    let requiredNumber = input;
    if (!requiredNumber) {
      return '';
    }
    let append = '';
    if (number >= 1000 && number <= 999999) {
      requiredNumber = input / 1000;
      append = 'k';
    } else if (number >= 1000000 && number <= 999999999) {
      requiredNumber = input / 1000000;
      append = 'm';
    } else if (number >= 1000000000 && number <= 999999999999) {
      requiredNumber = input / 1000000000;
      append = 'bn';
    }
    if (requiredNumber) {
      return requiredNumber.toPrecision(3) + append;
    }
    return '';
  }

  showBooleans(input) {
    if (input) {
      return this.sanitizer.bypassSecurityTrustHtml(
        '<i class="fal fa-check-circle" style="color:green !important"></i>'
      );
    }
    return this.sanitizer.bypassSecurityTrustHtml(
      '<i class="fal fa-times-circle" style="color:red !important"></i>'
    );
  }

  showFinancialFigures(input) {
    let numberFormatted = this.showRoundedNumbers(input);
    if (numberFormatted) {
      return '£' + numberFormatted;
    }
    return '';
  }

  showEnergy(input: number) {
    let requiredNumber = Math.abs(input) * 1000; // Times by 1000 as units are kWh
    if (!requiredNumber) {
      return '';
    }
    let append = '';
    if (requiredNumber > 0 && requiredNumber <= 999999) {
      requiredNumber = requiredNumber / 1000;
      append = ' k';
    } else if (requiredNumber >= 1000000 && requiredNumber <= 999999999) {
      requiredNumber = requiredNumber / 1000000;
      append = ' M';
    } else if (requiredNumber >= 1000000000 && requiredNumber <= 999999999999) {
      requiredNumber = requiredNumber / 1000000000;
      append = ' G';
    } else if (
      requiredNumber >= 1000000000000 &&
      requiredNumber <= 999999999999999
    ) {
      requiredNumber = requiredNumber / 1000000000000;
      append = ' T';
    }
    if (requiredNumber) {
      return requiredNumber.toPrecision(3) + append + 'Wh';
    }
    return '';
  }

  showEmissions(input) {
    if (!input) {
      return '';
    }
    let number = Math.abs(input);
    let append = '';
    let requiredNumber;
    if (number > 0 && number < 99999) {
      requiredNumber = number;
      append = ' kgCO2e';
    } else if (number >= 100000) {
      requiredNumber = number / 1000;
      append = ' tCO2e';
    }
    // toPrecision returns number in scientific notation, parseFloat converts it back to a number.
    return parseFloat(requiredNumber.toPrecision(3)).toLocaleString() + append;
  }

  showPropensityModelComponents(input, companyNumber) {
    let output = '';
    const baseURL = '/profile/' + companyNumber + '?';
    if (input) {
      input.forEach(component => {
        let redirectURL = '';
        if (component.newsContaining) {
          if (component.newsSource === 'selfPublished') {
            redirectURL =
              baseURL +
              'selfPublishedCompanyNewsSearch' +
              '=' +
              encodeURIComponent(component.newsContaining);
          } else {
            redirectURL =
              baseURL +
              'companyNewsSearch' +
              '=' +
              encodeURIComponent(component.newsContaining);
          }
        }
        if (component.fullWebsiteSearchTerm) {
          redirectURL =
            baseURL +
            'fullWebsiteSearch' +
            '=' +
            encodeURIComponent(component.fullWebsiteSearchTerm);
        }
        if (component.homepageSearchTerm) {
          redirectURL =
            baseURL +
            'fullWebsiteSearch' +
            '=' +
            encodeURIComponent(component.homepageSearchTerm);
        }
        if (component.accountsSearchTerm) {
          redirectURL =
            baseURL +
            'accountsSearch' +
            '=' +
            encodeURIComponent(component.accountsSearchTerm);
        }

        const opacity =
          Math.max(1.5, Math.abs(component.propensity_component_weighting)) / 5;
        const colour =
          component.propensity_component_weighting > 0
            ? 'rgb(0, 81, 108)'
            : '#DC3545';
        const href = redirectURL ? 'href=' + redirectURL : '';
        const annotation = component.propensity_component_annotation
          ? 'title=' + `"${component.propensity_component_annotation}"`
          : '';
        const icon = redirectURL ? '<i class="fal fa-external-link"></i>' : '';
        output += `
            <a
            ${href}
            target="_blank"
            class="badge propensity-component"            
            matTooltipPosition="above"
            ${annotation}
            style="color: white; margin-right: 7px; background-color: ${colour}; opacity:${opacity}">
            ${icon}
            ${component.name} 
            </a>
            `;
      });
      return this.sanitizer.bypassSecurityTrustHtml(output);
    }
  }

  createLogoFromInitials(companyName: string, showAllInitials = false): string {
    const initials = companyName
      .split(/\s/g)
      .map(chunk => {
        if (chunk) {
          // takes the 2nd letter if 1st if non-alphanumeric e.g bracket
          return /\w/gi.test(chunk[0])
            ? chunk[0].toLocaleUpperCase()
            : chunk[1]?.toLocaleUpperCase();
        }
      })
      .join('');

    if (showAllInitials) {
      return initials;
    }

    //only return the even number of letters for the ui consistency
    return initials.length % 2 === 0 ? initials : initials.slice(0, -1);
  }

  addPostfixAtLimit(value: number | string, limit: number = 99): string {
    if (value === undefined || value === null || typeof value !== 'number')
      return '';
    const postFixed = value >= limit ? `${limit}+` : `${value}`;
    return postFixed;
  }

  getValueAndPercentage(value: Record<string, number>): SafeHtml {
    if (!value) return;
    const val =
      value.value > 999 ? this.showRoundedNumbers(value.value) : value.value;

    const percentage = Number((value.percentage * 100).toFixed(2)) || 0;

    const formattedHTML = val
      ? `<span class="text-vivid-blue text-bold-600">${percentage}%</span><small class="">${val}</small>`
      : `<span class="text-grey-400">&#65343;&#65343;&#65343;</span>`;
    return this.sanitizer.bypassSecurityTrustHtml(formattedHTML);
  }

  chunkNumber(value: number): string {
    /* looks for positions that are not at a word boundary (\B) and
     * are followed by groups of three digits (\d{3}) and
     * that are not followed by another digit ((?!\d)).
     * */
    const chunk = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return chunk;
  }
}
