<div id="chart" class="">
  <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [grid]="chartOptions.grid"
    [plotOptions]="chartOptions.plotOptions" [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [dataLabels]="chartOptions.dataLabels"
    [colors]="chartOptions.colors" [legend]="chartOptions.legend" [tooltip]="chartOptions.tooltip">
  </apx-chart>

  @if(barChartData.extraInfo){
  <div>
    @for (item of barChartData.extraInfo; track $index) {
    <span>&blacktriangleright; {{item}}</span>
    }

  </div>
  }
</div>