<h5 class="zint-card-title">{{ label }}</h5>
@if(!data?.length) {
<div class="aira-nodata-icon-backdrop">
  <i class="fa-duotone fa-thin fa-list-check"></i>
  <p>No insights have been derived.</p>
</div>
} @else {

<div class="d-flex flex-column flex-gap-05">
  @for (item of data; track $index) {
    <div class="d-flex justify-content-between align-items-baseline mb-2">
      <span class="text-bold-600">{{item[headerKeyName]}}</span>
      <small *ngIf="item[contentKeyName]" class="badge-light-grey ">{{item[contentKeyName]}}</small>
    </div>
  }
</div>
}