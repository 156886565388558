<h5 class="zint-card-title">{{label}}</h5>
@if(!pubMethods.hasData(data)) {
  <div class="aira-nodata-icon-backdrop">
    <i class="fa-duotone fa-thin fa-chart-simple"></i>
    <p>No insights have been derived.</p>
  </div>
}
@else {
    <bar-chart [barChartData]="{series: data, tooltipPrepend: schema.prepend, tooltipAppend: schema.append, xCategories: schema.axis_labels, extraInfo, colours: blues}"></bar-chart>
  }
