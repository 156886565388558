import { Component, Input } from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexXAxis,
  ApexYAxis,
  ApexPlotOptions,
  ApexTooltip,
  ApexGrid,
  ApexLegend,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  grid: ApexGrid;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  tooltip: ApexTooltip;
  legend: ApexLegend;
  colors: string[];
};

export type BarChartDataInput = {
  series: number[];
  seriesName: string;
  isReversedHorizontalChart: boolean;
  xCategories: string[];
  width: number;
  height: number;
  colours: string[];
  extraInfo: string[];
  tooltipAppend: string;
  tooltipPrepend: string;
};

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.css',
})
export class BarChartComponent {
  @Input() barChartData: Partial<BarChartDataInput> = {};
  chartOptions: Partial<ChartOptions>;

  mutedColours = [
    'var(--zint-purple)',
    'var(--zint-bear-blue)',
    'var(--zint-steel-blue)',
    'var(--zint-vivid-blue)',
    'var(--zint-baby-blue)',
    'var(--zint-grey-600)',
    'var(--zint-blue-500)',
    'var(--zint-blue-300)',
    'var(--zint-purple-300)',
    'var(--zint-purple-400)',
  ];

  constructor() {}

  ngOnInit() {
    this.buildTheBarChart();
  }

  buildTheBarChart(): void {
    if (this.barChartData.isReversedHorizontalChart) {
      this.buildHorizontalBarChart();
    } else {
      this.setUpBaseBarChart();
    }
  }

  setUpBaseBarChart(): void {
    this.chartOptions = {
      series: [
        {
          name: this.barChartData.seriesName || 'bar',
          data: this.barChartData.series || [1, 2, 3, 4],
        },
      ],
      chart: {
        toolbar: {
          show: false,
        },
        type: 'bar',
        height: this.barChartData.height || 260,
        width: this.barChartData.width || '100%',
        parentHeightOffset: 0,
      },
      legend: { show: false },
      colors: this.barChartData.colours || this.mutedColours,
      plotOptions: {
        bar: {
          horizontal: false, // true ? yaxis.reversed = true
          borderRadius: 6,
          borderRadiusApplication: 'end',
          distributed: true,
          dataLabels: {
            position: 'center',
            hideOverflowingLabels: true,
            total: {
              enabled: false,
            },
          },
        },
      },
      xaxis: {
        categories: this.barChartData.xCategories || [''],
        labels: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        reversed: false, //true = horizontal bar chart
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        custom: ({ series, seriesIndex, dataPointIndex }) => {
          return `<small class='bg-light p-1'>
            ${this.barChartData.tooltipPrepend || ''}${series[seriesIndex][dataPointIndex]}&nbsp;${this.barChartData.tooltipAppend || ''}
            </small>`;
        },
        x: {
          show: false,
        },
        y: {
          // title: null,
        },
        marker: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
    };
  }

  buildHorizontalBarChart(): void {
    this.setUpBaseBarChart();
    this.chartOptions = {
      ...this.chartOptions,
      plotOptions: {
        ...this.chartOptions.plotOptions,
        bar: {
          ...this.chartOptions.plotOptions.bar,
          horizontal: true,
        },
      },
      xaxis: {
        ...this.chartOptions.xaxis,
        labels: {
          show: false,
        },
      },
      yaxis: {
        ...this.chartOptions.yaxis,
        reversed: true,
        labels: {
          show: true,
        },
      },
      grid: {
        ...this.chartOptions.grid,
        padding: {
          top: -16,
          right: 20,
          bottom: 0,
          left: 0,
        },
      },
    };
  }
}
