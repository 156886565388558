<h5 class="zint-card-title">{{ schema.label }}</h5>
@if(!data?.length) {
<div class="aira-nodata-icon-backdrop">
    <i class="fa-duotone fa-thin fa-list-check"></i>
    <p>No insights have been derived.</p>
</div>
} @else {
    <div class="d-flex flex-wrap flex-gap-05">
        @for (item of cardsToRender; track $index) {
        <div class="zint-base-card">        
            <span class="zint-card-title d-flex align-items-end"> 
                @if (item.domain) {
                    <img *ngIf="item[headerKeyName]" class="card-img" alt="logo"
                        src="https://www.google.com/s2/favicons?sz=64&domain={{item.domain}}">
                } @else {
                    <i class="fal flex-align-anchor-center mr-1" [ngClass]="schema.fallback_icon || 'fad fa-cloud-question'"></i>
                }
                  <truncate-text [inputText]="item[headerKeyName]" [limit]="4" [showEllipsis]="true"></truncate-text>
            </span>
            <div class="d-flex max-height-20vh">
                @for (contentKey of contentKeys; track $index; let outerIndex = $index) {                                       
                    @if (isContentArray(item[contentKey])) {
                        <div [ngClass]="outerIndex === 0 ? 'ml-0 mr-auto' : 'ml-auto mr-0'">
                        @for (subItem of item[contentKey]; track $index; let innerIndex = $index) {  
                            <span class="d-block" [ngClass]="outerIndex > 0 ? 'aux-badge' : 'primary-badge'">
                                @if(formatterKeys[outerIndex] === 'flag'){           
                                    <span [innerHTML]="item['country_code'][innerIndex] | companyDataPipe: formatterKeys[outerIndex]"></span>
                                    {{subItem}}
                                } @else { 

                                    {{subItem | companyDataPipe: formatterKeys[outerIndex]}}
                                }
                            </span>
                            }
                        </div>
                }   @else {    
                    <div [ngClass]="outerIndex === 0 ? 'ml-0 mr-auto' : 'ml-auto mr-0'">
                        <span *ngIf="item[contentKey]" class="d-block" [ngClass]="outerIndex > 0 ? 'aux-badge' : 'primary-badge'">
                            @if(formatterKeys[outerIndex] === 'flag'){
                                <span [innerHTML]="item['country_code'] | companyDataPipe: formatterKeys[outerIndex]"></span>
                                {{item[contentKey]}}
                            } @else {

                                {{item[contentKey] | companyDataPipe: formatterKeys[outerIndex]}}
                            }                                                       
                        </span>
                    </div>
                    }
                }
            </div>
        </div>
        }
        @if (leftoverCount) {
        <div role="button" class="zint-base-card zint-card-light" (click)="showAllCards()">
            <span class="zint-card-details my-3">{{leftoverCount}}+</span>
        </div>
        }
    </div>
}