<h5 class="zint-card-title">{{ label }}</h5>

@if(!pubMethods.hasData(data)) {
<div class="aira-nodata-icon-backdrop">
    <i class="fa-duotone fa-thin fa-chart-pie-simple"></i>
    <p>No insights have been derived.</p>
</div>
}
@else {

    <donut-chart [donutData]="{series, legendLabels: labels, chartType: 'donut', width: 320, totalLabel: getTotalLabel(), tooltipAppend: append}" ></donut-chart>
}
