import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'camelCaseToProper' })
export class CamelCaseToProperPipe implements PipeTransform {
  transform(inputString: string) {
    return (
      inputString
        // Add a space before any uppercase letter (except at the start or if preceded by another uppercase)
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // Handle multi-letter acronyms (e.g., "AI" in "helloAIWorld")
        .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
        // Capitalize the first letter of each word
        .replace(/\b\w/g, match => match.toUpperCase())
    );
  }
}
