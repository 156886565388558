<h5 class="zint-card-title">{{ label }}</h5>
@if(!value) {
<div class="aira-nodata-icon-backdrop">
    <i class="fa-duotone fa-thin fa-input-numeric"></i>
    <p>No insights have been derived.</p>
</div>
}
@else {
<div class="d-flex justify-content-center align-items-center h-75">
    <div class="zint-base-card d-inline-block px-4">
        <h4 class="text-grey-600 text-bold-600 long-text">{{ prepend || '' }} {{ value | number: '1.0-0' }}</h4>

        <small class="d-inline-block text-grey-500 text-right">{{ append || ''}}</small>
    </div>
</div>
}