import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexPlotOptions,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexGrid
} from "ng-apexcharts";

export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  yaxis: ApexYAxis;
  tooltip: ApexTooltip;
  grid: ApexGrid;
  title: ApexTitleSubtitle;
}

@Component({
  selector: 'aira-histogram-chart',
  templateUrl: './aira-histogram-chart.component.html',
  styleUrls: ['./aira-histogram-chart.component.css']
})
export class AiraHistogramChartComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  @Input() frequencies: number[] = []; // Input is now 'frequencies'

  ngOnInit(): void {
    this.createHistogram();
  }

  ngOnChanges(): void {
    this.createHistogram();
  }

  createHistogram() {
    if (!this.frequencies || this.frequencies.length === 0) {
      return;
    }

    this.chartOptions = {
      series: [
        {
          name: "Frequency",
          data: this.frequencies // Directly use the input frequencies
        }
      ],
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false
        }
      },
      title: {
        text: "Histogram"
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: false,
          columnWidth: '80%'
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        // Since we don't have bin ranges, use simple numeric categories or labels
        categories: this.frequencies.map((_, i) => `Bin ${i + 1}`), // Example: Bin 1, Bin 2, etc.
        title: {
          text: "Bins" // Or a more appropriate label
        }
      },
      yaxis: {
        title: {
          text: "Frequency"
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return `Frequency: ${val}`;
          }
        }
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5
        }
      }
    };
  }

}
