import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { publicMethods, baseUrl } from 'globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class OrganisationManagementService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingData: false,
    loadingSharedDownloadCustomColumn: false
  };

  getOrganisationManagementDetails(org_id): Observable<any> {
    this.isLoading['loadingData'] = true;
    let params = new HttpParams();
    if (org_id) {
      // Then this is loaded via the URL, so is not the immediate superuser's org.
      params = params.set('org_id', org_id);
    }
    return this.http
      .post(
        baseUrl + '/organisation_management_details/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingData'] = false)),
        catchError(
          this.pubMethods.handleError('getOrganisationManagementDetails', [])
        )
      );
  }

  superUserSaveUserEmailAddress(userId, emailAddress, org_id): Observable<any> {
    this.isLoading['loadingData'] = true;
    let params = new HttpParams()
      .set('userId', userId)
      .set('emailAddress', emailAddress);
    if (org_id) {
      params = params.set('org_id', org_id);
    }
    return this.http
      .post(
        baseUrl + '/superuser_save_user_email_address/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingData'] = false)),
        catchError(
          this.pubMethods.handleError('superUserSaveUserEmailAddress', [])
        )
      );
  }

  superUserSaveUserDownloadQuota(
    userId,
    downloadLimit,
    org_id
  ): Observable<any> {
    this.isLoading['loadingData'] = true;
    let params = new HttpParams()
      .set('userId', userId)
      .set('downloadLimit', downloadLimit);
    if (org_id) {
      params = params.set('org_id', org_id);
    }
    return this.http
      .post(
        baseUrl + '/superuser_save_user_download_quota/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingData'] = false)),
        catchError(
          this.pubMethods.handleError('superUserSaveUserDownloadQuota', [])
        )
      );
  }

  superUserRequestNewPassword(userId, org_id): Observable<any> {
    this.isLoading['loadingData'] = true;
    let params = new HttpParams().set('userId', userId);
    if (org_id) {
      params = params.set('org_id', org_id);
    }
    return this.http
      .post(
        baseUrl + '/superuser_reset_password/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingData'] = false)),
        catchError(
          this.pubMethods.handleError('superUserRequestNewPassword', [])
        )
      );
  }

  addTeamMember(emailAddress, org_id): Observable<any> {
    this.isLoading['loadingData'] = true;
    let params = new HttpParams().set('emailAddress', emailAddress);
    if (org_id) {
      params = params.set('org_id', org_id);
    }
    return this.http
      .post(
        baseUrl + '/superuser_add_team_member/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingData'] = false)),
        catchError(this.pubMethods.handleError('addTeamMember', []))
      );
  }

  callDeleteUserAccount(userId, org_id): Observable<any> {
    this.isLoading['loadingData'] = true;
    let params = new HttpParams().set('userId', userId);
    if (org_id) {
      params = params.set('org_id', org_id);
    }
    return this.http
      .post(
        baseUrl + '/superuser_delete_team_member/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingData'] = false)),
        catchError(this.pubMethods.handleError('callDeleteUserAccount', []))
      );
  }

  updateTagView(tagViewString, org_id): Observable<any> {
    this.isLoading['loadingData'] = true;
    let params = new HttpParams().set('tagViewString', tagViewString);
    if (org_id) {
      params = params.set('org_id', org_id);
    }
    return this.http
      .post(
        baseUrl + '/superuser_change_tag_view/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingData'] = false)),
        catchError(this.pubMethods.handleError('updateTagView', []))
      );
  }

  getSharedDownloadColumnOptions(): Observable<any> {
    this.isLoading['loadingSharedDownloadCustomColumn'] = true;
    const params = new HttpParams();
    return this.http
      .post(
        baseUrl + '/get_shared_date_columns/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingSharedDownloadCustomColumn'] = false)),
        catchError(this.pubMethods.handleError('getSharedDateColumns', []))
      );
  }

  setSharedDownloadColumn(columnId): Observable<any> {
    this.isLoading['loadingData'] = true;
    const params = new HttpParams().set('columnId', columnId);
    return this.http
      .post(
        baseUrl + '/superuser_set_shared_download_column/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingSharedDownloadCustomColumn'] = false)),
        catchError(this.pubMethods.handleError('setSharedDownloadColumn', []))
      );
  }



}
